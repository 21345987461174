import React from "react";
import * as Common from "@/app/General/common";
import {
  withMultiRegionComponent,
  withMultiRegionProperties,
} from "@/shared/wrappers/withMultiRegion";
import formatZipCode from "@/shared/functions/formats/formatZipCode";

const inputTypeRegion = withMultiRegionProperties({
  US: "number",
  BR: "number",
  UK: "text",
});

function UpsertFacilityZipFieldUS({ that, ...props }) {
  return (
    <input
      type={inputTypeRegion}
      id="Zip"
      name="zip"
      minLength="5"
      maxLength="9"
      value={that.Facilty?.address?.zip?.trim()}
      onKeyDown={(evt) =>
        ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
      }
      onChange={(e) =>
        Common.updateInputValueInState(
          that.state.Facilty.address,
          e,
          that,
          that.state
        )
      }
      className="form-control form-control-sm"
    />
  );
}
function UpsertFacilityZipFieldBR({ that, ...props }) {
  return (
    <input
    type="text"
    id="zip"
    name="zip"
    value={formatZipCode(
        Common.replaceNullWithString(
          that.state.Facilty.address.zip
        )
    )}
    onChange={(e) =>
      Common.updateInputValueInState(
        that.state.Facilty.address,
        e,
        that,
        that.state
      )
    }
    className="form-control form-control-sm"
  />
  );
}

function UpsertFacilityZipFieldUK({ that, ...props }) {
    return (
        <input
            type={inputTypeRegion}
            id="zip"
            name="zip"
            maxlength="8"
            value={formatZipCode(
                Common.replaceNullWithString(
                    that.state.Facilty.address.zip
                ), "UK"
            )}
            onChange={(e) =>
                Common.updateInputValueInState(
                    that.state.Facilty.address,
                    e,
                    that,
                    that.state
                )
            }
            className="form-control form-control-sm"
        />
    );
}

const UpsertFacilityZipFieldByRgion = {
  US: UpsertFacilityZipFieldUS,
  BR: UpsertFacilityZipFieldBR,
  UK: UpsertFacilityZipFieldUK,
};

const UpsertFacilityZipFieldMultiRegion = withMultiRegionComponent(
  UpsertFacilityZipFieldByRgion
);

export default UpsertFacilityZipFieldMultiRegion;
