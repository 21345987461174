import React, { useRef, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";

export function RecapEmail(props) {
    const htmlFilePath = process.env.PUBLIC_URL + '/media/email.html';
    const iFrame = useRef(null);

    const toolbar = (
        <div className="card-toolbar">
            <div className="example-tools">
                <button
                    type="button"
                    className="mr-5 btn btn-dark btn-sm"
                    onClick={() => props.backPress()}
                >
                    Back To Facility
                </button>

            </div>
        </div>
    );

    const handleIframeLoaded = () => {
       /* const iFrameItem = iFrame.current;
        const nameInput = iFrameItem.contentDocument.querySelector('input[name="facility_name"]');
        nameInput.value = props.facility.facilityName;*/
    };

    return (
        <Card className="example example-compact">
            <CardHeader title={'Post Visit Recap - ' + props.facility.facilityName} >
                {toolbar }
            </CardHeader>
            <CardBody>
                <div>
                    {
                        <iframe
                            ref={iFrame }
                            title="HTML Viewer"
                            src={htmlFilePath}
                            style={{ width: '100%', height: '600px', border: 'none' }}
                            onLoad={handleIframeLoaded}>
                        </iframe>

                    }
                    
                </div>
            </CardBody>
        </Card>
    );
}

export default RecapEmail;