import React, { useEffect, useState, useRef } from 'react';
import { Card, CardHeader, CardBody } from '../../../_metronic/_partials/controls';
import Select from 'react-select';
import { Table } from 'react-bootstrap';
import { getRequest, postRequest, Uri_GetUserByRoleID,Uri_getFacilitiesByClinicianId, Uri_updateFacilityClinician } from '../../General/api';
import { successStatusCode, defaultCloseModalTimeout, showSuccessAlertAutoClose, LookupsFK } from '../../General/common';

export default function FacilityClinicianManager() {
  const [clinicians, setClinicians] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [searched, setSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const selectedClinician = useRef(null);
  const selectedNewClinician = useRef(null);

  useEffect(() => {
    getRequest(`${Uri_GetUserByRoleID}?id=${LookupsFK.Fk_RoleClinicianID}`)
      .then(response => {
        const responseData = response.data;
        if (responseData.statusCode !== successStatusCode || !responseData.result) {
          showSuccessAlertAutoClose('Clinician list not found!', 'warning', 'warning!', defaultCloseModalTimeout);
        }

        setClinicians(response.data.result.map(clinician => ({ label: clinician.value, value: clinician.key })));
      }).catch(error => {
        console.log(error);
        showSuccessAlertAutoClose('Sorry, an error has occurred', 'warning', 'warning!', defaultCloseModalTimeout);
      });
  }, []);

  const handleCheckboxChangeAll = (e) => {
    setFacilities(facilities.map(facility => ({ ...facility, isChecked: e.target.checked })));
  }

  const getFacilitiesByClinicianId = (inputValue) => {
    setSearched(true);
    getRequest(`${Uri_getFacilitiesByClinicianId}?clinicianId=${inputValue}`)
      .then(response => {
        const responseData = response.data;
        if (responseData.statusCode !== successStatusCode || !responseData.result) {
          showSuccessAlertAutoClose('Facility list not found!', 'warning', 'warning!', defaultCloseModalTimeout);
        }

        setFacilities(responseData.result.map(facility => ({ ...facility, isChecked: false })));
      }).catch(error => {
        console.log(error);
        showSuccessAlertAutoClose('Sorry, an error has occurred', 'warning', 'warning!', defaultCloseModalTimeout);
      });
  }

  const Save = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const selectedFacilities = facilities.filter(facility => facility.isChecked);

    if (selectedFacilities.length === 0) {
      showSuccessAlertAutoClose('Please select at least one facility!', 'warning', 'warning!', defaultCloseModalTimeout);
      setIsLoading(false);
      return;
    }

    const selectedFacilitiesIds = selectedFacilities.map(facility => facility.fkFacilityId);
    const newClinicianId = selectedNewClinician.current.value;

    postRequest(Uri_updateFacilityClinician, { "FacilitiesIds": selectedFacilitiesIds, "NewClinicianId": newClinicianId })
      .then(() => {
        showSuccessAlertAutoClose('Facility Clinician updated successfully!', 'success', 'Success!', defaultCloseModalTimeout);
        getFacilitiesByClinicianId(selectedClinician.current.value);
        setIsLoading(false);
      }).catch(error => {
        console.log(error);
        showSuccessAlertAutoClose('Sorry, an error has occurred', 'warning', 'warning!', defaultCloseModalTimeout);
        setIsLoading(false);
      });
  }

  return (
    <>
      <Card>
        <CardHeader title='Facility Clinician Manager'></CardHeader>
        <CardBody>
          <>
            <form onSubmit={e => Save(e)}>
              <div className='row'>
                <div className='col-5'>
                  <div className='form-group'>
                    <label className='form-label' htmlFor='clinician'>Clinician</label>
                    <Select
                      options={clinicians}
                      id='clinician'
                      name='clinician'
                      required
                      isSearchable
                      onChange={selectedOption => {
                        selectedClinician.current = selectedOption;
                        getFacilitiesByClinicianId(selectedOption.value);
                      }}
                    />
                  </div>
                </div>
                <div className='col-5'>
                  <div className='form-group'>
                    <label className='form-label' htmlFor='newClinician'>New Clinician</label>
                    <Select
                      options={clinicians}
                      id='newClinician'
                      name='newClinician'
                      required
                      isSearchable
                      onChange={selectedOption => {
                        selectedNewClinician.current = selectedOption;
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='d-flex w-100 justify-content-end py-3'>
                  <button type='submit' className='btn btn-success mr-5 px-10'>{isLoading ? <div className='p-2 spinner spinner-primary'></div> : 'Save'}</button>
                </div>
              </div>
            </form>
            <hr />
            {
              facilities && facilities.length > 0 ? (
                <Table responsive striped hover bordered>
                  <thead>
                    <tr>
                      <th>Facility Name</th>
                      <th className='text-center'>
                        <input
                          type='checkbox'
                          onChange={(e) => { handleCheckboxChangeAll(e) }} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      facilities.map((facility) => (
                        <tr key={facility.fkFacilityId}>
                          <td>{facility.facilityName}</td>
                          <td className='text-center'>
                            <input
                              type='checkbox'
                              id={facility.fkFacilityId}
                              checked={facility.isChecked}
                              onChange={(e) => {
                                setFacilities(facilities.map(facilityItem => {
                                  if (facilityItem.fkFacilityId === facility.fkFacilityId) {
                                    return { ...facilityItem, isChecked: e.target.checked }
                                  }
                                  return facilityItem;
                                }));
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              ) : (
                <div className='text-center'>{searched ? 'No data found' : ''}</div>
              )
            }
          </>
        </CardBody>
      </Card>
    </>
  );
}