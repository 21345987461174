import React, { useState, useEffect } from "react";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { FormattedMessage } from "react-intl";


const ResidentSmokingStatus = (props) => {

    const [smokingStatusList, setSmokingStatusList] = useState([]);
    const [resident, setResident] = useState(props.resident);

    useEffect(() => {
        getSmokingStatus();
    }, []);

    const getSmokingStatus = () => {
        Api.getRequest(`SmokingStatus/active`)
            .then((res) => {
                console.log(res.data)
                if (res.data.statusCode === 200 && res.data.result) {
                    if (res.data.result.length > 0) {
                        setSmokingStatusList(res.data.result);
                    }
                }
            })
            .catch((ex) => {
                Common.LogError(ex, "Resident", "getDropDownValueApiCall");
            });
    };

    const setOptionsUI = (options) => {
        return options.map((option) => (
            <option key={option.id} value={option.id}>
                {option.name}
            </option>
        ));
    }


    return (
        <>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                    <label className="form-label" htmlFor="Status">
                        <FormattedMessage id="RESIDENT.SMOKING_STATUS" defaultMessage="Smoking Status" />
                    </label>
                    <select
                        className="form-control form-control-sm"
                        id="FkResidentSmokingStatusId"
                        name="FkResidentSmokingStatusId"
                        required
                        defaultValue={resident.FkResidentSmokingStatusId}
                        onChange={(e) => {
                            const updatedResident = { ...resident, fkResidentSmokingStatus: e.target.value };
                            Common.updateInputValueInState(props.state.Resident, e, props.thisReference, props.state);
                            setResident(updatedResident);
                        }}
                    >
                        {setOptionsUI(smokingStatusList)}
                    </select>
                </div>
            </div>
        </>
    )
}

export default ResidentSmokingStatus;