import React, { createRef } from "react";
import { Form } from "react-bootstrap";
import * as Api from "@/app/General/api";
import * as Common from "@/app/General/common";
import Loader from "react-loader-spinner";
import NewBrightreePhysician from "@/app/pages/physician/newbrightreephysician";
import { Animated } from "react-animated-css";
import AddEditPhysician from "@/app/pages/physician/newPhysician";
import { injectIntl } from "react-intl";
import { requiredFieldsForAlternativeShippingAddress } from "@/Utility/maps/WoundReportMap";
import ssnWithoutSpecialChar from "@/shared/functions/ssn/ssnWithoutSpecialChar";
import CreateResidentFormWrapper from "@/shared/components/createResidentForm";
import sampleResidentBR from "./samples/sampleResidentBR";

class CreateResidentBR extends React.Component {
  constructor(props) {
    super(props);

    this.intl = this.props.intl;
    this.state = {
      addWoundComponent: null,
      physicianModal: false,
      isPrimaryModal: false,
      formValidated: false,
      singingPhysicianList: [],
      singingPhysician:
        this.props.Resident != null
          ? this.props.Resident.fkSigningPhysicianId > 0
            ? {
                value: props.Resident.fkSigningPhysicianId,
                label: props.Resident.signingPhysicianName,
              }
            : null
          : this.props.facility.fkPhysicianId > 0 &&
            this.props.facility.singingForAllCmns == true
          ? {
              value: this.props.facility.fkPhysicianId,
              label: this.props.facility.physicianName,
            }
          : null,
      singingphysiciansInputValue: "",
      primaryPhysicianList: [],
      primaryPhysician:
        this.props.Resident != null &&
        this.props.Resident.fkPrimaryPhysicianId > 0
          ? {
              value: props.Resident.fkPrimaryPhysicianId,
              label: props.Resident.primaryPhysicianName,
            }
          : null,
      primaryPhysiciansInputValue: "",
      residentStatus: [],
      physicianList: [],
      residentInsuranceList:
        this.props.Resident != null
          ? this.props.Resident.residentInsuranceList
          : [],
      addBtPhysicianComponent: "",
      addBtPhysicianModal: false,
      gender: [],
      states: [],
      isLoading: false,
      residentUpserting: false,
      Resident: JSON.parse(
        JSON.stringify(
          this.props.Resident != null ? this.props.Resident : sampleResidentBR
        )
      ),
      countryList: [],
    };

    this.insuranceSelection = createRef();
    this.intl = this.props.intl;
  }

  isLoading = (val) => {
    this.setState({ isLoading: val });
  };

  componentDidMount() {
    try {
      this.setState({ isLoading: false });
      if (
        this.state.Resident.id == null ||
        this.state.Resident.isHospicePatient == false
      ) {
        var resident = this.state.Resident;
        resident.shippingAddress.addressId = this.props.facility.addressId;
        resident.shippingAddressId = this.props.facility.addressId;
        resident.shippingAddress.addressLine1 = this.props.facility.addressLine1;
        resident.shippingAddress.addressLine2 = this.props.facility.addressLine2;
        resident.shippingAddress.city = this.props.facility.city;
        resident.shippingAddress.stateId = this.props.facility.stateId;
        resident.shippingAddress.countryId = this.props.facility.countryId;

        resident.shippingAddress.zip = this.props.facility.zip;
        resident.shippingAddress.phone1 = this.props.facility.phone1;
        resident.shippingAddress.fax = this.props.facility.fax;
        resident.shippingAddress.mobile1 = this.props.facility.mobile1;
        resident.shippingAddress.email = this.props.facility.email;
        resident.facility = this.props.facility;
        resident.facilityName = this.props.facility.facilityName;
        resident.fkSigningPhysicianId = this.props.facility.fkPhysicianId;
        resident.signingPhysicianName = Common.replaceNullWithString(
          this.props.facility.physicianName
        );
        debugger;
        this.setState({ Resident: resident });
      }
      if (Common.replaceNullWithString(this.props.FacilityId) == "") {
        Common.showSuccessAlertAutoClose(
          this.intl.formatMessage({ id: "NEWRESIDENT.FACILITY_ID_NOT_FOUND" }),
          "warning",
          this.intl.formatMessage({ id: "BUTTON.WARNING.TITLE" }),
          3000
        );

        this.props.hideModal();
      }

      this.getDropDownValueApiCall(7, "residentStatus");
      this.getDropDownValueApiCall(1006, "gender");
      this.GetPhysicianList();
      this.getAllState();
      this.GetCountryList();
      this.setState({ isLoading: true });
    } catch (error) {}
  }
  handleCloseAdd = () => {
    this.setState({ addBtPhysicianModal: false });
  };

  selectedResidentInsurance = (insurances) => {
    this.setState({ residentInsuranceList: insurances });
  };
  handleShowAddSigningPhysician = () => {
    this.setState({
      addBtPhysicianComponent: (
        <NewBrightreePhysician
          close={this.handleCloseAdd}
          selectPhysician={this.selectSigningPhysician}
        />
      ),
    });
    this.setState({ addBtPhysicianModal: true });
  };
  handleShowAddBrightreePhysician = () => {
    this.setState({
      addBtPhysicianComponent: (
        <NewBrightreePhysician
          close={this.handleCloseAdd}
          selectPhysician={this.selectPhysician}
        />
      ),
    });
    this.setState({ addBtPhysicianModal: true });
  };
  handleHospiceChange = (e) => {
    Common.withOutEventUpdateInputValueInState(
      this.state.Resident,
      this,
      this.state,
      e.target.checked,
      "onHospice"
    );
  };
  handleMedAChange = (e) => {
    Common.withOutEventUpdateInputValueInState(
      this.state.Resident,
      this,
      this.state,
      e.target.checked,
      "currentlyMedA"
    );

    Common.withOutEventUpdateInputValueInState(
      this.state.Resident,
      this,
      this.state,
      false,
      "onHospice"
    );
  };
  handleMedBChange = (e) => {
    Common.withOutEventUpdateInputValueInState(
      this.state.Resident,
      this,
      this.state,
      e.target.checked,
      "IsMedB"
    );
  };
  handleECPChange = (e) => {
    Common.withOutEventUpdateInputValueInState(
      this.state.Resident,
      this,
      this.state,
      e.target.checked,
      "isEcp"
    );
  };
  handleMedicateOnlyChange = (e) => {
    Common.withOutEventUpdateInputValueInState(
      this.state.Resident,
      this,
      this.state,
      e.target.checked,
      "isMedicaidOnly"
    );
  };

  onChangeHospicePt = (e) => {
    const { checked } = e.target;
    const { facility } = this.props;

    Common.updateInputValueInState(this.state.Resident, e, this, this.state);
    const clearShippingAddress = {
      addressId: null,
      addressLine1: "",
      addressLine2: "",
      city: "",
      stateId: null,
      zip: "",
      phone1: "",
      fax: "",
      mobile1: "",
      email: "",
      shippingAddressId: null,
    };
    const previousShippingDate = {
      addressId: facility.addressId,
      addressLine1: facility.addressLine1,
      addressLine2: facility.addressLine2,
      city: facility.city,
      stateId: facility.stateId,
      zip: facility.zip,
      phone1: facility.phone1,
      fax: facility.fax,
      mobile1: facility.mobile1,
      email: facility.email,
      shippingAddressId: facility.addressId,
    };

    this.setState((prevState) => {
      const resident = { ...prevState.Resident };

      resident.shippingAddress = checked
        ? clearShippingAddress
        : previousShippingDate;

      return { Resident: resident };
    });
  };

  formatSocialSecurity(val) {
    if (val != null) {
      val = val.replace(/\D/g, "");
      val = val.replace(/^(\d{3})/, "$1-");
      val = val.replace(/-(\d{2})/, "-$1-");
      val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
      return val;
    }
  }

  loadSingingPhysicians = async (inputValue) => {
    try {
      if (inputValue.length > 1) {
        var { data } = await Api.getRequestQry(
          Api.apiUrls.doctorsSearch,
          `?searchTerm=${inputValue}`
        );
        if (data.success) {
          let orgList = [];
          if (data.result.length) {
            orgList = data.result;
          }
          this.setState({ singingPhysicianList: orgList });
          // setOrganizations(orgList)
          return orgList.map((e) => {
            return {
              label: e.lastName + " " + e.firstName,
              value: e.brightreeId,
            };
          });
        }
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  setSingingPhysicianInputValue = async (value) => {
    this.setState({ singingphysiciansInputValue: value });
  };
  setPhysician = (physician) => {
    debugger;
    var _selectedPhysician = this.state.Resident;
    if (this.state.isPrimaryModal) {
      this.setState({
        primaryPhysician: {
          value: physician.physicianId,
          label: physician.lastName + " " + physician.firstName,
        },
      });
      _selectedPhysician.physicianViewModel = physician;
      _selectedPhysician.fkPrimaryPhysicianId = null;
      _selectedPhysician.primaryPhysicianNpi = physician?.npi;
    } else {
      this.setState({
        singingPhysician: {
          value: physician.physicianId,
          label: physician.lastName + " " + physician.firstName,
        },
      });
      _selectedPhysician.physicianSigningOrder = physician;
      _selectedPhysician.fkSigningPhysicianId = null;
      _selectedPhysician.signingPhysicianNpi = physician?.npi;
    }
  };
  setSingingPhysician = (physician) => {
    this.setState({ singingPhysician: physician });
    var _selectedPhysician = this.state.Resident;
    if (physician != null) {
      var obj = this.state.singingPhysicianList.find(
        (x) => x.brightreeId == physician.value
      );
      debugger;
      _selectedPhysician.physicianSigningOrder = obj;
      _selectedPhysician.fkSigningPhysicianId = null;
      _selectedPhysician.signingPhysicianNpi = obj?.npi;

      _selectedPhysician.signingPhysicianName =
        Common.replaceNullWithString(obj.lastName) +
        " " +
        Common.replaceNullWithString(obj.firstName);
    } else {
      _selectedPhysician.physicianSigningOrder = null;
      _selectedPhysician.fkSigningPhysicianId = null;
      _selectedPhysician.signingPhysicianName = "";
      _selectedPhysician.signingPhysicianNpi = null;
    }

    this.setState({ Resident: _selectedPhysician });
  };

  loadPrimaryPhysicians = async (inputValue) => {
    try {
      if (inputValue.length > 1) {
        var { data } = await Api.getRequestQry(
          Api.apiUrls.doctorsSearch,
          `?searchTerm=${inputValue}`
        );
        if (data.success) {
          let orgList = [];
          if (data.result.length) {
            orgList = data.result;
          }
          this.setState({ primaryPhysicianList: orgList });
          // setOrganizations(orgList)
          return orgList.map((e) => {
            return {
              label: e.lastName + " " + e.firstName,
              value: e.brightreeId,
            };
          });
        }
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  setPrimaryPhysicianInputValue = async (value) => {
    this.setState({ primaryPhysiciansInputValue: value });
  };
  setPrimaryPhysician = (physician) => {
    debugger;
    this.setState({ primaryPhysician: physician });
    var _selectedPhysician = this.state.Resident;
    if (physician != null) {
      var obj = this.state.primaryPhysicianList.find(
        (x) => x.brightreeId == physician.value
      );
      _selectedPhysician.physicianViewModel = obj;
      _selectedPhysician.fkPrimaryPhysicianId = null;
      _selectedPhysician.primaryPhysicianNpi = obj?.npi;
      _selectedPhysician.fkPrimaryPhysicianId = null;

      _selectedPhysician.primaryPhysicianName =
        Common.replaceNullWithString(obj?.lastName) +
        " " +
        Common.replaceNullWithString(obj?.firstName);
    } else {
      _selectedPhysician.physicianViewModel = null;
      _selectedPhysician.fkPrimaryPhysicianId = null;
      _selectedPhysician.primaryPhysicianName = "";
      _selectedPhysician.primaryPhysicianNpi = null;
    }

    this.setState({ Resident: _selectedPhysician });
  };

  render() {
    return (
      <>
        {this.state.addBtPhysicianModal && (
          <Animated animationIn="fadeInLeft" animationInDuration={1000}>
            {this.state.addBtPhysicianComponent}
          </Animated>
        )}
        {!this.state.addBtPhysicianModal && (
          <Animated animationIn="fadeIn" animationInDuration={1000}>
            {!this.state.isLoading == true && (
              <div className="d-flex justify-content-center">
                <Loader type="Puff" color="#00BFFF" height={50} width={50} />
              </div>
            )}
            {
              <Form
                ref={(ref) => {
                  this.form = ref;
                }}
                validated={this.state.formValidated}
              >
                <CreateResidentFormWrapper
                  state={this.state}
                  setState={(state) => this.setState(state)}
                  insuranceSelectionRef={this.insuranceSelection}
                  setOptionsUI={this.setOptionsUI}
                  setPrimaryPhysicianInputValue={
                    this.setPrimaryPhysicianInputValue
                  }
                  loadPrimaryPhysicians={this.loadPrimaryPhysicians}
                  setPrimaryPhysician={this.setPrimaryPhysician}
                  handleHospiceChange={this.handleHospiceChange}
                  handleECPChange={this.handleECPChange}
                  handleMedAChange={this.handleMedAChange}
                  handleMedBChange={this.handleMedBChange}
                  handleMedicateOnlyChange={this.handleMedicateOnlyChange}
                  setSingingPhysicianInputValue={
                    this.setSingingPhysicianInputValue
                  }
                  loadSingingPhysicians={this.loadSingingPhysicians}
                  setSingingPhysician={this.setSingingPhysician}
                  selectedResidentInsurance={this.selectedResidentInsurance}
                  onChangeHospicePt={this.onChangeHospicePt}
                  thisReference={this}
                  getCountryList={this.GetCountryList}
                  {...this.props}
                />
              </Form>
            }
            {this.state.physicianModal && (
              <AddEditPhysician
                onSaveAPI={this.setPhysician}
                open={this.state.physicianModal}
                toggleModal={() => this.setState({ physicianModal: false })}
              />
            )}
          </Animated>
        )}
      </>
    );
  }

  GetPhysicianList() {
    Api.getRequest(Api.URI_GetAllPhysician)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setState({ physicianList: res.data.result });
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Resident", "GetPhysicianList");
      });
  }

  setPhysicianListUI(list) {
    return list.map((state) => (
      <option key={state.physicianId} value={state.physicianId}>
        {Common.replaceNullWithString(state.lastName) +
          " " +
          Common.replaceNullWithString(state.firstName)}
      </option>
    ));
  }

  getDropDownValueApiCall(id, state) {
    Api.getRequestById(Api.Uri_GetLookupValuesByFkId, id)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setState({ [state]: res.data.result });
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Resident", "getDropDownValueApiCall");
      });
  }

  getAllState() {
    Api.getRequest(Api.Uri_GetState)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setState({ states: res.data.result });
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Resident", "GetAllState");
      });
  }

  setOptionsUI(options) {
    return options.map((option) => (
      <option key={option.key} value={option.key}>
        {option.value}
      </option>
    ));
  }

  handleSubmit = (event) => {
    if (this.form.checkValidity() === false) {
      let _state = this.state;
      _state.formValidated = true;
      this.setState(_state);
    } else {
      let obj = this.state.Resident;
      if (obj.id > 0) {
        this.UpsertResidentApiCall(null, null);
      } else {
        this.handleResidentSearchFC();
      }
    }
  };

  UpsertResidentApiCall(btPatientBrightreeID = null, residentBtKey = null) {
    const { facility } = this.props;
    const { Resident } = this.props;
    let obj = this.state.Resident;

    const someRequiredFieldIsEmpty = !!requiredFieldsForAlternativeShippingAddress.find(
      (field) => !obj.shippingAddress[field]
    );

    this.isLoading(false);
    this.props.setResidentLoading(true);

    obj.signingPhysicianPhone =
      Common.replaceNullWithString(obj.signingPhysicianPhone) != ""
        ? obj.signingPhysicianPhone.replace(/[^0-9]/g, "").substr(0, 10)
        : null;

    if (obj.billingAddress != null) {
      obj.billingAddress.phone1 =
        Common.replaceNullWithString(obj.billingAddress.phone1) != ""
          ? obj.billingAddress.phone1.replace(/\D/g, "").substr(0, 10)
          : null;
      obj.billingAddress.mobile1 =
        Common.replaceNullWithString(obj.billingAddress.mobile1) != ""
          ? obj.billingAddress.mobile1.replace(/\D/g, "").substr(0, 10)
          : null;
    }

    if (obj.raddress != null) {
      obj.raddress.phone1 =
        Common.replaceNullWithString(obj.raddress.phone1) != ""
          ? obj.raddress.phone1.replace(/\D/g, "").substr(0, 10)
          : null;
      obj.raddress.mobile1 =
        Common.replaceNullWithString(obj.raddress.mobile1) != ""
          ? obj.raddress.mobile1.replace(/\D/g, "").substr(0, 10)
          : null;
    }

    if (obj.shippingAddress != null) {
      obj.shippingAddress.phone1 =
        Common.replaceNullWithString(obj.shippingAddress.phone1) != ""
          ? obj.shippingAddress.phone1.replace(/\D/g, "").substr(0, 10)
          : null;
      obj.shippingAddress.mobile1 =
        Common.replaceNullWithString(obj.shippingAddress.mobile1) != ""
          ? obj.shippingAddress.mobile1.replace(/\D/g, "").substr(0, 10)
          : null;
    }

    obj.ssn = ssnWithoutSpecialChar(obj.ssn);

    debugger;
    if (obj.isHospicePatient && someRequiredFieldIsEmpty) {
      obj.shippingAddress = {
        addressId: facility.addressId,
        addressLine1: facility.addressLine1,
        addressLine2: facility.addressLine2,
        city: facility.city,
        stateId: facility.stateId,
        zip: facility.zip,
        countryId: facility.countryId,
        phone1:
          Common.replaceNullWithString(facility.phone1) != ""
            ? obj.shippingAddress.phone1.replace(/\D/g, "").substr(0, 10)
            : null,
        fax: facility.fax,
        mobile1:
          Common.replaceNullWithString(facility.mobile1) != ""
            ? obj.shippingAddress.mobile1.replace(/\D/g, "").substr(0, 10)
            : null,
        email: facility.email,
        shippingAddressId: facility.addressId,
      };
    }

    debugger;
    obj.fkFacilityId = this.props.FacilityId;
    obj.createdBy =
      Resident && Resident.createdBy != null
        ? Resident.createdBy
        : this.props.userID;
    obj.modifiedBy =
      Resident && Resident.modifiedBy != null
        ? Resident.modifiedBy
        : this.props.userID;
    obj.btPatientBrightreeID = btPatientBrightreeID;
    obj.residentBtKey = residentBtKey;
    obj.residentInsuranceList = this.insuranceSelection?.current?.getInsuranceList();
    Api.postRequest(Api.URI_UpsertResident, obj)
      .then((res) => {
        debugger;
        if (res.data.success) {
          this.props.setResidentLoading(false);
          Common.showSuccessAlertAutoClose(
            this.intl.formatMessage({
              id: "NEWRESIDENT.CREATE_RESIDENT_SUCCESS",
            }),
            "success",
            this.intl.formatMessage({ id: "BUTTON.SUCCESS.TITLE" }),
            3000
          );
          this.props.close();
          if (obj.id == null) {
            this.isLoading(true);
            if (res.data.result != null) {
              obj.id = res.data.result.id;
              if (this.props.handleNewWound) {
                this.props.handleNewWound(obj);
              }
            } else {
              console.log("not translated:" + res.data.message)
              Common.showSuccessAlert(
                res.data.message,
                "warning",
                this.intl.formatMessage({ id: "BUTTON.FAILED.TITLE" }),
                3000
              );
            }
          }
        } else {
          this.props.setResidentLoading(false);
          Common.showSuccessAlert(
            this.intl.formatMessage({
              id: "NEWRESIDENT.ERROR_OCCURRED_WHILE_UPDATING_THE_ENTRIES",
            }),
            "warning",
            this.intl.formatMessage({ id: "BUTTON.FAILED.TITLE" }),
            3000
          );
        }
      })
      .catch((ex) => {
        this.props.setResidentLoading(false);
        Common.showSuccessAlertAutoClose(
          this.intl.formatMessage({
            id: "NEWRESIDENT.SOME_ERROR_OCCURED_WHILE_PROCESSING_YOUR_REQUEST",
          }),
          "warning",
          this.intl.formatMessage({ id: "BUTTON.FAILED.TITLE" }),
          3000
        );
        Common.LogError(ex, "Resident", "UpsertResident");
      })
      .then(() => {
        this.isLoading(true);
      });
  }
  handleResidentSearchFC = async () => {
    this.isLoading(false);
    this.props.setResidentLoading(true);

    let obj = this.state.Resident;

    obj.ssn = ssnWithoutSpecialChar(obj.ssn);

    var { data } = await Api.getRequestQry(
      Api.Uri_ResidentSearchFC,
      "?facilityId=" +
        this.props.FacilityId +
        "&lastName=" +
        obj.lastName +
        "&ssn=" +
        obj.ssn +
        "&dob=" +
        obj.doB
    );

    if (data.result.isMatchInFC == true) {
      var displayDenayButton = obj.ssn.length > 0 ? false : true;
      this.isLoading(true);
      this.props.setResidentLoading(false);
      Common.showConfirmation(
        data.result.message,
        "",
        this.showResidentAlreadyOnFileInFC,
        null,
        data.result,
        "Yes",
        displayDenayButton,
        displayDenayButton == true
          ? this.userTapedNoAddResidentWhenAlreadyExistsInFC
          : null
      );
    } else {
      this.handleResidentSearchBT();
    }
  };
  showResidentAlreadyOnFileInFC = (result) => {
    let obj = this.state.Resident;
    if (obj.ssn.length > 0) {
      if (result.isOpen) {
        Common.showSuccessAlert(
          this.intl.formatMessage({
            id: "NEWRESIDENT.THIS_RESIDENT_ALREADY_EXISTS_1",
          }),
          "success",
          this.intl.formatMessage({ id: "BUTTON.SUCCESS.TITLE" }),
          3000
        );
      } else {
        Common.showSuccessAlert(
          this.intl.formatMessage({
            id: "NEWRESIDENT.THIS_RESIDENT_ALREADY_EXISTS_2",
          }),
          "success",
          this.intl.formatMessage({ id: "BUTTON.SUCCESS.TITLE" }),
          3000
        );
      }
    } else {
      Common.showSuccessAlert(
        this.intl.formatMessage({
          id: "NEWRESIDENT.THIS_RESIDENT_ALREADY_EXISTS_3",
        }),
        "success",
        this.intl.formatMessage({ id: "BUTTON.SUCCESS.TITLE" }),
        3000
      );
    }
  };

  userTapedNoAddResidentWhenAlreadyExistsInFC = () => {
    Common.showConfirmation(
      this.intl.formatMessage({
        id: "NEWRESIDENT.DID_YOU_STILL_WANT_TO_ADD_THIS_NEW_RESIDENT",
      }),
      "",
      this.handleResidentSearchBT,
      null,
      null
    );
  };

  handleResidentSearchBT = async () => {
    const { facility } = this.props;

    // if there's no brightreeId, then it simply does not exists , so a new one will be created
    if (!facility.brightreeId) {
      this.UpsertResidentApiCall();
      return;
    }

    let obj = this.state.Resident;

    obj.ssn = ssnWithoutSpecialChar(obj.ssn);

    var { data } = await Api.getRequestQry(
      Api.Uri_ResidentSearchBt,
      "?facilityId=" +
        this.props.facility.brightreeId +
        "&lastName=" +
        obj.lastName +
        "&ssn=" +
        obj.ssn +
        "&dob=" +
        obj.doB
    );

    var displayDenayButton = obj?.ssn != "" || obj?.ssn != null ? false : true;

    if (data.result.isMatchInBt == true) {
      if (displayDenayButton == true) {
        Common.showConfirmation(
          data.result.message,
          "",
          this.showResidentAlreadyOnFileInBt,
          null,
          data.result,
          "Yes",
          true,
          this.UpsertResidentApiCall
        );
      } else {
        this.UpsertResidentApiCall(
          data.result.residentBtId,
          data.result.residentBtKey
        );
      }
    } else {
      this.UpsertResidentApiCall();
    }
  };

  showResidentAlreadyOnFileInBt = (residentDetail) => {
    this.UpsertResidentApiCall(
      residentDetail.residentBtId,
      residentDetail.residentBtKey
    );
  };

  GetCountryList() {
    Api.getRequest(Api.Uri_GetCountry)
      .then((res) => {
        if (res.data.statusCode === 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setState({ countryList: res.data.result });
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Resident", "GetCountryList");
      });
  }
}

export default injectIntl(CreateResidentBR, { forwardRef: true });
