
import { FormattedMessage } from "react-intl";
import React from 'react';
import { Text, View, StyleSheet  } from '@react-pdf/renderer';
import TableHeader from './tableHeader';
import TableSecondHeader from './tableSecondHeader';

const styles = StyleSheet.create({
    headerLineOne: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    headerLineTwo: {
        display: 'flex',
        marginBottom: 10
    }
});

const DocumentHeader = ({facilityName, toDate, fromDate, woundType, intl}) => {
    return (
        <View fixed>
            <View style={[styles.headerLineOne, {fontSize: 10, fontFamily: 'Helvetica-Bold'}]}>
                <Text>
                    <FormattedMessage id="REPORTS.WOUNDS.FACILITY_NAME" defaultMessage="Facility Name" />: {facilityName}
                </Text>
                <Text>
                    <FormattedMessage id="REPORTS.WOUNDS.WOUND_REPORT" defaultMessage="Wound Report" />:
                </Text>
                <Text>
                    <span className="font-weight-bolder">
                        {" "}
                        <FormattedMessage
                            id="REPORTS.WOUNDS.FROM"
                            defaultMessage="From"
                        />
                        :{" "}
                        {intl.formatDate(fromDate, {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        })}{" "}
                        <FormattedMessage
                            id="REPORTS.WOUNDS.TO"
                            defaultMessage="To"
                        />
                        :{" "}
                        {intl.formatDate(toDate, {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        })}{" "}
                    </span>
                </Text>
            </View>
            <View style={[styles.headerLineTwo, {fontSize: 10, fontFamily: 'Helvetica-Bold'}]}>
                <Text>
                <span>
                    <FormattedMessage
                      id="REPORTS.WOUNDS.WOUND_TYPE"
                      defaultMessage="Wound Type"
                    />
                    :
                  </span>{" "}
                  {woundType}
                </Text>
            </View>
            <TableHeader intl={intl} />
            <TableSecondHeader intl={intl} />
        </View>
    )
} 

export default DocumentHeader;
