import React from "react";

import { BRFacilityQueueFetchHandler } from "./region/brHandleFetchFacilityQueue";
import { UKFacilityQueueFetchHandler } from "./region/ukHandleFetchFacilityQueue";
import { USFacilityQueueFetchHandler } from "./region/usHandleFetchFacilityQueue";

import	{ withMultiRegionFunction } from "@/shared/wrappers/withMultiRegion";


export const CreateOnMountFetchFacilityQueue = withMultiRegionFunction({
    UK: UKFacilityQueueFetchHandler,
    US: USFacilityQueueFetchHandler,
    BR: BRFacilityQueueFetchHandler
});

