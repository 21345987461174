import React from 'react';
import { View } from "@react-pdf/renderer";

const Separator = () => {
    return (
        <View style={{height: 10, width: '100%', backgroundColor: '#FFF', borderRight: '1px solid #EBEDF3', borderBottom: '1px solid #EBEDF3'}}>
        </View>
    )
}

export default Separator;