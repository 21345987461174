import React, { forwardRef } from "react";

import CreateResidentUK from "./lang/createResidentUK";
import CreateResidentBR from "./lang/createResidentBR";
import CreateResidentUS from "./lang/createResidentUS";
import { withMultiRegionComponent } from "@/shared/wrappers/withMultiRegion";

const CreateResidentMultiRegion = withMultiRegionComponent({
  UK: CreateResidentUK,
  US: CreateResidentUS,
  BR: CreateResidentBR,
});

const CreateResidentWrapper = forwardRef((props, ref) => {
  return <CreateResidentMultiRegion ref={ref} {...props} />;
});

export default CreateResidentWrapper;
