import { withMultiRegionFunction } from "@/shared/wrappers/withMultiRegion";
import ssnWithoutSpecialCharBR from "./ssnWithoutSpecialCharBR.fn";
import ssnWithoutSpecialCharUS from "./ssnWithoutSpecialCharUS.fn";
import ssnWithoutSpecialCharUK from "./ssnWithoutSpecialCharUK.fn";

const ssnWithoutSpecialCharCountry = {
  BR: ssnWithoutSpecialCharBR,
  US: ssnWithoutSpecialCharUS,
  UK: ssnWithoutSpecialCharUK,
};

export default function ssnWithoutSpecialChar(ssn) {
  return withMultiRegionFunction(ssnWithoutSpecialCharCountry)(ssn);
}
