import React from 'react';
import { View} from '@react-pdf/renderer';
import { facilityWoundReportStyles } from './reportDocument';
import WoundDetailFirstRow from './woundDetailFirstRow';
import WoundDetailSecondRow from './woundDetailSecondRow';
import Separator from './separator';

const DocumentBody = ({details, intl}) => {
    return (
        <View style={facilityWoundReportStyles.table}>            
            {details.map(detail => (
                <View wrap={false}>
                    <WoundDetailFirstRow detail={detail} intl={intl} />
                    <WoundDetailSecondRow detail={detail} intl={intl} />
                    <Separator />
                </View>
            ))}
      </View>
    )
}


export default DocumentBody;