import React from "react";
import getRegion from "@/shared/functions/getRegion";

const region = getRegion().components;

export function withMultiRegionComponent(ComponentCountries) {
  return React.forwardRef((props, ref) => {
    const ComponentCountry =
      ComponentCountries[region] || ComponentCountries.US;
    return <ComponentCountry {...props} ref={ref} />;
  });
}

export function withMultiRegionFunction(FunctionCountries) {
  const functionRegion = FunctionCountries[region] || FunctionCountries.US;
  return functionRegion;
}

export function withMultiRegionProperties(PropertiesCountries) {
  const propertiesRegion =
    PropertiesCountries[region] || PropertiesCountries.US;
  return propertiesRegion;
}
