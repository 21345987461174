import React from "react";

import CreateResidentFormInputUS from "./lang/createResidentFormInputUS";
import CreateResidentFormInputBR from "./lang/createResidentFormInputBR";
import CreateResidentFormInputUK from "./lang/createResidentFormInputUK";
import { withMultiRegionComponent } from "@/shared/wrappers/withMultiRegion";

const CreateResidentMultiRegion = withMultiRegionComponent({
  US: CreateResidentFormInputUS,
  BR: CreateResidentFormInputBR,
  UK: CreateResidentFormInputUK
});

function CreateResidentFormWrapper(props) {
  return <CreateResidentMultiRegion {...props} />;
}

export default CreateResidentFormWrapper;
