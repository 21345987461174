import React from "react";
import { getAllFacility, getAllPartailOrderFacilityList } from './common';



export const USFacilityQueueFetchHandler = (payload) => {
    getAllFacility(payload.setFacilityListUi);

    getAllPartailOrderFacilityList(payload.setPartailFacilityList,payload.setIsLoading)
}


