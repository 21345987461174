import React from "react";
import { Tab, Tabs } from "react-bootstrap";
// import WorkQueue from "./workqueue";
// import OstomyFacility from "./ostomyFacility";
// import UrologicalFacility from "./urologicalFacility";
// import TracheostomyFacility from "./tracheostomyFacility";
import NewWound from './newwound';
import ViewWound from './viewWound';
import NewWoundAssessment from '@/app/pages/resident/newWoundAssessment';

import * as Common from "../../General/common";


export function WoundEvaluationAndAssessmentTab(props) {

    return (
        <>
            <Tabs defaultActiveKey="wounds" id="uncontrolled-tab-example">
                <Tab eventKey="wounds" title={
                    <div>
                        Wound Evaluation
                        <div>
                            <small>
                                <span className='badge badge-pill badge-primary my-auto '>
                                {/* {Common.getInputStringDateFormat(new Date(props.facility.cycleDate))} */}
                            </span>
                            </small>
                        </div>
                    </div>}>
             <NewWound refresh={props.refresh} isEdit={false}
            isReorder={props.isReorder}
            showProduct={false}
            isReadOnly={false}
            displayProduct={true}
            user={props.user} 
            close={props.close}
            resident={props.resident} 
            isWorkQueue={true}
            queue={props.queue || Common.ProcessStatus.workQueueID}
            // ref={(ref) => {
            //     newWoundInfo = ref;
            // }} 
            />
                </Tab>
                <Tab disabled={!props.isReorder} eventKey="ostomy" title={
                    <div>
                        Previous Dispensing Order
                        <div>
                            <small>
                                <span className='badge badge-pill badge-primary my-auto '>
                               
                            </span>
                            </small>
                        </div>
                    </div>
                }>
                    {props.lastEvaluation!=null && props.lastEvaluation!=undefined &&
                     <ViewWound refresh={props.refresh}
                     queue={props.queue ? props.queue : Common.ProcessStatus.workQueueID}
                isEdit={true}
                isReorder={props.isReorder}
                showProduct={true}
                isReadOnly={true}
                displayProduct={true}
                user={props.user} resident={props.lastEvaluation}  />      
                    }
                </Tab>

                <Tab eventKey="urological" title={
                    <div>
                        Last Assessment
                        <div>
                            <small>
                                <span className='badge badge-pill badge-primary my-auto '>
                              
                            </span>
                            </small>
                        </div>
                    </div>
                }>
                    {props.lastAssesment!=null &&props.lastAssesment!=undefined&&
                   <NewWoundAssessment isEdit={false} canView={false} user={props.user} resident={props.lastAssesment}
                  
                   />
                }
                </Tab>

          
            </Tabs>
        </>
    );
}
export default WoundEvaluationAndAssessmentTab;