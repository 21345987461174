import React, { useCallback, useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import { FormattedMessage, FormattedDate } from "react-intl";
import moment from "moment";
import { forwardRef } from "react";
import * as Common from "@/app/General/common";
import formatZipCode from "@/shared/functions/formats/formatZipCode";


const ResidentDetailsUK = forwardRef((props, ref) => {
    const [genderDropdown, setGenderDropdown] = useState([]);
    const { intl } = props;

    const {
        firstName,
        middleName,
        lastName,
        ssn,
        doB,
        unit,
        room,
        admissionDate,
        residentStatus,
        gender,
        isWhiteley,
        isSelfReferral,
        isNHSReferral,
        raddress,
        residentInsuranceList = [],
        residentEmergencyContact = {},
        height,
        weight,
        smokingStatus,
        allergicToSulpha,
        allergicToCollagen,
        allergicToIodine,
        allergicToHoney,
        allergicToPenicillin,
        allergicToLatex,
        allergicToTape,
        allergicToAdhesive,
        otherAllergies,
        otherAllergiesDescription,
        noKnownAllergies,
        hadAsthma,
        hadCOPD,
        hadCardiovascularDisease,
        hadDepressionAnxiety,
        hadDiabetes1Insulin,
        hadDiabetes2Diet,
        hadDiabetes2insulin,
        hadGERD,
        hadHypercholesterolemia,
        hadHyperlipidemia,
        hadHypertension,
        hadObesity,
        hadOsteoarthritis,
        hadOsteoparosis,
        hadProstateHyperplasia,
        otherMedicalHistory,
        otherMedicalHistoryDescription,
        insuranceName,
        insurancePolicyNo
    } = props.resident;
    
    const fetchGenderDropDown = useCallback(async () => {

        try {
            const response = await Common.getLookupOptionsObjectByHeaderName("gender")
            setGenderDropdown(response)    
        }
        catch (e) {
        }
    }, [])

    useEffect(() => {
        fetchGenderDropDown();
    }, [])

    const genderName = useMemo(() =>{ 
        if(!genderDropdown) return 0;
        var residentGender = genderDropdown?.find(g => g.key == gender)
        return residentGender ? residentGender.value : '-';
    
    },  [genderDropdown, gender]);

    const residentInsurance = useMemo(() => {
        return (
            <tr >
                <td>{insuranceName}</td>
                <td>{insurancePolicyNo}</td>
            </tr>);

    }, []);

    const calculateBMI = () => {
        const bmi = weight / (height * height);
        return bmi.toFixed(2);
      };
    
      const getBMIInterpretation = (bmi) => {
        if (!bmi) return "";
    
        if (bmi < 18.5) {
          return "Underweight";
        } else if (bmi >= 18.5 && bmi <= 24.9) {
          return "Normal weight";
        } else if (bmi >= 25 && bmi <= 29.9) {
          return "Overweight";
        } else if (bmi >= 30) {
          return "Obese";
        }
      };


    return (
        <div className="w-100">

            <fieldset className="rounded border p-2 mb-3">
                <legend className="text-primary fw-500">
                    <FormattedMessage id="RESIDENTDETAILS.BASIC_INFORMATION" />:
                </legend>
                <table
                    className="table table-sm border-0 w-100 table-striped"
                    id=""
                >
                    <tbody>
                        <tr>
                            <td className="font-weight-bolder">
                                <FormattedMessage id="RESIDENTDETAILS.RESIDENT" />
                            </td>
                            <td>
                                {`${firstName} ${middleName} ${lastName}`}
                            </td>

                            <td className="font-weight-bolder">
                                <FormattedMessage id="RESIDENTDETAILS.GENDER" />
                            </td>
                            <td>{genderName}</td>
                            <td className="font-weight-bolder">
                                <FormattedMessage id="RESIDENTDETAILS.PACIENT_STATUS" />
                            </td>
                            <td>{residentStatus}</td>
                            <td className="font-weight-bolder">
                                <FormattedMessage id="RESIDENTDETAILS.DATE_OF_BIRTH" />
                            </td>
                            <td>{<FormattedDate value={doB} />}</td>
                        </tr>

                        <tr>
                            <td className="font-weight-bolder">
                                Age
                            </td>
                            <td>{moment().diff(doB, 'years')}</td>
                            <td className="font-weight-bolder">
                                NHS Number
                            </td>
                            <td>{ssn}</td>

                            <td className="font-weight-bolder">
                                Private Whiteley
                            </td>
                            <td><Checkbox checked={isWhiteley} value="checkedA" /></td>


                            <td className="font-weight-bolder">
                                Private Self-Referall
                            </td>
                            <td><Checkbox checked={isSelfReferral} value="checkedA" /></td>


                        </tr>

                        <tr>
                            <td className="font-weight-bolder">
                                NHS Referral
                            </td>
                            <td><Checkbox checked={isNHSReferral} value="checkedA" /></td>
                            <td className="font-weight-bolder">
                                <FormattedMessage id="RESIDENTDETAILS.CURRENT_ADMISSION_DATE" />
                            </td>
                            <td>{<FormattedDate value={admissionDate} />}</td>

                            <td className="font-weight-bolder">
                                <FormattedMessage id="RESIDENTDETAILS.UNIT" />
                            </td>
                            <td>{unit}</td>

                            <td className="font-weight-bolder">
                                <FormattedMessage id="RESIDENTDETAILS.ROOM" />
                            </td>
                            <td>{room}</td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>


            <fieldset className="rounded border p-2 mb-3">
                <legend className="text-primary fw-500">
                    <FormattedMessage id="ADDFACILITY.ADDRESS_INFORMATION" />
                </legend>
                <table className="table table-sm border-0 w-100 table-striped">
                    <tbody>
                        <tr>
                            <td className="font-weight-bolder">
                                Address Line 1
                            </td>
                            <td>{raddress.addressLine1}</td>
                            <td className="font-weight-bolder">
                                Address Line 2
                            </td>
                            <td>{raddress.addressLine2}</td>
                            <td className="font-weight-bolder">
                                City
                            </td>
                            <td>{raddress.city}</td>
                            <td className="font-weight-bolder">
                                Postal Code
                            </td>
                            <td>{formatZipCode(raddress.zip, "UK")}</td>
                            <td className="font-weight-bolder">
                                Country
                            </td>
                            <td>{raddress.countryName}</td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>




            <fieldset className="rounded border p-2 mb-3">
                <legend className="text-primary fw-500">
                    <FormattedMessage id="NEWRESIDENT.INSURANCE_INFORMATION" />
                </legend>
                <table
                    className="table table-sm border-0 w-100 table-striped"
                >
                    <tbody>
                        <tr>
                            <td className="font-weight-bolder">
                                <FormattedMessage id="NEWRESIDENT.INSURANCE.INSURANCE_NAME" />
                            </td>
                            <td className="font-weight-bolder">
                                <FormattedMessage id="NEWRESIDENT.INSURANCE.POLICY_NO" />
                            </td>
                        </tr>
                        {
                            residentInsurance
                        }
                    </tbody>
                </table>
            </fieldset>



            <fieldset className="rounded border p-2 mb-3">
                <legend className="text-primary fw-500">
                    <FormattedMessage id="NEWRESIDENT.EMERGENCY_CONTACTS" />
                </legend>
                <table className="table table-sm border-0 w-100 table-striped">
                    <tbody>
                        <tr>
                            <td className="font-weight-bolder">
                                Name
                            </td>
                            <td className="font-weight-bolder">
                                Phone
                            </td>
                            <td className="font-weight-bolder">
                                Relationship
                            </td>
                        </tr>
                        {residentEmergencyContact && (<tr>
                            <td>{residentEmergencyContact.name}</td>
                            <td>{residentEmergencyContact.phoneNumber}</td>
                            <td>{residentEmergencyContact.relationship}</td>
                        </tr>)}

                    </tbody>
                </table>
            </fieldset>



            <fieldset className="rounded border p-2 mb-3">
                <legend className="text-primary fw-500">
                    Determinants of Health
                </legend>
                <table className="table table-sm border-0 w-100 table-striped">
                    <tbody>
                        <tr>
                            <td className="font-weight-bolder">
                                Height
                            </td>
                            <td>{height}</td>
                            <td className="font-weight-bolder">
                                Weight
                            </td>
                            <td>{weight}</td>

                            <td className="font-weight-bolder">
                                BMI
                            </td>
                            <td>{calculateBMI()}</td>

                            <td className="font-weight-bolder">
                                BMI Interpretation
                            </td>
                            <td>{getBMIInterpretation(calculateBMI())}</td>

                            <td className="font-weight-bolder">
                                Smoking Status
                            </td>
                            <td>{smokingStatus}</td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>


            <fieldset className="rounded border p-2 mb-3">
                <legend className="text-primary fw-500">
                    Allergies
                </legend>
                <table className="table table-sm border-0 w-100 table-striped">
                    <tbody>
                        <tr>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={allergicToSulpha} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Sulfa</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={allergicToCollagen} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Collagen</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={allergicToIodine} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Iodine</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={allergicToHoney} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Honey</span>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={allergicToPenicillin} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Penicillin</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={allergicToLatex} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Latex</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                        <Checkbox checked={allergicToAdhesive} value="checkedA" />
                                        <span className="ml-2 font-weight-bolder">Adhesive</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                        <Checkbox checked={otherAllergies} value="checkedA" />
                                        <span className="ml-2 font-weight-bolder">Other</span>
                                        <span className="ml-2">{otherAllergiesDescription}</span>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={noKnownAllergies} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">No Known Allergies</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>


            <fieldset className="rounded border p-2 mb-3">
                <legend className="text-primary fw-500">
                    Medical History
                </legend>
                <table className="table table-sm border-0 w-100 table-striped">
                    <tbody>
                        <tr>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadHypertension} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Hypertension</span>
                                </div>
                            </td>

                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadHypercholesterolemia} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Hypercholesterolemia</span>
                                </div>
                            </td>

                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadHyperlipidemia} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Hyperlipidemia</span>
                                </div>
                            </td>

                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadCardiovascularDisease} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Cardiovascular Disease</span>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadOsteoarthritis} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Osteoarthritis</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadDiabetes1Insulin} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Diabetes Type 1 on insulin</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadDiabetes2insulin} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Diabetes Type 2 on insulin</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadDiabetes2Diet} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Diabetes Type 2 on diet control</span>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadProstateHyperplasia} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Benign Prostate Hyperplasia</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadDepressionAnxiety} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Depression / Anxiety</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadGERD} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Gastroesophageal Reflux Disease</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadOsteoparosis} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Osteoparosis</span>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadObesity} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Obesity</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadAsthma} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Asthma</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={hadCOPD} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">COPD</span>
                                </div>
                            </td>
                            <td style={{ width: '25%' }}>
                                <div className="d-flex align-items-center">
                                    <Checkbox checked={otherMedicalHistory} value="checkedA" />
                                    <span className="ml-2 font-weight-bolder">Other</span>
                                    <span className="ml-2">{otherMedicalHistoryDescription}</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>
        </div>
    )
})

export default injectIntl(ResidentDetailsUK, { forwardRef: true });
