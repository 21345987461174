import React from "react";
import * as Common from "@/app/General/common";
import { injectIntl } from "react-intl";
import moment from "moment";
import FastcareInputToolkit from "@/shared/toolkits/fastcareInputToolkit";
import ResidentSmokingStatus from "@/app/pages/resident/residentSmokingStatus";
import InsuranceWrapper from "@/shared/components/insurance";
import formatPhoneNumber from "@/shared/functions/formats/formatPhoneNumber";
import formatZipCode from "@/shared/functions/formats/formatZipCode";

function CreateResidentFormInputUK({
  intl,
  state,
  setState,
  insuranceSelectionRef,
  setOptionsUI,
  setPrimaryPhysicianInputValue,
  loadPrimaryPhysicians,
  setPrimaryPhysician,
  handleWhiteleyChange,
  handleSelfReferralChange,
  handleNHSReferralChange,
  setSingingPhysicianInputValue,
  loadSingingPhysicians,
  setSingingPhysician,
  selectedResidentInsurance,
  thisReference,
  handleInsuranceNameChange,
  ...props
}) {
  const { placeholderSSN, patternSSN, formatSSN } = FastcareInputToolkit.ssn;

  const calculateBMI = () => {
    const height = state.Resident.height;
    const weight = state.Resident.weight;

    if (height && weight) {
      const bmi = weight / (height * height);
      return bmi.toFixed(2);
    }
    return "";
  };

  const getBMIInterpretation = (bmi) => {
    if (!bmi) return "";

    if (bmi < 18.5) {
      return "Underweight";
    } else if (bmi >= 18.5 && bmi <= 24.9) {
      return "Normal weight";
    } else if (bmi >= 25 && bmi <= 29.9) {
      return "Overweight";
    } else if (bmi >= 30) {
      return "Obese";
    }
  };

  const handleOtherAllergies = (e) => {
    const { checked } = e.target;
    handleCheckboxChange(e);

    setState((prevState) => ({
      ...prevState,
      Resident: {
          ...prevState.Resident,
          otherAllergies: checked,
          otherAllergiesDescription: checked ? "" : prevState.Resident.otherAllergiesDescription,
      },
    }));

    if (!checked) {
      Common.withOutEventUpdateInputValueInState(
        state.Resident,
        thisReference,
        state,
        "",
        "otherAllergiesDescription"
      );
    }
  };

  const handleOtherMedicalHistory = (e) => {
    if (!e.target.checked) {
      Common.withOutEventUpdateInputValueInState(
        state.Resident,
        thisReference,
        state,
        "",
        "otherMedicalHistoryDescription"
      );
    }
    Common.updateInputValueInState(
      state.Resident,
      e,
      thisReference,
      state
    );
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    if (name === "noKnownAllergies" && checked) {
        setState((prevState) => ({
            ...prevState,
            Resident: {
                ...prevState.Resident,
                noKnownAllergies: true,
                allergicToSulpha: false,
                allergicToCollagen: false,
                allergicToIodine: false,
                allergicToHoney: false,
                allergicToPenicillin: false,
                allergicToLatex: false,
                allergicToAdhesive: false,
                otherAllergies: false,
                otherAllergiesDescription: "",
            },
        }));
    } else if (name !== "noKnownAllergies" && checked) {
        setState((prevState) => ({
            ...prevState,
            Resident: {
                ...prevState.Resident,
                [name]: true,
                noKnownAllergies: false,
            },
        }));
    } else {
        setState((prevState) => ({
            ...prevState,
            Resident: {
                ...prevState.Resident,
                [name]: checked,
            },
        }));
    }
  };
    
  return (
    <>
      {state.isLoading && (
        <>
          {/* Basic Info */}
          <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
            <legend className="text-primary fw-500">
              {intl.formatMessage({
                id: "NEWRESIDENT.BASIC_INFORMATION",
              })}
            </legend>
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="Status">
                    {intl.formatMessage({
                      id: "RESIDENT.STATUS",
                    })}
                  </label>
                  <select
                    className="form-control form-control-sm"
                    id="fkResidentStatusId"
                    name="fkResidentStatusId"
                    disabled={state.Resident.id == null ? true : false}
                    required
                    value={Common.replaceNullWithString(
                      state.Resident.fkResidentStatusId
                    )}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  >
                    <option></option>
                    {setOptionsUI(state.residentStatus)}
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="firstName">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.FIRST_NAME",
                    })}
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="form-control form-control-sm"
                    name="firstName"
                    defaultValue={state.Resident.firstName}
                    required
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="lastName">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.LAST_NAME",
                    })}
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="form-control form-control-sm"
                    name="lastName"
                    defaultValue={state.Resident.lastName}
                    required
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="gender">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.GENDER",
                    })}
                  </label>
                  <select
                    className="form-control form-control-sm"
                    id="gender"
                    name="gender"
                    value={Common.replaceNullWithString(state.Resident.gender)}
                    required
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  >
                    <option></option>
                    {setOptionsUI(state.gender)}
                  </select>
                </div>
              </div>

              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group">
                  <label className="form-label" htmlFor="doB">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.DOB",
                    })}
                  </label>
                  <input
                    type="date"
                    id="doB"
                    className="form-control form-control-sm"
                    name="doB"
                    min="1900-01-01"
                    max={Common.getInputStringDateUsingMoment(moment())}
                    required
                    defaultValue={
                      state.Resident.doB != null
                        ? Common.getInputStringDate(
                            new Date(state.Resident.doB)
                          )
                        : ""
                    }
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                <div className="form-group">
                  <label className="form-label" htmlFor="age-by-dob">
                    Age
                  </label>
                  <input
                    type="number"
                    id="age-by-dob"
                    className="form-control form-control-sm"
                    name="age-by-dob"
                    disabled
                    value={
                      state.Resident.doB
                        ? moment().diff(state.Resident.doB, "years")
                        : ""
                    }
                  />
                </div>
              </div>

              {/* pendente SSN -> NHS */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group">
                  <label className="form-label" htmlFor="ssn">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.NHS",
                    })}
                  </label>
                  <input
                    type="text"
                    id="ssn"
                    placeholder={placeholderSSN}
                    className="form-control form-control-sm"
                    name="ssn"
                    pattern={patternSSN}
                    value={formatSSN(state.Resident.ssn)}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>

              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="unit">
                    {intl.formatMessage({
                      id: "RESIDENTDETAILS.UNIT",
                    })}
                  </label>
                  <input
                    type="text"
                    id="unit"
                    className="form-control form-control-sm"
                    name="unit"
                    defaultValue={Common.replaceNullWithString(
                      state.Resident.unit
                    )}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group">
                  <label className="form-label" htmlFor="room">
                    {intl.formatMessage({
                      id: "RESIDENTDETAILS.ROOM",
                    })}
                  </label>
                  <input
                    type="text"
                    id="room"
                    className="form-control form-control-sm"
                    name="room"
                    defaultValue={Common.replaceNullWithString(
                      state.Resident.room
                    )}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group">
                  <label className="form-label" htmlFor="admissionDate">
                    {intl.formatMessage({
                      id: "RESIDENTDETAILS.CURRENT_ADMISSION_DATE",
                    })}
                  </label>
                  <input
                    type="date"
                    id="admissionDate"
                    className="form-control form-control-sm"
                    name="admissionDate"
                    min="1900-01-01"
                    max={Common.getInputStringDateUsingMoment(moment())}
                    required
                    defaultValue={
                      state.Resident.admissionDate != null
                        ? Common.getInputStringDate(
                            new Date(state.Resident.admissionDate)
                          )
                        : ""
                    }
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {/* Private Whiteley */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="isWhiteley"
                      name="isWhiteley"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.isWhiteley,
                        false
                      )}
                      onChange={(e) => handleWhiteleyChange(e)}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.PRIVATE_WHITELEY",
                    })}
                  </label>
                </div>
              </div>

              {/* Private Self-Referral */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="isSelfReferral"
                      name="isSelfReferral"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.isSelfReferral,
                        false
                      )}
                      onChange={(e) => handleSelfReferralChange(e)}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.PRIVATE_SELF_REFERRAL",
                    })}
                  </label>
                </div>
              </div>

              {/* NHS Referral */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="isNHSReferral"
                      name="isNHSReferral"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.isNHSReferral,
                        false
                      )}
                      onChange={(e) => handleNHSReferralChange(e)}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.NHS_REFERRAL",
                    })}
                  </label>
                </div>
              </div>
            </div>
          </fieldset>

          {/* Determinants of Health */}
          <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
            <legend className="text-primary fw-500">
              {intl.formatMessage({
                id: "NEWRESIDENT.DETERMINANTS_OF_HEALTH",
              })}
            </legend>
            <div className="row">
              <ResidentSmokingStatus
                resident={state.Resident}
                thisReference={thisReference}
                state={state}
              />

              <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                <div className="form-group">
                  <label className="form-label" htmlFor="height">
                    Height (m)
                  </label>
                  <input
                    type="number"
                    id="height"
                    className="form-control form-control-sm"
                    name="height"
                    min="0"
                    step="0.01"
                    defaultValue={state.Resident.height}
                    onChange={(e) => {
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      );
                      calculateBMI();
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                <div className="form-group">
                  <label className="form-label" htmlFor="weight">
                    Weight (kg)
                  </label>
                  <input
                    type="number"
                    id="weight"
                    className="form-control form-control-sm"
                    name="weight"
                    min="0"
                    step="0.01"
                    defaultValue={state.Resident.weight}
                    onChange={(e) => {
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      );
                      calculateBMI();
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                <div className="form-group">
                  <label className="form-label" htmlFor="bmi">
                    BMI
                  </label>
                  <input
                    type="number"
                    id="bmi"
                    className="form-control form-control-sm"
                    name="bmi"
                    disabled
                    value={calculateBMI()}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="bmi-interpretation">
                    BMI Interpretation
                  </label>
                  <input
                    type="text"
                    id="bmi-interpretation"
                    className="form-control form-control-sm"
                    name="bmi-interpretation"
                    disabled
                    value={getBMIInterpretation(calculateBMI())}
                  />
                </div>
              </div>

              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="regularConcomitantMedication"
                >
                  Regular Concomitant Medications
                </label>
                <textarea
                  id="regularConcomitantMedication"
                  className="form-control form-control-sm"
                  name="regularConcomitantMedication"
                  resize= "horizontal"
                  overflow= "auto"
                  defaultValue={state.Resident.regularConcomitantMedication}
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      state.Resident,
                      e,
                      thisReference,
                      state
                    )
                  }
                />
              </div>
            </div>
          </fieldset>

          {/* Allergies */}
          <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
            <legend className="text-primary fw-500">
              {intl.formatMessage({
                id: "NEWRESIDENT.ALLERGIES",
              })}
            </legend>

            <div className="row">
              {/* sulfa */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="allergicToSulpha"
                      name="allergicToSulpha"
                      checked={state.Resident.allergicToSulpha}
                      onChange={handleCheckboxChange}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_SULFA",
                    })}
                  </label>
                </div>
              </div>
              {/* collagen */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      name="allergicToCollagen"
                      id="allergicToCollagen"
                      checked={state.Resident.allergicToCollagen}
                      onChange={handleCheckboxChange}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_COLLAGEN",
                    })}
                  </label>
                </div>
              </div>
              {/* iodine */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      name="allergicToIodine"
                      id="allergicToIodine"
                      checked={state.Resident.allergicToIodine}
                      onChange={handleCheckboxChange}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_IODINE",
                    })}
                  </label>
                </div>
              </div>
              {/* honey */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="allergicToHoney"
                      name="allergicToHoney"
                      checked={state.Resident.allergicToHoney}
                      onChange={handleCheckboxChange}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_HONEY",
                    })}
                  </label>
                </div>
              </div>
              {/* penicillin */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      name="allergicToPenicillin"
                      id="allergicToPenicillin"
                      checked={state.Resident.allergicToPenicillin}
                      onChange={handleCheckboxChange}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_PENICILLIN",
                    })}
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {/* latex */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 d-flex align-items-center">
                <div className="form-group mt-0">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="allergicToLatex"
                      name="allergicToLatex"
                      checked={state.Resident.allergicToLatex}
                      onChange={handleCheckboxChange}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_LATEX",
                    })}
                  </label>
                </div>
              </div>
              {/* adhesive */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 d-flex align-items-center">
                <div className="form-group mt-0">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      name="allergicToAdhesive"
                      id="allergicToAdhesive"
                      checked={state.Resident.allergicToAdhesive}
                      onChange={handleCheckboxChange}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_ADHESIVE",
                    })}
                  </label>
                </div>
              </div>
              {/* other */}
              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 d-flex align-items-center">
                <div className="form-group mt-0">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      name="otherAllergies"
                      id="otherAllergies"
                      checked={state.Resident.otherAllergies}
                      onChange={handleOtherAllergies}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.OTHER_ALLERGIES",
                    })}
                  </label>
                </div>
                <div className="form-group ml-2 mb-0 mt-n2">
                  <input
                    type="text"
                    id="otherAllergiesDescription"
                    className="form-control form-control-sm"
                    name="otherAllergiesDescription"
                    defaultValue={state.Resident.otherAllergiesDescription}
                    disabled={!state.Resident.otherAllergies}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              {/* no known allergies */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 d-flex align-items-center">
                <div className="form-group mt-0">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="noKnownAllergies"
                      name="noKnownAllergies"
                      checked={state.Resident.noKnownAllergies}
                      onChange={handleCheckboxChange}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.NO_KNOWN_ALLERGIES",
                    })}
                  </label>
                </div>
              </div>
            </div>
          </fieldset>

          {/* Medical History */}
          <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
            <legend className="text-primary fw-500">
              {intl.formatMessage({
                id: "NEWRESIDENT.MEDICAL_HISTORY",
              })}
            </legend>
            <div className="row">
              {/* Hypertension */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="hadHypertension"
                      name="hadHypertension"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.hadHypertension,
                        false
                      )}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.HYPERTENSION",
                    })}
                  </label>
                </div>
              </div>
              {/* Hypercholesterolemia */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadHypercholesterolemia"
                      id="hadHypercholesterolemia"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadHypercholesterolemia,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.HYPERCHOLESTEROLEMIA",
                    })}
                  </label>
                </div>
              </div>
              {/* Hyperlipidemia */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadHyperlipidemia"
                      id="hadHyperlipidemia"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadHyperlipidemia,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.HYPERLIPIDEMIA",
                    })}
                  </label>
                </div>
              </div>
              {/* Cardiovascular Disease */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="hadCardiovascularDisease"
                      name="hadCardiovascularDisease"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.hadCardiovascularDisease,
                        false
                      )}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.CARDIOVASCULAR_DISEASE",
                    })}
                  </label>
                </div>
              </div>
              {/* Diabetes Type 1 on insulin */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadDiabetes1Insulin"
                      id="hadDiabetes1Insulin"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadDiabetes1Insulin,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.DIABETES_TYPE1_ON_INSULIN",
                    })}
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Diabetes Type 2 on insulin */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadDiabetes2insulin"
                      id="hadDiabetes2insulin"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadDiabetes2insulin,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.DIABETES_TYPE2_ON_INSULIN",
                    })}
                  </label>
                </div>
              </div>
              {/* Diabetes Type 2 on diet control */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadDiabetes2Diet"
                      id="hadDiabetes2Diet"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadDiabetes2Diet,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.DIABETES_TYPE2_ON_DIET_CONTROL",
                    })}
                  </label>
                </div>
              </div>
              {/* Osteoarthritis */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadOsteoarthritis"
                      id="hadOsteoarthritis"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadOsteoarthritis,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.OSTEOARTHRITIS",
                    })}
                  </label>
                </div>
              </div>
              {/* Benign Prostate Hyperplasia */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadProstateHyperplasia"
                      id="hadProstateHyperplasia"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadProstateHyperplasia,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.BENIGN_PROSTATE_HYPERPLASIA",
                    })}
                  </label>
                </div>
              </div>
              {/* Depression/Anxiety */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadDepressionAnxiety"
                      id="hadDepressionAnxiety"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadDepressionAnxiety,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.DEPRESSION_ANXIETY",
                    })}
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Gastroesophageal Reflux Disease */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadGERD"
                      id="hadGERD"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadGERD,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.GASTROESOPHAGEAL_REFLUX_DISEASE",
                    })}
                  </label>
                </div>
              </div>
              {/* Osteoporosis */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadOsteoparosis"
                      id="hadOsteoparosis"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadOsteoparosis,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.OSTEOPOROSIS",
                    })}
                  </label>
                </div>
              </div>
              {/* Obesity */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadObesity"
                      id="hadObesity"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadObesity,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.OBESITY",
                    })}
                  </label>
                </div>
              </div>
              {/* Asthma */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadAsthma"
                      id="hadAsthma"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadAsthma,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ASTHMA",
                    })}
                  </label>
                </div>
              </div>
              {/* COPD */}
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="hadCOPD"
                      id="hadCOPD"
                      checked={Common.replaceNullWithString(
                        state.Resident.hadCOPD,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.COPD",
                    })}
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {/* other */}
              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 d-flex align-items-center">
                <div className="form-group mt-0">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="otherMedicalHistory"
                      id="otherMedicalHistory"
                      checked={Common.replaceNullWithString(
                        state.Resident.otherMedicalHistory,
                        false
                      )}
                      className="m-1"
                      onChange={(e) => handleOtherMedicalHistory(e)}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.OTHER_MEDICAL_HISTORY",
                    })}
                  </label>
                </div>
                <div className="form-group ml-2 mb-0 mt-n2">
                  <input
                    type="text"
                    id="otherMedicalHistoryDescription"
                    className="form-control form-control-sm"
                    name="otherMedicalHistoryDescription"
                    defaultValue={state.Resident.otherMedicalHistoryDescription}
                    disabled={!state.Resident.otherMedicalHistory}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </fieldset>

          {/* Emergency Contact */}
          <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
            <legend className="text-primary fw-500">
              {intl.formatMessage({
                id: "NEWRESIDENT.EMERGENCY_CONTACTS",
              })}
            </legend>
            <div className="row">
              {/* Name */}
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="name">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.EMERGENCY_CONTACTS_NAME",
                    })}
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control form-control-sm"
                    name="name"
                    defaultValue={state.Resident.residentEmergencyContact.name}
                    onChange={(e) => {
                      Common.updateInputValueInState(
                        state.Resident.residentEmergencyContact,
                        e,
                        thisReference,
                        state
                      );
                    }}
                  />
                </div>
              </div>
              {/* Relationship */}
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="relationship">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.EMERGENCY_CONTACTS_RELATIONSHIP",
                    })}
                  </label>
                  <input
                    type="text"
                    id="relationship"
                    className="form-control form-control-sm"
                    name="relationship"
                    defaultValue={state.Resident.residentEmergencyContact.relationship}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident.residentEmergencyContact,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              {/* Mobile Number */}
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="phoneNumber">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.EMERGENCY_CONTACTS_NUMBER",
                    })}
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formatPhoneNumber(Common.replaceNullWithString(
                      state.Resident.residentEmergencyContact.phoneNumber
                    ))}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident.residentEmergencyContact,
                        e,
                        thisReference,
                        state
                      )
                    }
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
          </fieldset>

          {/* Address */}
          <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
            <legend className="text-primary fw-500">
              {intl.formatMessage({
                id: "NEWRESIDENT.ADDRESS_INFORMATION",
              })}
            </legend>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="State">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ADDRESS_LINE_1",
                    })}
                  </label>
                  <input
                    id="addressLine1"
                    name="addressLine1"
                    value={Common.replaceNullWithString(
                      state.Resident.raddress.addressLine1
                    )}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident.raddress,
                        e,
                        thisReference,
                        state
                      )
                    }
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="addressLine2">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ADDRESS_LINE_2",
                    })}
                  </label>
                  <input
                    id="addressLine2"
                    name="addressLine2"
                    value={Common.replaceNullWithString(
                      state.Resident.raddress.addressLine2
                    )}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident.raddress,
                        e,
                        thisReference,
                        state
                      )
                    }
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              {/* City */}
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="city">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.CITY",
                    })}
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={Common.replaceNullWithString(
                      state.Resident.raddress.city
                    )}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident.raddress,
                        e,
                        thisReference,
                        state
                      )
                    }
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              {/* County */}
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="State">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.COUNTRY",
                    })}
                  </label>
                  <select
                    className="form-control form-control-sm"
                    name="countryId"
                    value={Common.replaceNullWithString(
                      state.Resident.raddress.countryId
                    )}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident.raddress,
                        e,
                        thisReference,
                        state
                      )
                    }
                  >
                    <option></option>
                    {setOptionsUI(state.countryList)}
                  </select>
                </div>
              </div>
              {/* Postal Code */}
              <div className="col-sm-3 col-md-4 col-lg-4 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="zip">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.POSTAL_CODE",
                    })}
                  </label>
                  <input
                    type="text"
                    id="zip"
                    name="zip"
                    maxlength="8"
                    value={formatZipCode(
                        Common.replaceNullWithString(
                            state.Resident.raddress.zip
                        ), "UK"
                    )}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident.raddress,
                        e,
                        thisReference,
                        state
                      )
                    }
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              {/* Patient Contact Number */}
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone1">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.PATIENT_CONTACT_NUMBER",
                    })}
                  </label>
                  <input
                    type="text"
                    id="phone1"
                    name="phone1"
                    value={formatPhoneNumber(Common.replaceNullWithString(
                      state.Resident.raddress.phone1
                    ))}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident.raddress,
                        e,
                        thisReference,
                        state
                      )
                    }
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
          </fieldset>
        </>
      )}
      {/* Insurance */}
      <fieldset
        className={
          "p-2 mb-2 bg-light border border-dark rounded " +
          (state.isLoading ? "" : "d-none")
        }
      >
        <legend className="text-primary fw-500">
          {intl.formatMessage({
            id: "NEWRESIDENT.INSURANCE_INFORMATION",
          })}
        </legend>

        <InsuranceWrapper intl={intl} resident={state.Resident} />
      </fieldset>

      {state.isLoading && (
        <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
          <legend className="text-primary fw-500">
            {intl.formatMessage({
              id: "NEWRESIDENT.GP_INFO",
            })}
          </legend>
          <div className="row">
            {/* GP Name */}
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <div className="form-group">
                <label className="form-label" htmlFor="signingContactPerson">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.GP_NAME",
                  })}
                </label>
                <input
                  type="text"
                  id="signingContactPerson"
                  className="form-control form-control-sm"
                  name="signingContactPerson"
                  defaultValue={state.Resident.signingContactPerson}
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      state.Resident,
                      e,
                      thisReference,
                      state
                    )
                  }
                />
              </div>
            </div>
            {/* GP Email */}
            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <div className="form-group">
                <label className="form-label" htmlFor="signingPhysicianEmail">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.GP_EMAIL_ADDRESS",
                  })}
                </label>
                <input
                  type="text"
                  id="signingPhysicianEmail"
                  name="signingPhysicianEmail"
                  defaultValue={Common.replaceNullWithString(
                    state.Resident.signingPhysicianEmail
                  )}
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      state.Resident,
                      e,
                      thisReference,
                      state
                    )
                  }
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            {/* GP Address */}
            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <div className="form-group">
                <label className="form-label" htmlFor="State">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.ADDRESS_LINE_1",
                  })}
                </label>
                <input
                  id="addressLine1"
                  name="addressLine1"
                  value={Common.replaceNullWithString(
                    state.Resident.gpAddress.addressLine1
                  )}
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      state.Resident.gpAddress,
                      e,
                      thisReference,
                      state
                    )
                  }
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <div className="form-group">
                <label className="form-label" htmlFor="addressLine2">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.ADDRESS_LINE_2",
                  })}
                </label>
                <input
                  id="addressLine2"
                  name="addressLine2"
                  value={Common.replaceNullWithString(
                    state.Resident.gpAddress.addressLine2
                  )}
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      state.Resident.gpAddress,
                      e,
                      thisReference,
                      state
                    )
                  }
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            {/* City */}
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <div className="form-group">
                <label className="form-label" htmlFor="city">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.CITY",
                  })}
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={Common.replaceNullWithString(
                    state.Resident.gpAddress.city
                  )}
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      state.Resident.gpAddress,
                      e,
                      thisReference,
                      state
                    )
                  }
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            {/* County */}
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <div className="form-group">
                <label className="form-label" htmlFor="State">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.COUNTY",
                  })}
                </label>
                <select
                  className="form-control form-control-sm"
                  name="stateId"
                  value={Common.replaceNullWithString(
                    state.Resident.gpAddress.stateId
                  )}
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      state.Resident.gpAddress,
                      e,
                      thisReference,
                      state
                    )
                  }
                >
                  <option></option>
                  {setOptionsUI(state.states)}
                </select>
              </div>
            </div>
            {/* Postal Code */}
            <div className="col-sm-3 col-md-4 col-lg-4 col-xl-3">
              <div className="form-group">
                <label className="form-label" htmlFor="zip">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.POSTAL_CODE",
                  })}
                </label>
                <input
                  type="text"
                  id="zip"
                  name="zip"
                  maxlength="8"
                  value={formatZipCode(
                    Common.replaceNullWithString(
                      state.Resident.gpAddress.zip
                    ), "UK"
                  )}
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      state.Resident.gpAddress,
                      e,
                      thisReference,
                      state
                    )
                  }
                  className="form-control form-control-sm"
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <div className="form-group">
                <label className="form-label" htmlFor="FirstName">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.GP_TELEPHONE",
                  })}
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="signingPhysicianPhone"
                  value={formatPhoneNumber(
                    Common.replaceNullWithString(
                      state.Resident.signingPhysicianPhone
                    )
                  )}
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      state.Resident,
                      e,
                      thisReference,
                      state
                    )
                  }
                  className="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </>
  );
}

export default injectIntl(CreateResidentFormInputUK, { forwardRef: true });
