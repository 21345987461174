import { Switch, Typography } from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import {
  Accordion,
  AccordionCollapse,
  AccordionToggle, Button, Dropdown, DropdownButton
} from "react-bootstrap";
import CheckPermission from "../../General/CheckPermission";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import { showDecisionAlert, showWarningAlert } from "../../../index";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import ResidentWounds from "../../pages/resident/residentWounds";
import PopupModal from "../modal/popupmodal";
import Newresident from "../modal/residentsmodals/newresident";
import ResidentDetails from "../resident/residentdetails";
import ResidentStatus from "../resident/residentStatus";
import ICDReport from "../modal/reports/icdreport";
import AOB from "../resident/aob";
import PatientOrders from "../resident/patientorders";
import ResidentNotes from "../resident/residentnotes";
import WoundLocation from "../resident/woundlocation";
import WoundNote from "../resident/woundnote";
import moment from 'moment';
import SelectableContext from "react-bootstrap/SelectableContext";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FacilityResidentHistory from '../resident/residentHistory';
import SkipWoundMsg from '../resident/skipWoundMsg';
import NewWound from '../resident/newwound';
import { useSelector } from 'react-redux';
import RecapEmail from "../recapEmail/recapEmail";
import InsuranceWrapper from "@/shared/components/insurance";

import { injectIntl } from "react-intl";

var UpsertNewresident, insuranceModal, residentHistoryModal,
  residentStatusModal, addStatusRef, residentInfoModal, AddResidentModal, patientOrdersModal,
   ResidentNotesModal, showSkipWoundMsgModal, WoundNoteModal,editWoundmodal = null;
   var _sampleWoundObj = {
    fkResidentId: 0,
    residentName: '',
    facilityName: '',
    roomUnit: '',
    // evaluatedBy: Common.replaceNullWithString(user.firstName) + " " + Common.replaceNullWithString(user.lastName),
    physicanName: '',
    btPatientBrightreeID: null,
    fkLastWoundDetailId: null,
    isWoundInfoChange: false,
    changeReason: '',
    fkSalesOrderId: 0,
  
    wound:
    {
        id: 0,
        woundDetailId: 0,
        // evaluationDate: '',
        // dateFirstObserved: '',
        // fkAcquisitionId: null,
        fkWoundLocationId: null,
        woundLocation: "",
        fkWoundTypeId: null,
        woundDescription: '',
        fkWoundThicknessId: null,
        //fkWoundSevereTissueTypeId: null,
        fkWoundLateralityId: null,
        woundLength: '',
        woundWidth: '',
        woundDepth: '',
        isUtd:false,
        tunneling: false,
        tunnelingDepth: null,
        tunnelingClockPosition: '',
        undermining: false,
        underminingDepth: null,
        underminingClockPosition: null,
        toUnderminingClockPosition:null,
        icdcode: "",
        fkExudateAmountId: null,
        fkExudateTypeId: null,
        exudateColor: '',
        fkDebridementTypeId: null,
        debridementDate: null,
        surgicalDate: null,
        odor: false,
        Pain: false,
        painMedicationOrdered: false,
        sloughPercentage: null,
        necroticPercentage: null,
        //FkWoundCleanseId :'',
        cleanseDescription: null,
        fkDressingChangeFrequencyId: 8,
        //fkPrimaryDressingId :null,
        //FkAdditionalPrimaryDressingId :'',
        otherAdditionalPrimaryDressing: '',
        //fkSecondaryDressingId :null,
        //FkAdditionalSecondaryDressingId :'',
        otherAdditionalSecondaryDressing: '',
        treatment: '',
        comments: '',
        thicknessIdForBackStagging: null,
        orientationLeftRight: null,
        orientationUpperLower: null,
        orientationInnerOuter: null,
        orientationAnteriorPosterior: null,
        orientationMedialLateral: null,
        orientationDorsalDistalProximal: null,
        isDocumentation: false,
        isPartailOrder: false,
        // FkWoundcareProductTypeId :'',
  
    }
  };
export function AllFacilityResident(props) {
  //#region Resident modal
  const [showModalResident, setShowResident] = useState(false);
  const [isResidentInfoEdit, setisResidentInfoEdit] = useState(false);
  const [residentListState, setResidentListState] = useState([]);
  const [residentListUI, setResidentListUI] = useState([]);
  const [Resident, setResident] = useState("");
  const [ResidentID, setResidentID] = useState("");
  const [ResidentName, setResidentName] = useState("");
  const FacilityId = props.facility.id;
  const [facilityName, setFacilityName] = useState('');
  const [chain, setChainName] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const { user } = useSelector((state) => state.auth);
  const handleCloseResident = () => setShowResident(false);
  const handleShowResident = () => setShowResident(true);
  //#endregion
    const [addWoundComponent, setAddWoundComponent] = useState(null);
    const [showRecapEmail, setShowRecapEmail] = useState(false);

    const [residentLoading, setResidentLoading] = useState(false);
  const displatWarningAlert = () => showWarningAlert("Coming soon!!!", "Alert");
  const displayDecisionAlert = (title, message) =>
    showDecisionAlert(
      function () { },
      function () { },
      title == null ? "Are you sure?" : title,
      message == null ? "You want to deactivate this Resident." : message
    );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    getAllResident();
    //setResidentListTemp();
    //Common.LoadDataTable("facilityList")
  }, []);

  //#region Set Resident List
  const getAllResident = useCallback(() => {
    setIsLoading(true);
    if (props.isPartail) {
      Api.getRequestQry(
        Api.URI_GetAllBillingQueueResidentByFacilityID,
        "?facilityID=" + FacilityId + "&isPartailOrder=" + 1 + "&partailOrderDate=" + props.facility.cycleDate +
        "&processStatusId=" + props.facility.fkProcessStatusId
      )
        .then((res) => {
          if (res.data.statusCode == 200) {
            if (res.data.result != null) {
              if (res.data.result.length > 0) {
                setResidentList(res.data.result);
              }
            }
          }

        })
        .catch((ex) => { })
        .then(() => {
          setIsLoading(false);
          Common.LoadDataTable("residentsList");
        });

    }
    else {
      Api.getRequestQry(
        Api.URI_GetAllResidentByFacilityID, "?facilityID=" + FacilityId
      )
        .then((res) => {
          if (res.data.statusCode == 200) {
            if (res.data.result != null) {
              if (res.data.result.length > 0) {
                setResidentList(res.data.result);
              }
            }
          }

        })
        .catch((ex) => { })
        .then(() => {
          setIsLoading(false);
          Common.LoadDataTable("residentsList");
        });
    }
  });


  const handleShowAddResident = (obj) => {
    setResident(obj);
    AddResidentModal.showModal();
  };
  const handleResidentStatus = async (resident) => {
    await setResident(resident)
    residentStatusModal.showModal();

  }
  const handleResidentHistory = async (residentId) => {
    await setResidentID(residentId)
    residentHistoryModal.showModal();
  }
  const setResidentList = (list, isShowProduct) => {
    var rows = [];

    //const statusBadgeType = ["badge-warning", "badge-success"];
    //const status = ["Pending", "Processed"];



    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const name = obj.lastName + ", " + obj.firstName;
      const residentID = obj.id;
      const dob = moment(obj.doB).format('MM/DD/YYYY');
      var insurance = "No insurance on file";
      // var insurance = this.intl.formatMessage({id:"FACILITY_RESIDENT.RESIDENT.NO_INSURANCE"})

      if (obj.residentInsuranceList != null) {
        insurance = "";
        for (var d = 0; d < obj.residentInsuranceList.length; d++) {
          let objIns = JSON.parse(JSON.stringify(obj.residentInsuranceList[d]));
          insurance = insurance + (insurance != "" ? ", " : "") + " " + (objIns.isAddEdit ? "*" : "") + "=>" + (objIns.fkInsuranceLevelId == 1 ? "Primary"
            : objIns.fkInsuranceLevelId == 2 ? "Secondary"
              : objIns.fkInsuranceLevelId == 3 ? "Tertiary"
                : "N/A") + " " + objIns.insuranceName
        }
      }
      // else{
      //   insurance="Not Insured"
      // }
      // var obj = usersDatajson[Math.floor(Math.random() * usersDatajson.length)];
      // var dob = new Date(obj.birthdate);
      // let randomCount = Math.floor(Math.random() * status.length);
      // var randStatus = resStatus[residentID];
      var statusBadgeType =
        obj.isProcess == true ? "badge-success" : "badge-warning";

      rows.push(
        <Accordion key={i.toString()} defaultActiveKey={!obj.isProcess ? i.toString() + obj.id : ""}>
          <Card>
            <CardHeader>
              <div
                className={`w-100 d-flex border-bottom m-0 ${obj.isProcess
                  ? "bg-light-success border-success"
                  : "border-light-dark"
                  }`}
              >
                <div className="customStyleAccorion" style={{ display: "grid" }}>
                  <AccordionToggle
                    as={Button}
                    variant="link"
                    // className="h5 mb-0"
                    eventKey={i.toString() + obj.id}
                  >
                    <div title={obj.fkPrimaryPhysicianId == null ? "Primary physician is not assigned" : ""}
                      className={`h5 mb-0 text-dark ${obj.fkPrimaryPhysicianId == null ? "bg-light-danger border-danger" : ""}`}> {name}</div>

                  </AccordionToggle>

                  {obj.fkPrimaryPhysicianId == null ? <small>Primary physician is not assigned<br /></small> : ""}
                  {/* <small>  <label className="form-label font-weight-bold mr-2" htmlFor="fkDebridementTypeId">DOB:</label>{obj.doB!=null?dob:"N/A" }</small> <br /> */}
                  <small> {insurance}</small>
                </div>
                {/*{user.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_IntakeProcessor)
                  == undefined && */}
                  <span className={`badge my-auto mx-4 ml-auto `}>

                    <span>
                      <SelectableContext.Provider value={false}>
                        <DropdownButton
                          className="d-inline"
                          drop={"down"}
                          size="xs"
                          variant="primary"
                          title={'Actions'}
                          id={`dropdown-button-drop-actions`}
                          key={'down'}>
                          <Dropdown.Item eventKey="1" onClick={() => { setResident(obj); setisResidentInfoEdit(false); setResidentID(obj.id); residentInfoModal.showModal(); }}>Resident Info</Dropdown.Item>
                          <Dropdown.Item eventKey="8" onClick={() => handleResidentStatus(obj)}>Set Resident Status</Dropdown.Item>
                          <Dropdown.Item eventKey="81" onClick={() => handleResidentHistory(obj.id)}>View History</Dropdown.Item>

                          <Dropdown.Item
                            eventKey="8"
                            onClick={() => {
                              setResidentID(obj.id);
                              setResidentName(name);
                              ResidentNotesModal.showModal();
                            }}
                          >
                            Resident Notes
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="2" onClick={() => {  setResidentID(obj.id); showSkipWoundMsgModal.showModal(); }} >View Skip Wound Messages</Dropdown.Item>

                          {/* <Dropdown.Item eventKey="9" className="text-primary" onClick={() => { AOBModal.showModal(); }}>AoB Manager</Dropdown.Item> */}
                        </DropdownButton>
                      </SelectableContext.Provider>
                    </span>
                  </span>
                {/*}*/}
                <>

                </>

              </div>
            </CardHeader>
            <AccordionCollapse eventKey={i.toString() + obj.id}>
              <CardBody>
                <fieldset >
                  <ResidentWounds isPartail={props.isPartail}
                    facility={props.facility} 
                    // isProcessor={user.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_IntakeProcessor)
                    //  == undefined?false:true}
                    refresh={() => { getAllResident() }} Resident={obj} />
                </fieldset>
              </CardBody>
            </AccordionCollapse>
          </Card>
        </Accordion>
      );
    }

    //return rows
    setResidentListUI([]);
    setResidentListUI(rows);
  };

  const handleNewWound =(newWoundDetail)=>{
    debugger
    setResident(newWoundDetail)
    Common.showConfirmation("Would you like to enter a wound.",
    "", handleNewWoundModal, null, newWoundDetail,"Yes",false,null,"No")
  }
  const handleNewWoundModal = (obj) => {
    let residenObj = JSON.parse(JSON.stringify(_sampleWoundObj));
    residenObj.fkResidentId = obj.id;
    residenObj.residentName = Common.replaceNullWithString(obj.firstName) + " " + Common.replaceNullWithString(obj.lastName);
    residenObj.facilityName = Common.replaceNullWithString(props.facility.facilityName);
    residenObj.roomUnit = Common.replaceNullWithString(obj.room) + "/" + Common.replaceNullWithString(obj.unit);
    residenObj.physicanName = Common.replaceNullWithString(obj.primaryPhysicianName);
    // residenObj.evaluatedBy= Common.replaceNullWithString(this.props.user.firstName) + " " + Common.replaceNullWithString(this.props.user.lastName);
    residenObj.btPatientBrightreeID = obj.btPatientBrightreeID;
    if(obj.fkResidentStatusId==Common.ResidentStatusID.open){
      setAddWoundComponent(<NewWound refresh={()=>getAllResident()}  isEdit={false} 
      isReorder={false} 
      showProduct={false}
      isReadOnly={false}
      user={user}
      displayProduct={true}
      queue={props.queue || Common.ProcessStatus.workQueueID}
      resident={residenObj} close={() => handleCloseWoundmodalPopup()} />,
      // ResidentFirstName:obj.firstName,
      // ResidentLastName:obj.lastName
  
     )
  
    editWoundmodal.showModal()
  }else{
    Common.showSuccessAlert("Resident is not open please update resident status to add/update new wound", "warning", "Warning!", 3000);
  
  }
  }
  const handleCloseWoundmodalPopup=()=>{
    debugger
    editWoundmodal.hideModal()
  getAllResident()
    
  }
  // for (var i = 0; i < 3; i++) {
  //     var obj = usersDatajson[Math.floor(Math.random() * usersDatajson.length)];
  //     var dob = new Date(obj.birthdate);
  //     let randomCount = Math.floor(Math.random() * status.length);
  //     var randStatus = status[randomCount];
  //     var randStatusBadgeType = statusBadgeType[randomCount];
  //     //${randStatus == "Processed" ? "bg-light-success" :""}
  //     rows.push(
  //         <Accordion>
  //             <Card>
  //                 <CardHeader>
  //                     <div className={`w-100 d-flex border-bottom  ${randStatus == "Processed" ? "bg-light-success border-success" :"border-light-dark"}`}>
  //                         <AccordionToggle as={Button} variant="link" className="h5 mb-0" eventKey={i.toString()}>
  //                             {obj.first_name + " " + obj.last_name}
  //                         </AccordionToggle>
  //                         <span className={`badge my-auto mx-4 ml-auto ${randStatusBadgeType}`}>{randStatus}</span>
  //                     </div>
  //                 </CardHeader>
  //                 <AccordionCollapse eventKey={i.toString()}>
  //                     <CardBody><WorkQueueWounds /></CardBody>
  //                 </AccordionCollapse>
  //             </Card>
  //         </Accordion >
  //     );
  // }

  const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(
    ShowAdvancedSearch ? true : false
  );
  const showAdvanceSearchSection = () => {
    showAdvanceSearchSectionState(!ShowAdvancedSearch);
  };

  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
        {user.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_IntakeProcessor)
          == undefined &&
          <CheckPermission
            permission={UserActions.Create}
            module={ModulesName.Module_Residents}
            yes={() => (
              <Button
                variant="primary"
                size="sm"
                className="mr-5"
                onClick={() => {
                  handleShowAddResident(null);
                }}
              >
                New Resident
              </Button>
            )}
            no={() => (
              <Button variant="primary" size="sm" className="mr-5" disabled="true">
                New Resident
              </Button>
            )}
          />
        }
              <button
                  type="button"
                  className="mr-5 btn btn-primary btn-sm"
                  onClick={() => setShowRecapEmail(true)}
              >
                  Recap Email
              </button>
        <button
          type="button"
          className="mr-5 btn btn-dark btn-sm"
          onClick={() => props.backPress()}
        >
          Back To Facility List
        </button>

      </div>
    </div>
  );

  //initialize datatable
  //   $(document).ready(function() {
  //     if ($.fn.dataTable.isDataTable("#residentsList")) {
  //       $("#residentsList").DataTable = $("#residentsList").DataTable();
  //     } else {
  //       $("#residentsList").DataTable({
  //         responsive: true,
  //         pageLength: 25,
  //         lengthMenu: [
  //           [25, 50, 100, -1],
  //           [25, 50, 100, "All"],
  //         ],
  //       });
  //     }
  //   });

  return (
    <>

          {showRecapEmail &&
              <Animated
                  animationIn="fadeIn"
                  animationInDuration={1000}
                  animationOut="fadeOut"
                  isVisible={showRecapEmail}
              >
                  <RecapEmail backPress={() => setShowRecapEmail(false)} facility={props.facility} />
              </Animated>}
          {!showRecapEmail &&
      <Card className="example example-compact">
        <CardHeader>
          <div className="card-title">
            <span>
              <b className="text-success">Facility: {props.facility.facilityName}</b> ,{" "} <br />
              <b>Ownership/Headquarters:</b> {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
              <b>Purchasing Group-Chain:</b>{" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
              <b>
                <FormControlLabel
                  label={<Typography >
                    <h4 className="mt-4"> Part B:</h4>
                  </Typography>
                  }
                  labelPlacement='start'
                  control={
                    <Checkbox checked={props.facility.medicarePartBbyGentell} value="checkedA" />
                  }
                />
                <FormControlLabel
                  label={<Typography >
                    <h4 className="mt-4"> Equal Care:</h4>
                  </Typography>
                  }
                  labelPlacement='start'
                  control={
                    <Checkbox checked={props.facility.isEcp} value="checkedB" />
                  }
                />
                <FormControlLabel
                  label={<Typography >
                    <h4 className="mt-4">Outs:</h4>
                  </Typography>
                  }
                  labelPlacement='start'
                  control={
                    <Checkbox checked={props.facility.outProgram} value="checkedC" />
                  }
                />
              </b>
              <h3 className="card-label mt-3">Resident List</h3>
            </span>
          </div>
          {toolbar}
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <div className="row d-flex justify-content-center">
              <span className="mt-5 spinner spinner-primary"></span>
            </div>
          ) : (
            residentListUI
          )}
        </CardBody>
              </Card>}
      <PopupModal
        title="Insurances"
        showButton={false}
        ref={(ref) => {
          insuranceModal = ref;
        }}
      >
        {" "}
        <InsuranceWrapper residentID={ResidentID} />{" "}
      </PopupModal>
      <PopupModal
        title="Resident Info"
        showButton={false}
        dialogClassName="modal-90w"

        ref={(ref) => {
          residentInfoModal = ref;
        }}
      >
        {" "}
        {
          isResidentInfoEdit && <Animated
            animationIn="fadeIn"
            animationInDuration={800}
            animationOut="fadeOut"
            // isVisible={isResidentInfoEdit}
            onClose={(ref) => {
              Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning',
                () => { ref.setState({ show: false }); }, () => { }, null, "Exit Without Saving");
            }}
          >
            <div className="row d-flex align-items-center justify-content-end">
              <Button className='mb-4 mr-4 ' onClick={() => { setisResidentInfoEdit(!isResidentInfoEdit); }}>Go Back To Detail</Button>
            </div>
            <Newresident
              ref={(ref) => {
                UpsertNewresident = ref;
              }}
              FacilityId={FacilityId}
              Resident={Resident}
              facility={props.facility}
              setResidentLoading={setResidentLoading}
              handleNewWound={handleNewWound}
              close={() => {
                getAllResident(false);
                setResidentLoading(false);
                residentInfoModal.hideModal(true);
              }}
            />
            <div className="row d-flex align-items-center justify-content-end">
            <Button className='mb-4 btn-success ml-2' disabled={residentLoading} onClick={() => { UpsertNewresident.handleSubmit() }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                    {residentLoading && <span className="mr-8 spinner spinner-white"></span>}
        <span>{'Save Changes'}</span>
              </span>
              </Button>
            </div>
          </Animated>

        }

        {
          !isResidentInfoEdit && <Animated
            animationIn="fadeIn"
            animationInDuration={800}
            animationOut="fadeOut"
          // isVisible={isResidentInfoEdit}
          >       <div className="row d-flex align-items-center justify-content-end">
              <Button className='mb-4 mr-4' onClick={() => { setisResidentInfoEdit(!isResidentInfoEdit); }}>Edit</Button>
            </div>
            <ResidentDetails resident={Resident} getAllResident={() => { getAllResident(false) }} />{" "}
          </Animated>
        }
        {" "}
      </PopupModal>


      {/** Patient Orders Modal */}
      <PopupModal
        title="Resident Orders"
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          patientOrdersModal = ref;
        }}
      >
        {" "}
        <PatientOrders />{" "}
      </PopupModal>

      {/** Wound Detail Modal */}


      {/** Wound Location Modal */}


      {/** Wound Note Modal */}
      <PopupModal
        title="Add Wound Note"
        size="lg"
        showButton={true}
        ref={(ref) => {
          WoundNoteModal = ref;
        }}
      >
        {" "}
        <WoundNote />{" "}
      </PopupModal>

      {/** Wound Note Modal */}
 

      {/* Resdinet Notes Modal */}
      {/** Wound Detail Modal */}
      <PopupModal
        title={<div className="card-title">
          <span>
            <h3 className="card-label">Resident Notes</h3>
            <small className="">
              <b>Resident:</b>{" "} {ResidentName},{" "}
              <b>Facility:</b> {facilityName},{" "}
              <b>Chain:</b>{" "} {chain},{" "}
              <b>Group:</b>{" "} {organizationName},{" "}

            </small>
          </span>
        </div>}
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          ResidentNotesModal = ref;
        }}
      >
        {" "}
        <ResidentNotes
          //   userID={user.userID}
          FacilityId={FacilityId}
          ResidentID={ResidentID}
        />{" "}
      </PopupModal>

      {/* Add/edit resident modal */}

      <PopupModal
        title={<div className="card-title">
          <span>
            <h3 className="card-label">Add Resident</h3>
            <small className="">
              <b>Facility:</b> {props.facility.facilityName},{" "}
              <b>Ownership/Headquarters:</b>  {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
              <b>Operators/Purchasing Group:</b>{" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
            </small>
          </span>
        </div>}
        dialogClassName="modal-97w"
        showButton={true}
        ref={(ref) => {
          AddResidentModal = ref;
        }}
        buttonDisabled={residentLoading}
        buttonPress={() => UpsertNewresident.handleSubmit()}
        module={ModulesName.Module_Residents}
        action={UserActions.Create}
        onClose={(ref) => {
          Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning',
            () => { ref.setState({ show: false }); }, () => { }, null, "Exit Without Saving");
        }}
      >
        <Newresident
          ref={(ref) => {
            UpsertNewresident = ref;
          }}
          user={user}
          handleNewWound={handleNewWound} 
          FacilityId={FacilityId}
          Resident={Resident}
          facility={props.facility}
          setResidentLoading={setResidentLoading}
          close={() => {
            getAllResident();
            AddResidentModal.hideModal(true);
            setResidentLoading(false);
          }}
        />
      </PopupModal>
      <PopupModal
        title={<div className="card-title">
          <span>
            <h3 className="card-label">Resident Status</h3>
            <small className="">
              <b>Facility:</b> {props.facility.facilityName},{" "}
              <b>Ownership/Headquarters:</b> {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
              <b>Purchasing Group-Chain:</b>{" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
            </small>
          </span>
        </div>}
        // dialogClassName="modal-90w"

        ref={(ref) => {
          residentStatusModal = ref;
        }}
        showButton={true}
        buttonPress={() => addStatusRef.onSubmit()}
        module={ModulesName.Module_Residents}
        action={UserActions.Update}
      >
        {" "}
        <ResidentStatus
          ref={(ref) => {
            addStatusRef = ref;
          }}
          resident={Resident}
          close={() => {
            getAllResident();
            residentStatusModal.hideModal();
            //  props.afterAuthorize();
            //  setOrderApproved(true)
          }}
        />{" "}
      </PopupModal>

      <PopupModal
        // title="Sales Order Message"
        title={<div className="card-title">
          <span>
            <h3 className="card-label">Resident History</h3>
            <small className="">
              <b>Facility:</b> {props.facility.facilityName},{" "}
              <b>Ownership/Headquarters:</b> {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
              <b>Purchasing Group-Chain:</b>{" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
            </small>
          </span>
        </div>}
        showButton={false}
        ref={(ref) => {
          residentHistoryModal = ref;
        }}

      >
        {" "}
        <FacilityResidentHistory fkResidentId={ResidentID} />
        {" "}
      </PopupModal>

      <PopupModal
                // title="Sales Order Message"
                title={<div className="card-title">
                <span>
                  <h3 className="card-label">Skip Wounds Message</h3>
                  <small className="">
                    <b>Facility:</b> {props.facility.facilityName},{" "}
                    <b>Ownership/Headquarters:</b> {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
                    <b>Purchasing Group-Chain:</b>{" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
                  </small>
                </span>
              </div>}
                showButton={false}
                ref={(ref) => {
                    showSkipWoundMsgModal = ref;
                }}

            >
                {" "}
                <SkipWoundMsg  fkResidentId={ResidentID}    onSave={()=>{showSkipWoundMsgModal.hideModal()}} />
                {" "}
            </PopupModal>
            <PopupModal
               onClose={(ref) => {
                      getAllResident();
                      ref.setState({ show: false })
                      // Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning',
                      //     () => { ref.setState({ show: false }); }, () => { }, null, "Exit Without Saving");
                  }}
                title={<div className="card-title">

                    <span>
                        <h3 className="card-label">Wound Evaluation 
                        {/* - First Name:{" "}
                            {Common.replaceNullWithString(this.state.Resident.firstName)},{" "}Last Name: {Common.replaceNullWithString(this.state.Resident.lastName)} */}
                            </h3>

                    </span>
                </div>}
                dialogClassName="modal-95w" ref={ref => { editWoundmodal = ref; }}>
                {addWoundComponent} </PopupModal>
    </>
  );
}

// export default AllFacilityResident = injectIntl(AllFacilityResident);
export default AllFacilityResident;
