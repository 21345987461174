import React from "react";
import * as Api from "../../../../../General/api";
import * as Common from "../../../../../General/common";


const getAllFacility = (cb) => {
    Api.getRequest(Api.Uri_GetFacility + "?isAssessmentView=1")
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.success) {
            if (res.data.result != null) {
              if (res.data.result.length > 0) {
                cb(res.data.result);
              }
            }
          }
        }
      })
      .catch(function (error) {
        Common.LogError(error, "Facility", "getAllFacility");
      })
      .then(() => { });
  };


  const getAllPartailOrderFacilityList = (cb,loadingCB) => {
    //setIsLoading(true);
    Api.getRequestQry(Api.Uri_GetPartailOrderFacilities, "?queueId=" + 0)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
                cb(res.data.result);
            }
          }
        }
      })
      .catch((ex) => { })
      .then(() => {
        loadingCB(false);
      });
  };

  export {getAllFacility,getAllPartailOrderFacilityList};