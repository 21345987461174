
import React, { useCallback, useRef } from 'react';
import { Button, Form } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { Card, CardBody, CardFooter, CardHeader, CardHeaderToolbar } from "../../../_metronic/_partials/controls/Card";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { toJpeg } from "html-to-image";
import uuid from 'node-uuid'
import moment from 'moment';
import Select, { createFilter } from "react-select";
import { showSuccessAlertAutoClose } from '../../General/common';
import { FormattedDate, FormattedMessage, injectIntl } from "react-intl";

const data = [
  {
    name: "20/1/22",
    uv: 100
  },
  {
    name: "21/1/22",
    uv: 70
  },
  {
    name: "22/1/22",
    uv: 50
  },
  {
    name: "23/1/22",
    uv: 30
  },
  {
    name: "24/1/23",
    uv: 10
  },
  {
    name: "25/1/22",
    uv: 0
  }
];
var _sampleWoundObj = {
  residentName: '',
  physicanName: '',
  woundLocation: '',
  woundType: '',
  woundEvaluationDetail: []
}
class WoundAreaTrendReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValidated: false,
      facilityName: null,
      residentName: null,
      woundType: null,
      facilityList: [],
      facilityResidentList: [],
      residentWoundList: [],
      IsLoading: false,
      showReport: false,
      facilityForPDFName: null,
      wound: {
        FacilityId: null,
        ResidentId: null,
        WoundId: null,
        fromDate: "",
        toDate: ""
      },
      woundEvaluationList: [],
      woundEvaluations: {
        residentName: '',
        physicanName: '',
        woundLocation: '',
        woundType: '',
        woundEvaluationDetail: [],
        evaluationDateArray: []
      }

    }
  }
  componentDidMount() {
    this.getAllFacility();
  }
  getAllFacility() {
    Api.getRequest(`${Api.Uri_GetFacility}?isAssessmentView=1`,)
      .then((res) => {

        if (res.data.statusCode == 200) {
          if (res.data.result != null) {

            if (res.data.result.length > 0) {
              debugger
              let facilityList = res.data.result.map(facility => ({ value: facility.id, label: facility.facilityName })).sort((a, b) => a.label.localeCompare(b.label));

              this.setState({ facilityList: facilityList, IsLoading: true })
            }
          }
        }
      }).catch((ex) => {
      });
  }
  onFacilitiChange = (e) => {

    this.setState({
      facilityResidentList: [],
      residentWoundList: [],
      residentName: null,
      woundType: null,
      facilityName: e,
    })

    if (e) {
      Api.getRequestQry(Api.Uri_GetResidentByFacilityId, "?facilityId=" + e.value)
        .then((res) => {
          if (res.data.statusCode == 200) {

            if (res.data.result != null) {
              if (res.data.result.length > 0) {
                const data = res.data.result;
                this.setState({
                  facilityResidentList: data.map((item) => {
                    return { value: item.key, label: item.value }
                  })
                })
              }
            }
          }
        })
        .catch((ex) => {
        })
        .then(() => {
        });
    }
  }
  onResidentChange = (e) => {
    debugger
    this.setState({
      residentWoundList: [],
      woundType: null,
      residentName: e,
    }, () => {

    })
    this.setState({ wound: { ...this.state.wound, residentId: e.value, woundId: e ? e.value : null } })


    if (e) {
      Api.getRequestById(Api.URI_GetResidentWoundByResidentID, e.value)
        .then((res) => {
          if (res.data.statusCode == 200) {
            if (res.data.result != null) {
              if (res.data.result.length > 0) {
                debugger

                let residentWoundList = res.data.result.map((item) => {
                  return { value: item.wound.id, label: `${Common.replaceNullWithString(item.wound.bodyRegion)}${(item.residentWoundLocation != null && item.wound.bodyRegion != null) ? "/" : ""}${Common.replaceNullWithString(item.residentWoundLocation)}` }
                })

                residentWoundList.unshift(
                  { value: 'all', label: 'All' }
                );
                this.setState({ residentWoundList: residentWoundList })

              }
            }
          }
        }).catch((ex) => {
          Common.LogError(ex, "ResidentWound", "Get");
        });
    }

  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ showReport: false, facilityForPDFName: this.state.facilityName.label })

    let obj = this.state.wound;

    var query = "?toDate=" + obj.toDate + "&fromDate=" + obj.fromDate + "&residentId=" + obj.residentId

    if (obj.woundId != null && obj.woundId != 'all') {
      query += `&woundId=${obj.woundId}`
    }


    Api.getRequestQry(Api.Uri_GetRptWoundAreaTrend, query)
      .then((res) => {

        if (res.data.statusCode == 200) {

          if (res.data.result != null && res.data.result.length == 0) {
            showSuccessAlertAutoClose(this.props.intl.formatMessage({ id: 'REPORTS.WOUNDAREATREND.RECORD_NOT_FOUND' }), 'warning', 'warning!', 3000)
          }
          debugger
          if (res.data.result != null) {

            let woundEvaluationList = [];

            for (let woundIndex in res.data.result) {
              let woundEvaluation = res.data.result[woundIndex];
              let evaluationDateArray = [];
              var uvc = 0

              for (let woun of woundEvaluation.woundEvaluationDetail) {
                uvc += 10;
                evaluationDateArray.push({
                  name: this.props.intl.formatDate(moment.utc(woun.evaluationDate).local(), {    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',}),
                  uv: uvc
                });
              }

              let tmpEvaluation = {
                residentName: woundEvaluation.residentName,
                physicanName: woundEvaluation.physicanName,
                woundLocation: woundEvaluation.woundLocation,
                woundType: woundEvaluation.woundType,
                woundEvaluationDetail: woundEvaluation.woundEvaluationDetail,
                evaluationDateArray: evaluationDateArray
              };

              woundEvaluationList.push(tmpEvaluation);
            }


            this.setState({ woundEvaluationList, showReport: true })
          } else {
            Common.showSuccessAlert(this.props.intl.formatMessage({ id: 'REPORTS.WOUNDAREATREND.RECORD_NOT_FOUND' }), "warning", "Warnng!", 3000);

            this.setState({ showReport: false })

          }
        }
      })
      .catch((ex) => {


      })
      .then(() => {

      });
  }

  handleClearFilters = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      facilityName: null,
      residentName: null,
      woundType: null,
      facilityResidentList: [],
      residentWoundList: [],
      wound: { fromDate: "", toDate: "" },
      showReport: false
    })
  }

  renderWoundForm() {
    return (
      <>{
        (this.state.IsLoading == true) ?
          <form onSubmit={e => this.handleSubmit(e)}>
            <div className='row'>
              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" htmlFor="FacilityName">
                    <FormattedMessage id="REPORTS.WOUNDAREATREND.FACILITY_NAME" defaultMessage="Facility Name" />
                  </label>
                  <Select
                    options={this.state.facilityList}
                    placeholder={this.props.intl.formatMessage({ id: 'REPORTS.WOUNDAREATREND.SELECT' })}
                    id="facilityId"
                    name="facilityId"
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: 'start' })}
                    onChange={(value) => {
                      this.onFacilitiChange(value)
                    }}
                    value={this.state.facilityName}
                  />
                </div>
              </div>
              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" htmlFor="ResidentId">
                    <FormattedMessage id="REPORTS.WOUNDAREATREND.RESIDENT_NAME" defaultMessage="Resident Name" />
                  </label>
                  <Select
                    options={this.state.facilityResidentList}
                    placeholder={this.props.intl.formatMessage({ id: 'REPORTS.WOUNDAREATREND.SELECT' })}

                    id="residentId"
                    name="residentId"
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: 'start' })}
                    onChange={(value) => {

                      this.onResidentChange(value)
                    }}
                    value={this.state.residentName}
                  />
                </div>
              </div>
              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" htmlFor="WoundId">
                    <FormattedMessage id="REPORTS.WOUNDAREATREND.WOUND" defaultMessage="Wound" />
                  </label>
                  <Select
                    options={this.state.residentWoundList}
                    placeholder={this.props.intl.formatMessage({ id: 'REPORTS.WOUNDAREATREND.SELECT' })}
                    id="woundId"
                    name="woundId"
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: 'start' })}
                    onChange={e => {
                      this.setState({ woundType: e });
                      this.setState({ wound: { ...this.state.wound, woundId: e ? e.value : null } })
                    }}
                    value={this.state.woundType}
                  />
                </div>
              </div>
              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" htmlFor="FromDate">
                    <FormattedMessage id="REPORTS.WOUNDAREATREND.FROM_DATE" defaultMessage="From Date" />
                  </label>
                  <input type='date' id='FromDate'
                    name="fromDate"
                    min='1900-01-01'
                    max="3999-12-31"
                    required
                    value={this.state.wound.fromDate}
                    onChange={e => {
                      this.setState({ wound: { ...this.state.wound, fromDate: e.target.value } })
                    }}
                    className="form-control form-control-sm" />
                </div>
              </div>
              <div className='col-lg-4'>
                <div className="form-group">
                  <label className="form-label" htmlFor="ToDate">
                    <FormattedMessage id="REPORTS.WOUNDAREATREND.TO_DATE" defaultMessage="To Date" />
                  </label>
                  <input type='date' id='ToDate'
                    name="toDate"
                    min='1900-01-01'
                    max="3999-12-31"
                    required
                    onChange={e => Common.updateInputValueInState(this.state.wound, e, this, this.state)}
                    value={this.state.wound.toDate}
                    className="form-control form-control-sm" />
                </div>
              </div>

            </div>
            <div className='d-flex justify-content-end py-3'>
              <Button type='submit' variant="success" className="mr-5 px-10" size="sm">
                <FormattedMessage id="REPORTS.WOUNDAREATREND.SEARCH_REPORT" defaultMessage="Search Report" />
              </Button>
              <button className='btn btn-danger btn-sm' onClick={(e) => this.handleClearFilters(e)}>
                <FormattedMessage id="REPORTS.WOUNDAREATREND.CLEAR_FILTER" defaultMessage="Clear filter" />
              </button>
            </div>
          </form> :
          <div className="d-flex justify-content-center">
            <Loader
              type="Puff"
              color="#00BFFF"
              height={50}
              width={50}
            />
          </div>
      }
      </>
    );
  }
  render() {
    const { intl } = this.props;
    async function PrintElem(state) {
      let doc = new jsPDF('p', 'pt', 'a4', false);
      const elm = document.querySelectorAll("div[id^='tableForPrint-']");

      for await (const item of elm) {
        await html2canvas(item, { scale: 2 }).then(canvasItem => {
          const pixelHeight = 1600 * 2;
          for (let i = 0; i <= item.clientHeight * 2; i += pixelHeight) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = canvasItem.width;
            canvas.height = pixelHeight;
            ctx.drawImage(canvasItem, 0, i, canvasItem.width, pixelHeight, 0, 0, canvasItem.width, pixelHeight);
            const imgData = canvas.toDataURL('image/png');
            doc.addImage(imgData, 'PNG', 10, 30, 575, 0, undefined, 'FAST');
            if (i + pixelHeight < item.clientHeight * 2) {
              doc.addPage();
            }
          }
        });

        if (Array.from(elm).indexOf(item) !== elm.length - 1) {
          await doc.addPage();
        }
      }

      const pageCount = doc.internal.getNumberOfPages();
      doc.setFont('Poppins', 'normal', 'bold');
      doc.setFontSize(8);
      doc.setTextColor(112, 108, 108);
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text(intl.formatMessage({ id: 'REPORTS.WOUNDAREATREND.PAGE' }) + ' ' + i + ' ' + intl.formatMessage({ id: 'REPORTS.WOUNDAREATREND.OF' }) + ' ' + pageCount, doc.internal.pageSize.width - 50, doc.internal.pageSize.height - 20);
      }

      doc.save(`${state.facilityForPDFName} - ${intl.formatMessage({ id: 'REPORTS.WOUNDAREATREND.TITLE' })}.pdf`);
    }

    return (

      <>
        <Card>
          <CardHeader className='Text-center' title={<FormattedMessage id="REPORTS.WOUNDAREATREND.TITLE" defaultMessage="Wound Area Trend Report" />}>
            <CardHeaderToolbar>
              <div className="example-tools">

              </div>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            {this.renderWoundForm()}
            <hr />
            {this.state.showReport &&
              <>
                <div className='row'>
                  <div className='col-12'>
                    <button type="button" onClick={() => PrintElem(this.state)} className="mr-3 btn btn-success btn-sm float-right">
                      <FormattedMessage id="REPORTS.WOUNDAREATREND.PRINT_REPORT" defaultMessage="Print Report" />
                    </button>
                  </div>
                </div>
                <div className='trend-report'>
                  {
                    this.state.woundEvaluationList.map((woundEvalutation, index) => {
                      const elementId = `tableForPrint-${index}`;
                      return <div className='trend-report_content'>
                        <div id={elementId}>

                          <h2 className='text-center mb-10 mt-5 text-primary'>
                            <FormattedMessage id="REPORTS.WOUNDAREATREND.TITLE" defaultMessage="Wound Area Trend Report" />
                          </h2>
                          <div className='border border-dark px-3 pt-3'>
                            <table className='table border-0 table-sm trendReportInfo'>
                              <tbody className='font-weight-bolder'>
                                <tr>
                                  <td>
                                    <FormattedMessage id="REPORTS.WOUNDAREATREND.NAME" defaultMessage="Name" />:
                                  </td>
                                  <td>{woundEvalutation.residentName}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FormattedMessage id="REPORTS.WOUNDAREATREND.MD_NAME" defaultMessage="MD Name" />:
                                  </td>
                                  <td>{woundEvalutation.physicanName}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FormattedMessage id="REPORTS.WOUNDAREATREND.LOCATION_OF_WOUND" defaultMessage="Location of Wound" />:
                                  </td>
                                  <td>{woundEvalutation.woundLocation}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FormattedMessage id="REPORTS.WOUNDAREATREND.TYPE_OF_WOUND" defaultMessage="Type of Wound" />:
                                  </td>
                                  <td>{woundEvalutation.woundType}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <FormattedMessage id="REPORTS.WOUNDAREATREND.DATE_TODAY" defaultMessage="Date today" />:
                                  </td>
                                  <td>
                                    {this.props.intl.formatDate(woundEvalutation.dateToday, {
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit',
                                    })}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className='mt-10'>
                            <table className='table table-bordered table-sm w-100'>
                              <thead>
                                <tr>
                                  <th><FormattedMessage id="REPORTS.WOUNDAREATREND.DATE" defaultMessage="Date" /></th>
                                  <th><FormattedMessage id="REPORTS.WOUNDAREATREND.LENGTH" defaultMessage="Length" /></th>
                                  <th><FormattedMessage id="REPORTS.WOUNDAREATREND.WIDTH" defaultMessage="Width" /></th>
                                  <th><FormattedMessage id="REPORTS.WOUNDAREATREND.AREA" defaultMessage="Area" /></th>
                                  <th><FormattedMessage id="REPORTS.WOUNDAREATREND.PUSH_SCORE" defaultMessage="Push Score" /></th>
                                </tr>
                              </thead>
                              <tbody>
                                {woundEvalutation.woundEvaluationDetail?.map((wound) => {
                                  debugger
                                  return (
                                    <tr key={uuid()}>
                                      <td><FormattedDate value={wound.evaluationDate} /></td>
                                      <td>{wound.woundLength}</td>
                                      <td>{wound.woundWidth}</td>
                                      <td>{wound.woundArea}</td>
                                      <td>{wound.pushScore}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <h2 className='text-center mt-10'>
                            <FormattedMessage id="REPORTS.WOUNDAREATREND.EVALUATION_DATES" defaultMessage="Evaluation Dates" />
                          </h2>
                          <ResponsiveContainer width="100%" height={300}>
                            <LineChart
                              style={{ width: "100% !important" }}
                              data={woundEvalutation.evaluationDateArray}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 10
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Line type="monotone" dataKey="uv" stroke="#3699FF" />
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    })}
                </div>

              </>
            }
          </CardBody>
        </Card>

        <canvas id='c' width='100' height='100' style={{ display: 'none' }}></canvas>

      </>

    );
  }
}

export default injectIntl(WoundAreaTrendReport);
