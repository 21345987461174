import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { facilityWoundReportStyles } from './reportDocument';
import moment from "moment";
import {
  exudate,
  calculatePushScore,
} from "../../../../General/common";

const woundDetailFirstRowStyle = StyleSheet.create({
    detailFirstRow: {
        backgroundColor: '#EBEDF3',
        color: '#3F4254',
        padding: 5,
        fontSize: 8,
        minHeight: 45,
        alignItems: 'center',
    }
});

const WoundDetailFirstRow = ({detail, intl}) => {
    const styles = facilityWoundReportStyles;
    const acquisitionEnum = {
        1: intl.formatMessage({ id: "REPORTS.WOUNDS.ADMITTED" }),
        2: intl.formatMessage({ id: "REPORTS.WOUNDS.ACQUIRED" }),
        3: intl.formatMessage({ id: "REPORTS.WOUNDS.CLOSED" }),
        4: intl.formatMessage({ id: "REPORTS.WOUNDS.REOCCURRING" }),
    };
    const orientationEnum = {
        LEFT: 2,
        RIGHT: 1,
    };

    const getHorizontalOrientation = (orientation) => {
        switch (orientation) {
          case orientationEnum.LEFT:
            return intl.formatMessage({ id: "REPORTS.WOUNDS.LEFT" }) + " - ";
          case orientationEnum.RIGHT:
            return intl.formatMessage({ id: "REPORTS.WOUNDS.RIGHT" }) + " - ";
          default:
            return "";
        }
    };
    
    const horizontalOrientation = getHorizontalOrientation(
        detail.orientationLeftRight
    );

    
    const exudateMap = {
        Light: exudate.light,
        Moderate: exudate.moderate,
        Heavy: exudate.heavy,
    };

    let exudateId = exudateMap[detail.exudateAmountName] ? exudateMap[detail.exudateAmountName] : 0;

    return (
        <View style={[styles.tableRow, woundDetailFirstRowStyle.detailFirstRow, {position: 'relative', marginTop: -1}]}>
            <View style={[styles.w8]}>
                <Text>{detail.residentName}</Text>
            </View>
            
            <View style={[styles.w8]}>
                <Text>{detail.roomUnit ? detail.roomUnit : "N/A"}</Text>
            </View>

            <View style={[styles.w8]}>
                <Text>
                    {intl.formatDate(moment(detail.dateFirstObserved), { year: "numeric", month: "2-digit", day: "2-digit",})}
                </Text>
            </View>

            
            <View style={[styles.w6, styles.flexCenter]}>
                <Text>{detail.acquisition === acquisitionEnum[1] ? '1' : '0'}</Text>
            </View>

            <View style={[styles.w6, styles.flexCenter]}>
                <Text>{detail.acquisition === acquisitionEnum[2] ? '1' : '0'}</Text>
            </View>

            <View style={[styles.w4, styles.flexCenter]}>
                <Text>{detail.acquisition === acquisitionEnum[3] ? '1' : '0'}</Text>
            </View>

            <View style={[styles.w10, styles.flexCenter]}>
                <Text>{detail.acquisition === acquisitionEnum[4] ? '1' : '0'}</Text>
            </View>
            
            <View style={[styles.w6]}>
                <Text>
                {horizontalOrientation}
                {detail.woundBodyRegion} / {detail.woundLocation}
                </Text>
            </View>

            <View style={[styles.w6, styles.flexCenter]}>
                <Text>{detail.woundType}</Text>
            </View>

            <View style={[styles.w6, styles.flexCenter]}>
                <Text>{detail.woundLength}</Text>
            </View>

            <View style={[styles.w6, styles.flexCenter]}>
                <Text>{detail.woundWidth}</Text>
            </View>

            <View style={[styles.w6, styles.flexCenter]}>
                <Text>{detail.woundDepth}</Text>
            </View>

            <View style={[styles.w10, styles.flexCenter]}>
                <Text>{detail.woundThicknessName}</Text>
            </View>

            <View style={[styles.w6, styles.flexCenter]}>
                <Text>{detail.exudateAmountName}</Text>
            </View>

            <View style={[styles.w4, styles.flexCenter]}>
                <Text>
                {calculatePushScore(detail.woundLength * detail.woundWidth, exudateId, detail.fkWoundSevereTissueTypeId)}
                </Text>
            </View>
        </View>
    )
}

export default WoundDetailFirstRow;