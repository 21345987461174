import { Document, Page, Font, StyleSheet  } from '@react-pdf/renderer';
import React from 'react';
import DocumentHeader from './documentHeader';
import DocumentBody from './documentBody';

export const facilityWoundReportStyles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 10
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    table: {
      display: 'table',
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderColor: '#EBEDF3'
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCol: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderColor: '#EBEDF3'
    },
    tableColBorderBottom: {
      borderBottom: '1px solid #EBEDF3'
    },
    tableColBorderRight: {
      borderRight: '1px solid #EBEDF3'
    },
    tableCell: {
      margin: 5,
      fontSize: 8
    },
    header: {
      color: '#3F4254',
      backgroundColor: '#F3F6F9',
      justifyContent: 'center',
      fontSize: 2
    },
    groupedHeader: {
        display: 'flex',
        flexDirection: 'row',
        width: '26%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rotateHeader: {
        transform: 'rotate(-45deg)',
        borderStyle: 'none'
    },
    w4: {
        width: '4%'
    },
    w5: {
        width: '5%'
    },
    w6: {
        width: '6%'
    },
    w8: {
        width: '8%'
    },
    w10: {
        width: '10%'
    },
    w11: {
        width: '11%'
    },
    w15: {
        width: '15%'
    },
    w20: {
        width: '20%'
    },
    w25: {
        width: '25%'
    },
    w40: {
        width: '40%'
    },
    w42: {
        width: '42%'
    },
    w50: {
        width: '50%'
    }
  });

Font.register({family: 'Poppins', src: 'https://fonts.googleapis.com/css?family=Poppins'})
const ReportDocument = ({woundEvaluations, intl}) => {
    return (
        <Document>
            {woundEvaluations.map(({
                woundType,
                facilityName,
                toDate,
                fromDate,
                woundEvaluationDetail,
                }, index) => (
                <Page size="A4" style={facilityWoundReportStyles.page} key={index} orientation='landscape'>
                    <DocumentHeader facilityName={facilityName} toDate={toDate} fromDate={fromDate} woundType={woundType} intl={intl} />

                    <DocumentBody details={woundEvaluationDetail} intl={intl} />
                </Page>
            ))}
        </Document>
    );
}

export default ReportDocument;