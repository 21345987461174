import RegionTypeEnum from "@/shared/models/enums/RegionTypeEnum";

export default function getRegion() {
  /** @type {"BR" | "US" | "UK"} */
  const region = process.env.REACT_APP_COUNTRY || "US";
  // const region = "BR";
  // const region = "UK";

  return RegionTypeEnum[region];
}
