import React from "react";

import { withMultiRegionComponent } from "@/shared/wrappers/withMultiRegion";
import facilityResidentUS from "./lang/facilityResidentUS";
import facilityResidentBR from "./lang/facilityResidentBR";
import facilityResidentUK from "./lang/facilityResidentUK";

const FacilityResidentMultiRegion = withMultiRegionComponent({
  US: facilityResidentUS,
  UK: facilityResidentUK,
  BR: facilityResidentBR,
});

function FacilityResidentWrapper(props) {
  return <FacilityResidentMultiRegion {...props} />;
}

export default FacilityResidentWrapper;
