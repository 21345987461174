
import RoutePaths from "@/app/constants/routepaths";
import RBAC from "@/app/General/roleModuleActions";
import getCurrentPageWithMultiRegion from "../getCurrentOrDefaultPage";
/**
* Validate the preconditions before determining the current page.
* @param {object} user
*/
function validatePreConditions(user) {
  if (!user || !user.roles || !Array.isArray(user.roles.roleList)) {
    throw new Error("Pre-condition failed");
  }
}

/**
* Validate the postconditions after determining the current page.
* @param {string} currentPage
*/
function validatePostConditions(currentPage) {
  if (!Object.values(RoutePaths).includes(currentPage)) {
    throw new Error("Post-condition failed");
  }
}

/**
* Get current page based on user roles
* @param {object} user
* @returns {string} currentPage
*/
function getCurrentPage(user) {
  return getCurrentPageWithMultiRegion(user);
}

/**
* Main function to get current page by user roles
* @param {object} user
* @returns {string} currentPage
*/
export default function getCurrentPageByUserRoles(user) {
  validatePreConditions(user);

  const currentPage = getCurrentPage(user);

  validatePostConditions(currentPage);

  return currentPage;
}