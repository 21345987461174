import { add } from "date-fns";
import sampleResidentCommon from "./sampleResidentCommon";

const sampleResidentUK = {
  ...sampleResidentCommon,
  smokingStatus: "",
  allergicToPenicillin: false,
  allergicToAdhesive: false,
  otherAllergies: false,
  otherAllergiesDescription: "",
  noKnownAllergies: false,
  height: 0,
  weight: 0,
  hadAsthma: false,
  hadCOPD: false,
  hadDepressionAnxiety: false,
  hadDiabetes1Insulin: false,
  hadDiabetes2Diet: false,
  hadDiabetes2insulin: false,
  hadGERD: false,
  hadHypercholesterolemia: false,
  hadHyperlipidemia: false,
  hadHypertension: false,
  hadObesity: false,
  hadOsteoarthritis: false,
  hadOsteoparosis: false,
  hadProstateHyperplasia: false,
  hadCardiovascularDisease: false,
  otherMedicalHistory: false,
  otherMedicalHistoryDescription: "",
  isWhiteley: false,
  isSelfReferral: false,
  insuranceName: "",
  insurancePolicyNo: null,
  isNHSReferral: false,
  residentEmergencyContact: {
    name: "",
    phoneNumber: "",
    relationship: "",
  },
  regularConcomitantMedication: "",
  FkResidentSmokingStatusId: 1,
  gpAddress: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateId: null,
    zip: "",
  }
};

export default sampleResidentUK;
