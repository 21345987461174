import React, { useEffect, useState } from 'react';
import { Button, Modal, Dropdown, DropdownButton } from "react-bootstrap";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { ModulesName, UserActions } from "../../General/roleModuleActions"
import { requestResetPassword } from "../../modules/Auth/_redux/authCrud";
import PopupModal from "../modal/popupmodal";
import NewPersonForm from "./newpersonform";
import AddEditUser from "./addEditUser";
import UserRole from './UserRole';
import { Switch } from '@material-ui/core';

var NewUserModal = null;
var AddUser = null;
var UserRoleModal1 = null;
var RoleUser1 = null;
export default function UserList(props) {
  const [userList, setUserList] = useState([]);
  const [USERID, setUserID] = useState('');
  const [user, setUser] = useState({
    userID: null,
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    mobile: '',
    fkMangerId: null,
    userName: '',
    status: true,
    forGentell: 1,
    btId: '',
  });

  useEffect(() => {
    getAllUsers();
  }, []);

  const showEditRolePopup = async (ID) => {
    await setUserID(ID)
    UserRoleModal1.showModal();
  };

  const onRoleFormSubmit = () => {
    UserRoleModal1.hideModal();
    getAllUsers();
  }

  const showAddUserModel = async () => {
    await setUser({
      userId: null,
      email: '',
      firstName: '',
      middleName: '',
      lastName: '',
      mobile: '',
      fkMangerId: null,
      userName: '',
      status: true,
      forGentell: props.isForGentell === 1 ? true : false,
      btId: '',
    })
    NewUserModal.showModal();

  }
  const showEditPopup = async (obj) => {
    await setUser(obj);
    NewUserModal.showModal();
  };

  const updateUserStatus = (obj) => {
    var msg = "You want to " + (obj.Status ? "Activate user" : "Deactivate user");
    Common.showConfirmation(msg, "", updateStatus, null, obj)
  };

  const updateStatus = (obj) => {
    if (obj) {
      Api.postRequest(Api.Uri_UpdateUserStatus, obj).then((res) => {
        if (res.data.success) {
          getAllUsers();
          Common.showSuccessAlertAutoClose("User Status Updated Successfully", "success", "Success!", Common.defaultCloseModalTimeout)
        } else {
          Common.showSuccessAlertAutoClose(res.data.message, "error", "Error!", Common.defaultCloseModalTimeout);
        }
      });
    }
  }

  const setPasswordEmail = (email) => {
    requestResetPassword(email)
      .then((response) => {
        if (response.status === Common.successStatusCode) {
          Common.showSuccessAlertAutoClose("Set password email sent Successfully", "success", "Success!", Common.defaultCloseModalTimeout)
        } else {
          Common.showSuccessAlertAutoClose(response.data.message, "error", "Error!", Common.defaultCloseModalTimeout);
        }
      });
  }

  const onUserFormSubmit = () => {
    NewUserModal.hideModal();
    Common.showSuccessAlertAutoClose("User Saved successful", "success", "Success!", Common.defaultCloseModalTimeout);
    getAllUsers();
  }

  const UserRoleButton = async (ID) => {
    await showEditRolePopup(ID);
  }

  const userRolesBadge = (_userRoles) => {
    let userBadgeRoleUI = [];
    let userRoles = _userRoles.split(',')
    for (var i = 0; i < userRoles.length; i++) {
      userBadgeRoleUI.push(<span className="badge badge-warning ml-1" key={i}>{userRoles[i]}</span>);
    }
    return userBadgeRoleUI;
  }

  const handleUserStatus = userID => event => {
    let user = {};
    user.UserID = userID;
    user.Status = event.target.checked
    updateUserStatus(user);
  };

  const getAllUsers = () => {
    Api.getRequestQry(Api.apiUrls.getAllUsers, "?isForGentell=" + props.isForGentell)
      .then((response) => {
        setAllUserList(response.data.result);
        Common.LoadDataTable("repFirmList");
      });
  }

  const setAllUserList = (list) => {
    let rows = [];
    for (var i = 0; i < list.length; i++) {
      const UserObject = list[i];
      rows.push(<tr key={i.toString()}>
        <td>
          {UserObject.firstName}
        </td>
        <td>
          {UserObject.lastName}
        </td>
        <td>
          {UserObject.userName}
        </td>
        <td>
          {UserObject.email}
        </td>
        <td>
          <Switch
            checked={UserObject.status}
            onChange={handleUserStatus(UserObject.userID)}
            value="Status"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </td>
        <td>
          {UserObject.mobile}
        </td>
        <td>
          {UserObject.roles === "" ? <span className="badge badge-success"></span> : userRolesBadge(UserObject.roles)}
        </td>
        <td align="center">
          <div className="text-center">
            <DropdownButton
              drop={"down"}
              size="xs"
              variant="primary"
              title={'Actions'}
              id={`dropdown-button-drop-actions`}
              key={'down'}>
              <Dropdown.Item onClick={() => showEditPopup(UserObject)} eventKey="1" >Edit User</Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={() => UserRoleButton(UserObject.userID)} >User Roles </Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={() => setPasswordEmail(UserObject.email)} >Set Password Email </Dropdown.Item>
            </DropdownButton>
          </div>
        </td>
      </tr>);
    }
    setUserList(rows);
  }

  return (
    <>
      <Card className="example example-compact">
        <CardHeader title="Users List" toolbar={<div className="card-toolbar">
          <div className="example-tools">
            <Button
              variant="primary"
              size="sm"
              onClick={() => showAddUserModel()}
            >
              Add User
            </Button>
          </div>
        </div>} />
        <CardBody>
          <table
            className="table table-striped table-hover table-sm table-bordered"
            id="repFirmList"
          >
            <thead>
              <tr>
                <th data-priority="1">First Name</th>
                <th>Last Name</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Phone</th>
                <th>Role(s)</th>
                <th data-priority="2" width="130px">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {userList}
            </tbody>
          </table>
        </CardBody>
      </Card>
      {
        <PopupModal
          size="lg"
          title="Add User"
          module={ModulesName.Module_Setup}
          action={UserActions.Create}
          buttonPress={() => AddUser.onSubmit()}
          ref={(ref) => {
            NewUserModal = ref;
          }}
        >
          {" "}
          <AddEditUser
            ref={(ref) => {
              AddUser = ref;
            }}
            onSaveAPI={() => onUserFormSubmit()}
            user={user}
          />{" "}
        </PopupModal>
      }
      <PopupModal
        size="lg"
        title={"Assign Role "}
        module={ModulesName.Module_Setup}
        action={UserActions.Update}
        buttonPress={() => RoleUser1.onButtonPress()}
        ref={ref => { UserRoleModal1 = ref; }}>
        <UserRole
          userID={USERID}
          isForGentell={props.isForGentell}
          ref={ref => { RoleUser1 = ref; }} onSaveAPI={() => onRoleFormSubmit()} />
      </PopupModal>
      <Modal
        size="xl"
        aria-labelledby="addPersonModal"
        centered
        scrollable
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title>New Sales Rep</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewPersonForm />
        </Modal.Body>
      </Modal>
    </>
  );
}
