import RoutePaths from "@/app/constants/routepaths";
import RBAC from "@/app/General/roleModuleActions";

const common = {
  [RBAC.Role_Facility_Level1]: RoutePaths.Page_FacilityQueue,
  [RBAC.Role_Facility_Level2]: RoutePaths.Page_FacilityQueue,
};

const roleToPageMap = {
  US: {
    [RBAC.Role_Gentell_Administrator]: RoutePaths.Page_Facility,
    [RBAC.Role_Gentell_RegionalManager]: RoutePaths.Page_WorkQueue,
    [RBAC.Role_Gentell_WoundCareSpecialist]: RoutePaths.Page_WorkQueue,
    [RBAC.Role_Gentell_SeniorBiller]: RoutePaths.Page_Billing,
    [RBAC.Role_Gentell_IntakeProcessor]: RoutePaths.Page_Billing,
    ...common,
    default: RoutePaths.Page_Facility,
  },
  BR: {
    [RBAC.Role_Gentell_Administrator]: RoutePaths.Page_FacilityQueue,
    ...common,
    default: RoutePaths.Page_FacilityQueue,
  },
  UK: {
    [RBAC.Role_Gentell_Administrator]: RoutePaths.Page_FacilityQueue,
    ...common,
    default: RoutePaths.Page_FacilityQueue,
  },
};

export default roleToPageMap;
