import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { facilityWoundReportStyles } from './reportDocument';

const tableSecondHeaderStyle = StyleSheet.create({
    box: {
        fontFamily: 'Helvetica-Bold',
        minHeight: 35,
        borderLeft: '1px solid #EBEDF3'
    }
});

const TableSecondHeader = ({intl}) => {
    const styles = facilityWoundReportStyles;
    return (
        <View style={[styles.tableRow, tableSecondHeaderStyle.box]}>
            <View style={[styles.tableCol, styles.w8, styles.header, {borderBottom: 'none'}]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({id: 'REPORTS.WOUNDS.WOUND_STATUS'})}
                </Text>
            </View>
            <View style={[styles.tableCol, styles.w42, styles.header, {borderBottom: 'none'}]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({id: 'REPORTS.WOUNDS.WOUND_BED_PERI_WOUND', defaultMessage: 'Wound bed Peri-wound Wound Edge Description'})}
                </Text>
            </View>
            <View style={[styles.tableCol, styles.w50, styles.header, {borderBottom: 'none'}]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({id: 'REPORTS.WOUNDS.TREATMENT', defaultMessage: 'Treatment'})}
                </Text>
            </View>
        </View>
    )
}

export default TableSecondHeader;