import React, { useEffect, useRef, useState, useMemo } from "react";
import { Animated } from "react-animated-css";
import Webcam from "react-webcam";
import { apiUrls, defaultUrls } from "../../constants/endpoints";
import {

  blobContainerResidentName,
} from "../../constants/endpoints";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { GetDateString, replaceNullOrEmptyWithString, showSuccessAlert, uploadImage } from "../../General/common";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Document, Page, pdfjs } from 'react-pdf'

import { FormattedDate, FormattedMessage, injectIntl } from "react-intl";

import { Button, Nav, NavDropdown } from 'react-bootstrap';
import AOBModal from "./Modals/AOBModal";
import { Add, CloudUpload, PlusOne, RemoveRedEye } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import * as icons from "@material-ui/icons";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export function ResidentDetailsComponent(props) {
    const { intl } = props;

    const { id, firstName, middleName, lastName, imageUrl, ssn, doB, unit, room, admissionDate, dischargeDate, rfirstName, rlastName, rmiddleName,
        responsiblePartyHomePhone, responsiblePartyCellPhone, fkShipToPhysicianId, allergicToSulpha, allergicToCollagen,
        allergicToTape, allergicToIodine, createdDate, createdBy, modifiedBy, modifiedDate,
        facilityName, genderName, primaryPhysicianName, residentStatus, isProcess, isEcp,aobPdfUrl,imageAOBName,faceSheetName } = props.resident;
    debugger
    const [picture, setPicture] = useState(imageUrl)
    const [isloading, setisloading] = useState(false);
    const [isCameraPreview, setisCameraPreview] = useState(false);
    const [isDocumentView, setIsDocumentView] = useState(false);
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [AobModalOpen, setAobModalOpen] = useState(false);
    const [viewType, setViewType] = useState('');
    const [aobFileName, setAobFileName] = useState(imageAOBName || '');
    const [faceSheetFileName, setFaceSheetFileName] = useState(faceSheetName||'');
    const fileInputRef = useRef(null);
    const [residentInsuranceList, setResidentInsuranceListUI] = useState([]);
    useEffect(()=>{
        setAobFileName(props.resident.imageAOBName)
    },[props.resident.imageAOBName])
    useEffect(() => {
        setResidentInsuranceList(props.resident.residentInsuranceList)
        //setResidentListTemp();
        //Common.LoadDataTable("facilityList")
    }, []);
    const uploadFaceSheetHandler = (e, _file = null, isCapture = false) => {
        setisloading(true);
        var file = isCapture ? _file : e.target.files[0];
        // // debugger;
        try {
            uploadImage(file).then((r) => {
                debugger;
                if (r != "e") {
                    Api.postRequest(apiUrls.upsertResidentImage + `?id=${id}&&url=${r}&&fileName=${file.name}`, null, false).then((response) => {
                        if (response.data.success) {
                            setPicture(r);
                            props.getAllResident();
                            showSuccessAlert(response.data.message, "success", "Success");
                            if (isCapture) {
                                setImgSrc(null);
                                setisCameraPreview(false);
                            }
                        }
                        else {
                            // image failed to bind with resident
                        }
                    });
                }
                else {
                    // image failed to upload
                }
            }).then(() => {
                setisloading(false)
            });
        }
        catch (ex) {
            setisloading(false)
        }
    }
  
  const cameraStyles = {
    width: "100%",
    height: "100%",
  };

  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const handleTakeFaceSheetPicture = () => {
    setisCameraPreview(!isCameraPreview);
    setImgSrc(isCameraPreview ? null : imgSrc);
  };


  const capture = React.useCallback(() => {
    if (isloading == true) return;
    const imageSrc = webcamRef.current.getScreenshot();
    debugger;
    setImgSrc(imageSrc);
  }, [isloading]);
  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  const updateNowHandler = () => {
    if (isloading == true) return;
    urltoFile(imgSrc, "temp.txt", "text/plain").then(function (file) {
      uploadFaceSheetHandler(null, file, true);
    });
  };

  const checkURL = useMemo(() => {
    debugger;
    if (!picture) return false;

    const doesTheUrlMatchSupportedFileExtensions =
      picture.match(/https?:\/\/.*\.(jpeg|jpg|gif|png)(?=\?)/) != null;
    return doesTheUrlMatchSupportedFileExtensions;
  }, [picture]);
  //   const  GetResidentInsurance = () => {
  //         Api.getRequestById(Api.URI_GetAllResidentInsurance, props.resident.id).then((res) => {

  //             if (res.data.statusCode == 200) {
  //                 if (res.data.result != null) {
  //                     if (res.data.result.length > 0) {
  //                         setResidentInsuranceList(res.data.result);
  //                     }
  //                 }
  //             }

  //             Common.LoadDataTable("physicianList")
  //         }).catch((ex) => {
  //         });
  //     }

  const setResidentInsuranceList = (list) => {
    // // debugger
    let rows = [];
    try {
      for (var i = 0; i < list.length; i++) {
        // // debugger
        const obj = list[i];
        rows.push(
          <tr key={i.toString()}>
            <td>{Common.replaceNullWithString(obj.insuranceName)}</td>
            <td>
              {obj.fkInsuranceLevelId == 1
                ? "Primary"
                : obj.fkInsuranceLevelId == 2
                  ? "Secondary"
                  : obj.fkInsuranceLevelId == 3
                    ? "Tertiary"
                    : "N/A"}
            </td>
            {/* <td>{(obj.startDate)}</td>
                    <td>{(obj.endDate)}</td> */}

            <td>
              {Common.replaceNullWithString(obj.policyNo) != ""
                ? obj.policyNo
                : "N/A"}
            </td>
          </tr>
        );
      }
      setResidentInsuranceListUI(rows);
      // // debugger
    } catch (error) { }
  };
  const onDocumentLoadSuccess = (numPages) => {
    debugger;
    setNumPages(numPages.numPages);
  };


  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3">
          <div className="row  pt-1">
            <fieldset className="rounded border p-2 mb-3 d-block w-100">
              <legend className="text-primary fs_1_1 text-center">
                <FormattedMessage id="RESIDENTDETAILS.FACESHEET_MANAGEMENT" />
              </legend>
              {checkURL ? (
                <div className="col-sm-6 col-md-12">
                  <div className="d-flex mb-5">
                    <img
                      src={replaceNullOrEmptyWithString(
                        picture,
                        defaultUrls.defaultResidentPicture
                      )}
                      className="img-resident mx-auto"
                      alt="img"
                    />
                  </div>
                </div>
              ) : (
                // <button type="button" className="btn btn-primary my-2 btn-block" onClick={() => setIsDocumentView(true)}>Show Face Sheet</button>
                <Button
                  className="d-block w-100 mb-1"
                  onClick={() => setIsDocumentView(true)}
                >
                  <FormattedMessage id="RESIDENTDETAILS.SHOW_FACE_SHEET" />
                </Button>
              )}

              {/* {checkURL(picture) ? (
                <div className="col-sm-6 col-md-12">
                  <div className="d-flex mb-5">
                    <img
                      src={replaceNullOrEmptyWithString(
                        picture,
                        defaultUrls.defaultResidentPicture
                      )}
                      className="img-resident mx-auto"
                      alt="img"
                    />
                  </div>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary my-2 btn-block"
                  onClick={() => setIsDocumentView(true)}
                >
                  <FormattedMessage id="RESIDENTDETAILS.SHOW_FILE" />
                </button>
              )} */}
              <div className="">
                <button
                  type="button"
                  className="btn btn-primary my-3 btn-block"
                  disabled={isloading}
                  onClick={handleTakeFaceSheetPicture}
                >
                  <span>
                    <FormattedMessage id="RESIDENTDETAILS.TAKE_FACE_SHEET_PICTURE" />
                  </span>
                  {isloading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
                <div
                  className="alert bg-secondary mx-auto  show p-2 mb-5"
                  role="alert"
                >
                  <div className="text-center">
                    <small>
                      <FormattedMessage id="RESIDENTDETAILS.FACESHEET" />
                    </small>
                  </div>
                </div>
                <div className="text-center">
                  <input
                    accept="image/png, image/jpeg, application/pdf"
                    type="file"
                    id="inputUploadFacesheet"
                    onChange={uploadFaceSheetHandler}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                  />
                  <label htmlFor="inputUploadFacesheet" className="btn btn-primary my-3 btn-block">
                    {
                      intl.formatMessage({ id: "CHOOSE_FILE" })
                    }
                  </label>
                </div>
                <div className="row mt-4">
                  <div
                    className="alert bg-light-warning mx-auto show p-2 mb-5"
                    role="alert"
                    style={{ width: "95%" }}
                  >
                    <div className="text-center">
                      <span className="font-weight-bolder">
                        <FormattedMessage id="RESIDENTDETAILS.EXISTING_FILE" />
                      </span>{" "}
                      <span>{faceSheetFileName}</span>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="row pt-3 mt-5">
            <fieldset className="rounded border p-2 mb-3 d-block w-100">
              <legend className="text-primary fs_1_1 text-center">
                <FormattedMessage id="RESIDENTDETAILS.AOB_MANAGEMENT" />
              </legend>
              <Button
                className="d-block w-100 mb-1"
                disabled={
                  props.resident?.aobPdfUrl == null ||
                  props.resident?.aobPdfUrl == ""
                }
                onClick={() => {
                  setViewType("view");
                  setAobModalOpen(true);
                }}
              >
                <FormattedMessage id="RESIDENTDETAILS.SHOW_AOB_FILE" />
              </Button>
              <Button
                className="d-block w-100 mb-1"
                variant="success"
                onClick={() => {
                  setViewType("new");
                  setAobModalOpen(true);
                }}
              >
                <FormattedMessage id="RESIDENTDETAILS.NEW_AOB_FORM" />
              </Button>
              <Button
                className="d-block w-100 mb-1"
                variant="success"
                onClick={() => {
                  setViewType("upload");
                  setAobModalOpen(true);
                }}
              >
                <FormattedMessage id="RESIDENTDETAILS.UPLOAD_AOB_FILE" />
              </Button>
              <div className="row mt-4">
                <div
                  className="alert bg-light-warning mx-auto show p-2 mb-5"
                  role="alert"
                  style={{ width: "95%" }}
                >
                  <div className="text-center">
                    <span className="font-weight-bolder">
                      <FormattedMessage id="RESIDENTDETAILS.EXISTING_AOB" />
                    </span>{" "}
                    <span>{aobFileName}</span>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-9 col-xl-9">
          {!isDocumentView ? (
            <>
              {isCameraPreview ? (
                <>
                  <Animated
                    animationIn="fadeIn"
                    animationInDuration={500}
                    animationOut="fadeOut"
                  >
                    <div className="row d-flex justify-content-center">
                      <div className={imgSrc ? "col-md-6" : "col-md-12"}>
                        <Webcam
                          ref={webcamRef}
                          style={cameraStyles}
                          audio={false}
                          screenshotFormat="image/jpeg"
                        />
                      </div>
                      <div className="col-md-6">
                        {imgSrc ? (
                          <Animated
                            animationIn="fadeIn"
                            animationInDuration={500}
                            animationOut="fadeOut"
                          >
                            <img style={cameraStyles} src={imgSrc} />
                          </Animated>
                        ) : null}
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className={imgSrc ? "col-md-6" : "col-md-12"}>
                        <button
                          disabled={isloading}
                          type="button"
                          className="btn btn-primary my-3 btn-block"
                          onClick={capture}
                        >
                          <i className="fa fa-camera"></i>
                          <FormattedMessage id="RESIDENTDETAILS.CAPTURE" />
                          {isloading && (
                            <span className="ml-3 spinner spinner-white"></span>
                          )}
                        </button>
                      </div>
                      <div className="col-md-6">
                        {imgSrc ? (
                          <button
                            type="button"
                            disabled={isloading}
                            className="btn btn-success my-3 btn-block"
                            onClick={updateNowHandler}
                          >
                            <i className="fa fa-floppy-o"></i>{" "}
                            <FormattedMessage id="RESIDENTDETAILS.UPDATE_NOW" />{" "}
                            {isloading && (
                              <span className="ml-3 spinner spinner-white"></span>
                            )}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </Animated>
                </>
              ) : (
                <>
                  <fieldset className="rounded border p-2 mb-3">
                    <legend className="text-primary fw-500">
                      <FormattedMessage id="RESIDENTDETAILS.BASIC_INFORMATION" />:
                    </legend>
                    <table
                      className="table table-sm border-0 w-100 table-striped"
                      id=""
                    >
                      <tbody>
                        <tr>
                          <td width="25%" className="font-weight-bolder">
                            <FormattedMessage id="RESIDENTDETAILS.RESIDENT" />
                          </td>
                          <td>{`${firstName == null ? "" : firstName} ${middleName == null ? "" : middleName
                            } ${lastName}`}</td>
                          <td className="font-weight-bolder">
                            <FormattedMessage id="RESIDENTDETAILS.SOCIAL_SECURITY" />
                          </td>
                          <td>{ssn}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bolder">
                            <FormattedMessage id="RESIDENTDETAILS.GENDER" />
                          </td>
                          <td>{genderName}</td>
                          <td className="font-weight-bolder">
                            <FormattedMessage id="RESIDENTDETAILS.PACIENT_STATUS" />
                          </td>
                          <td>{residentStatus}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bolder">
                            <FormattedMessage id="RESIDENTDETAILS.UNIT" />
                          </td>
                          <td>{unit}</td>
                          <td className="font-weight-bolder">
                            <FormattedMessage id="RESIDENTDETAILS.DATE_OF_BIRTH" />
                          </td>
                          <td>{<FormattedDate value={doB} />}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bolder">
                            <FormattedMessage id="RESIDENTDETAILS.ROOM" />
                          </td>
                          <td>{room}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bolder">
                            <FormattedMessage id="RESIDENTDETAILS.CURRENT_ADMISSION_DATE" />
                          </td>
                          <td>{<FormattedDate value={admissionDate} />}</td>
                          <td className="font-weight-bolder">
                            <FormControlLabel
                              label={`${intl.formatMessage({
                                id: "RESIDENTDETAILS.EQUAL_CARE",
                              })}:`}
                              labelPlacement="start"
                              control={
                                <Checkbox checked={isEcp} value="checkedA" />
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                  <div className="row">
                    <div className="col-xl-12">
                      <fieldset className="rounded border p-2 mb-3">
                        <legend className="text-primary fw-500">
                          <FormattedMessage id="RESIDENTDETAILS.INSURANCE_INFORMATION" />:
                        </legend>
                        <table
                          className="table table-sm border-0 w-100 table-striped"
                          id=""
                        >
                          <thead>
                            <tr>
                              <th>
                                <FormattedMessage id="RESIDENTDETAILS.INSURANCE_NAME" />
                              </th>
                              <th>
                                <FormattedMessage id="RESIDENTDETAILS.INSURANCE_LEVEL" />
                              </th>
                              <th>
                                <FormattedMessage id="RESIDENTDETAILS.POLICY_NO" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>{residentInsuranceList}</tbody>
                        </table>
                      </fieldset>
                    </div>
                    <div className="col-xl-6">
                      <fieldset className="rounded border p-2 mb-3">
                        <legend className="text-primary fw-500">
                          <FormattedMessage id="RESIDENTDETAILS.PHYSICIAN_INFORMATION" />:
                        </legend>
                        <table
                          className="table table-sm border-0 w-100 table-striped"
                          id=""
                        >
                          <tbody>
                            <tr>
                              <td className="font-weight-bolder">
                                <FormattedMessage id="RESIDENTDETAILS.PHYSICIAN" />
                              </td>
                              <td>{primaryPhysicianName}</td>
                            </tr>
                            <tr>
                              <td className="font-weight-bolder">
                                <FormattedMessage id="RESIDENTDETAILS.PHYSICIAN_ADDRESS" />
                              </td>
                              <td>N/A</td>
                            </tr>
                            <tr>
                              <td className="font-weight-bolder">
                                <FormattedMessage id="RESIDENTDETAILS.PHYSICIAN_PHONE" />
                              </td>
                              <td>(11) 99999-9999</td>
                            </tr>
                            <tr>
                              <td className="font-weight-bolder">
                                <FormattedMessage id="RESIDENTDETAILS.PHYSICIAN_FAX" />
                              </td>
                              <td>N/A</td>
                            </tr>
                          </tbody>
                        </table>
                      </fieldset>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <Document
                file={{
                  url: picture,
                }}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
                <Page pageNumber={numPages} />
              </Document>
              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-primary my-3 btn-block"
                    onClick={() => setIsDocumentView(false)}
                  >
                    <FormattedMessage id="RESIDENTDETAILS.BACK_TO_RESIDENT_INFO" />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {AobModalOpen && (
        <AOBModal
          isOpen={AobModalOpen}
          stateChanger={setAobModalOpen}
          resident={props.resident}
          type={viewType}
          props={props}
        ></AOBModal>
      )}
    </>
  );
}

export const ResidentDetails = injectIntl(ResidentDetailsComponent);

export default ResidentDetails;