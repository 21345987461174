/**
 * @typedef {Object} RegionSettings
 * @property {string} i18n - The i18n language code for the region.
 * @property {string} components - The components identifier for the region.
 */

/**
 * Enum representing region-specific settings.
 * 
 * @enum {RegionSettings}
 * @type {Object}
 * @readonly
 * @property {RegionSettings} BR - The settings for Brazil.
 * @property {RegionSettings} US - The settings for the United States.
 * @property {RegionSettings} UK - The settings for the United Kingdom.
 */

/**
 * Enum representing region-specific settings.
 *
 * @type {{ BR: RegionSettings, US: RegionSettings, UK: RegionSettings }}
 */
const regionTypeEnum = {
  BR: {
    i18n: "pt-BR",
    components: "BR",
  },
  US: {
    i18n: "en",
    components: "US",
  },
  UK: {
    i18n: "uk",
    components: "UK",
  },
};

export default regionTypeEnum;
