import { withMultiRegionProperties } from "../wrappers/withMultiRegion";

const isValidZipCodeRegion = {
  BR: /^\d{5}-\d{3}$/,
  US: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  UK: /^.{4,9}$/,
};

export default function isValidZipCode(value) {
  if (value === "") {
    return true;
  }

  return withMultiRegionProperties(isValidZipCodeRegion).test(value);
}
