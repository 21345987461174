/*eslint-disable */

import React from "react";
import AsyncSelect from "@/shared/components/atoms/AsyncSelect";
import { Animated } from "react-animated-css";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import * as Api from "@/app/General/api";
import * as Common from "@/app/General/common";
import { injectIntl } from "react-intl";

export class Insurance extends React.Component {
  constructor(props) {
    super(props);
    this.intl = props.intl;
    this.state = {
      formValidated: false,
      isEdit: false,
      insurancesList: [],
      insuranceSelect: [],
      insuranceLevels: [],
      showModalAddInsurance: false,
      AddPhysicianComponent: "",
      residentInsuranceListUI: [],
      residentInsuranceList: props.residentInsuranceList,
      primaryPhysiciansInputValue: "",
      primaryPhysician: null,
      ResidentInsurance: {
        idR: 0,
        fkResidentId: props.residentID,
        fkInsuranceIdR: "",
        fkInsuranceLevelIdR: "",
        active: true,
        startDate: "",
        endDate: "",
        policyNoR: "",
        payPct: "",
        insuranceName: "",
        insuranceLevel: "",
        isAddEdit: false,
      },
    };
  }
  componentDidMount() {
    debugger;

    this.setResidentInsuranceList(this.state.residentInsuranceList);

    // this.getAllDropdowns();
  }

  //#region  add person

  getInsuranceList = () => {
    return this.state.residentInsuranceList;
  };
  handleCloseAdd = () => {
    this.setState({ showModalAddInsurance: false });
    // if(this.props.residentID!=null){
    // this.GetResidentInsurance();
    // }
  };

  handleShowAddInsurance = () => {
    var Insurance = {
      idR: 0,
      fkResidentId: this.props.residentID,
      fkInsuranceIdR: "",
      fkInsuranceLevelIdR: "",
      active: true,
      startDate: "",
      endDate: "",
      policyNoR: "",
      payPct: "",
      insuranceName: "",
      insuranceLevel: "",
      isAddEdit: false,
    };
    this.setState({ ResidentInsurance: Insurance });

    //  this.setState({ AddPhysicianComponent: <AddInsurancmodal insurance={this.state.Insurance} close={this.handleCloseAdd} /> })
    this.setState({ showModalAddInsurance: true, isEdit: false });
  };

  //#region loop to  print the physician firm list

  // GetResidentInsurance = () => {
  //     debugger
  //     Api.getRequestById(Api.URI_GetAllResidentInsurance, this.props.residentID).then((res) => {

  //         if (res.data.statusCode == 200) {
  //             if (res.data.result != null) {
  //                 if (res.data.result.length > 0) {
  //         this.setState({ residentInsuranceList: res.data.result });

  //                     this.setResidentInsuranceList(res.data.result);
  //                 }
  //             }
  //         }

  //         Common.LoadDataTable("physicianList")
  //     }).catch((ex) => {
  //     });
  // }
  updateResidentActiveInActive = (obj) => {
    var msg = this.intl.formatMessage({
      id: "NEWRESIDENT.INSURANCE.YOU_WANT_TO_REMOVE_INSURANCE",
    });
    Common.showConfirmation(msg, "", this.removeInsurance, null, obj);
  };
  removeInsurance = (insurance) => {
    if (insurance) {
      let savedInsurance = JSON.parse(
        JSON.stringify(
          this.state.residentInsuranceList.filter((obj) => {
            return obj.fkInsuranceLevelId != insurance.fkInsuranceLevelId;
          })
        )
      );
      this.setState({ residentInsuranceList: savedInsurance });
      this.setResidentInsuranceList(savedInsurance);
      Common.showSuccessAlertAutoClose(
        this.intl.formatMessage({
          id: "NEWRESIDENT.INSURANCE.RESIDENT_UPDATED_SUCCESSFULLY",
        }),
        "success",
        this.intl.formatMessage({
          id: "BUTTON.SUCCESS.TITLE",
        }),
        3000
      );
    }
  };
  setResidentInsuranceList = (list) => {
    debugger;
    // // debugger
    let rows = [];
    try {
      for (var i = 0; i < list.length; i++) {
        // // debugger
        const obj = list[i];

        rows.push(
          <tr key={i.toString()}>
            <td>{Common.replaceNullWithString(obj.insuranceName)}</td>
            <td>
              {obj.fkInsuranceLevelId == 1
                ? this.intl.formatMessage({
                    id: "NEWRESIDENT.INSURANCE.PRIMARY",
                  })
                : obj.fkInsuranceLevelId == 2
                ? this.intl.formatMessage({
                    id: "NEWRESIDENT.INSURANCE.SECONDARY",
                  })
                : obj.fkInsuranceLevelId == 3
                ? this.intl.formatMessage({
                    id: "NEWRESIDENT.INSURANCE.TERTIARY",
                  })
                : this.intl.formatMessage({ id: "NEWRESIDENT.INSURANCE.N_A" })}
            </td>
            {/* <td>{(obj.startDate)}</td>
                    <td>{(obj.endDate)}</td> */}
            <td>
              {Common.replaceNullWithString(obj.policyNo) != ""
                ? obj.policyNo
                : this.intl.formatMessage({ id: "NEWRESIDENT.INSURANCE.N_A" })}
            </td>
            <td align="center">
              <div className="text-center">
                <DropdownButton
                  drop={"down"}
                  size="xs"
                  variant="primary"
                  title={"Actions"}
                  id={`dropdown-button-drop-actions`}
                  key={"down"}
                >
                  {/* <Dropdown.Item eventKey={"1" + i.toString()} onClick={() => handleShowAdd(obj)}>Edit</Dropdown.Item> */}
                  <Dropdown.Item
                    eventKey={"2" + i.toString()}
                    onClick={() => this.updateInsurance(obj)}
                  >
                    {this.intl.formatMessage({
                      id: "NEWRESIDENT.INSURANCE.EDIT",
                    })}
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={"2" + i.toString()}
                    onClick={() => this.updateResidentActiveInActive(obj)}
                  >
                    {this.intl.formatMessage({
                      id: "NEWRESIDENT.INSURANCE.REMOVE",
                    })}
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </td>
          </tr>
        );
      }
      this.setState({ residentInsuranceListUI: rows });
      // // debugger
    } catch (error) {}
  };

  //#endregion

  updateInsurance = (obj) => {
    var insurance = {
      insuranceName: obj.insuranceName,
      insuranceLevel: obj.insuranceLevel,
      fkInsuranceLevelIdR: obj.fkInsuranceLevelId,
      fkInsuranceIdR: obj.fkInsuranceId,
      policyNoR: obj.policyNo,
      idR: obj.id,
      isAddEdit: true,
    };
    this.setState({ ResidentInsurance: insurance });
    this.setState({ showModalAddInsurance: true, isEdit: true });
  };

  // async getAllDropdowns() {
  //     Api.getRequest(Api.Uri_GetInsurances).then((res) => {

  //         if (res.data.statusCode == 200) {
  //             if (res.data.result != null) {
  //                 if (res.data.result.length > 0) {
  //                     this.setState({ insurancesList: res.data.result })
  //                    var insuranceSelect= res.data.result.map(insurance => (
  //                         <option
  //                             key={insurance.key}
  //                             value={insurance.key}
  //                         >
  //                             {insurance.value}
  //                         </option>
  //                     ))
  //                     this.setState({insuranceSelect:insuranceSelect})
  //                 }
  //             }
  //         }

  //     }).catch((ex) => {
  //     });
  // }

  // setAllinsurances(insurances) {
  //     return
  // }
  //#endregion

  handleSubmit = (event) => {
    debugger;
    const form = event.currentTarget.form;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      let _state = this.state;
      _state.formValidated = true;
      this.setState(_state);
    } else {
      this.URI_UpsertResidentInsurance();
    }
  };
  addMedicareInsurance = (policyNoR) => {
    debugger;
    //    var savedInsurancesList = this.state.residentInsuranceList.filter
    if (policyNoR == "" || policyNoR == null) {
      let savedInsurance = JSON.parse(
        JSON.stringify(
          this.state.residentInsuranceList.filter((obj) => {
            return obj.fkInsuranceLevelId != 1 && obj.fkInsuranceId != 103;
          })
        )
      );
      this.setState({ residentInsuranceList: savedInsurance });
      this.setResidentInsuranceList(savedInsurance);
    } else {
      var isUpdated = false;
      let savedInsurance = JSON.parse(
        JSON.stringify(
          this.state.residentInsuranceList.map((ins) => {
            if (ins.fkInsuranceId == 103 && 1 == ins.fkInsuranceLevelId) {
              ins.policyNo = policyNoR;
              isUpdated = true;
            }
            return ins;
          })
        )
      );
      if (isUpdated) {
      } else {
        savedInsurance.push({
          insuranceName: "MEDICARE",
          insuranceLevel: "Primary",
          fkInsuranceLevelId: 1,
          fkInsuranceId: 103,
          policyNo: policyNoR,
          id: 0,
          isAddEdit: true,
          fkResidentId: this.props.residentID,
        });
      }
      this.setState({ residentInsuranceList: savedInsurance });
      this.setResidentInsuranceList(savedInsurance);
      this.props.selectInsurance(savedInsurance);
    }
  };
  URI_UpsertResidentInsurance() {
    debugger;
    // fkInsuranceId: '',
    //         fkInsuranceLevelId
    // insuranceName:'',
    // InsuranceLevel:''
    let obj = this.state.ResidentInsurance;
    //    this.state.insurancesList.map(ins => {
    //         if (ins.key == obj.fkInsuranceIdR) {
    //             obj.insuranceName = ins.value;
    //         }

    //     })

    if (obj.fkInsuranceLevelIdR == 1) {
      obj.insuranceLevel = "Primary";
    } else if (obj.fkInsuranceLevelIdR == 2) {
      obj.insuranceLevel = "Secondary";
    } else if (obj.fkInsuranceLevelIdR == 3) {
      obj.insuranceLevel = "Tertiary";
    } else {
      obj.insuranceLevel = "";
    }
    //   var isNew=true;
    let savedInsurance = JSON.parse(
      JSON.stringify(
        this.state.residentInsuranceList.map((ins) => {
          if (
            obj.isAddEdit &&
            obj.idR == ins.id &&
            obj.fkInsuranceLevelIdR == ins.fkInsuranceLevelId
          ) {
            ins.fkInsuranceLevelId = obj.fkInsuranceLevelIdR;
            ins.insuranceLevel = obj.insuranceLevel;
            ins.insuranceName = obj.insuranceName;
            ins.fkInsuranceId = obj.fkInsuranceIdR;

            ins.policyNo = obj.policyNoR;
          }
          return ins;
        })
      )
    );
    if (!obj.isAddEdit) {
      savedInsurance.push({
        insuranceName: obj.insuranceName,
        insuranceLevel: obj.insuranceLevel,
        fkInsuranceLevelId: obj.fkInsuranceLevelIdR,
        fkInsuranceId: obj.fkInsuranceIdR,
        policyNo: obj.policyNoR,
        id: 0,
        isAddEdit: true,
        fkResidentId: this.props.residentID,
      });
    }
    var count = 0;
    savedInsurance.map((ins) => {
      if (ins.fkInsuranceLevelId == obj.fkInsuranceLevelIdR) {
        debugger;
        count++;
      }
    });
    if (count > 1) {
      Common.showSuccessAlert(
        obj.insuranceLevel +
          this.intl.formatMessage({
            id: "NEWRESIDENT.INSURANCE.LEVEL_ASSIGNED",
          }),
        "warning",
        this.intl.formatMessage({
          id: "BUTTON.WARNING.TITLE",
        }),
        3000
      );
      return false;
    }
    this.setState({ residentInsuranceList: savedInsurance });
    this.setResidentInsuranceList(savedInsurance);
    this.props.selectInsurance(savedInsurance);
    Common.showSuccessAlertAutoClose(
      this.intl.formatMessage({
        id: "NEWRESIDENT.INSURANCE.SUCCESS",
      }),
      "success",
      this.intl.formatMessage({
        id: "BUTTON.SUCCESS.TITLE",
      }),
      3000
    );
    this.setState({ showModalAddInsurance: false });
  }
  // URI_UpsertResidentInsurance() {
  //     let obj = this.state.Insurance;
  //     // // debugger
  //     Api.postRequest(Api.URI_UpsertResidentInsurance, obj).then((res) => {
  //         // // debugger
  //         if (res.data.success) {
  //             if (res.data.result != null) {
  //                 Common.showSuccessAlertAutoClose("Insurance Created Successfully", "success", "Success!", 3000);
  //                 this.props.close();
  //             }
  //             else {
  //                 Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
  //             }
  //         }
  //         else {
  //             Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
  //         }
  //     }).catch((ex) => {

  //         // // debugger
  //         Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
  //     });
  // }
  //#endregion
  setPrimaryPhysicianInputValue = async (value) => {
    this.setState({ primaryPhysiciansInputValue: value });
  };
  setPrimaryPhysician = (physician) => {
    this.setState({ primaryPhysician: physician });
    var _selectedPhysician = this.state.ResidentInsurance;
    if (physician != null) {
      debugger;
      _selectedPhysician.fkInsuranceIdR = physician.value;
      _selectedPhysician.insuranceName = Common.replaceNullWithString(
        physician.label
      );
    } else {
      _selectedPhysician.fkInsuranceIdR = null;
      _selectedPhysician.insuranceName = "";
    }

    this.setState({ ResidentInsurance: _selectedPhysician });
  };
  loadPrimaryPhysicians = async (inputValue) => {
    try {
      if (inputValue.length > 1) {
        var { data } = await Api.getRequestQry(
          Api.Uri_GetInsurances,
          `?searchTerm=${inputValue}`
        );
        if (data.success) {
          let orgList = [];
          if (data.result.length) {
            orgList = data.result;
          }
          debugger;
          // setOrganizations(orgList)
          return orgList.map((e) => {
            return {
              label: e.value,
              value: e.key,
            };
          });
        }
      }
      return [];
    } catch (error) {
      return [];
    }
  };
  render() {
    return (
      <>
        {this.state.showModalAddInsurance && (
          <Animated animationIn="fadeInLeft" animationInDuration={1000}>
            {/* {this.state.AddPhysicianComponent} */}
            <Form validated={this.state.formValidated}>
              <>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">
                        {this.intl.formatMessage({
                          id: "NEWRESIDENT.INSURANCE",
                        })}
                      </label>
                      {/* <select className="form-control form-control-sm"
                                            name="fkInsuranceIdR"
                                            required
                                            disabled={this.state.isEdit}
                                            value={Common.replaceNullWithString(
                                                this.state.ResidentInsurance.fkInsuranceIdR
                                              )}
                                            onChange={e => Common.updateInputValueInState(this.state.ResidentInsurance, e, this, this.state)}
                                        >
                                            <option></option>
                                            {this.state.insuranceSelect}
                                        </select> */}
                      <AsyncSelect
                        // defaultOptions
                        name="primaryPhysicianName"
                        required
                        inputValue={this.state.primaryPhysiciansInputValue}
                        onInputChange={this.setPrimaryPhysicianInputValue}
                        value={this.state.primaryPhysician}
                        onChange={(obj) => {
                          this.setPrimaryPhysician(obj);
                        }}
                        placeholder={this.intl.formatMessage({
                          id: "NEWRESIDENT.SELECT",
                        })}
                        loadOptions={this.loadPrimaryPhysicians}
                        isClearable
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">
                        {this.intl.formatMessage({
                          id: "NEWRESIDENT.INSURANCE.INSURANCE_LEVEL",
                        })}
                      </label>
                      <select
                        className="form-control form-control-sm"
                        name="fkInsuranceLevelIdR"
                        disabled={this.state.isEdit}
                        value={Common.replaceNullWithString(
                          this.state.ResidentInsurance.fkInsuranceLevelIdR
                        )}
                        required
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.ResidentInsurance,
                            e,
                            this,
                            this.state
                          )
                        }
                      >
                        <option></option>
                        <option value="1">
                          {this.intl.formatMessage({
                            id: "NEWRESIDENT.INSURANCE.PRIMARY",
                          })}
                        </option>
                        <option value="2">
                          {this.intl.formatMessage({
                            id: "NEWRESIDENT.INSURANCE.SECONDARY",
                          })}
                        </option>
                        <option value="3">
                          {this.intl.formatMessage({
                            id: "NEWRESIDENT.INSURANCE.TERTIARY",
                          })}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="FirstName">
                        {this.intl.formatMessage({
                          id: "NEWRESIDENT.INSURANCE.POLICY_NO",
                        })}
                      </label>
                      <input
                        type="text"
                        id="FirstName"
                        name="policyNoR"
                        value={Common.replaceNullWithString(
                          this.state.ResidentInsurance.policyNoR
                        )}
                        required
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.ResidentInsurance,
                            e,
                            this,
                            this.state
                          )
                        }
                        className="form-control form-control-sm"
                      />
                      <div className="invalid-feedback">
                        {this.intl.formatMessage({
                          id: "NEWRESIDENT.INSURANCE.POLICY_NO_IS_REQUIRED",
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </>

              <div className="d-flex justify-content-end py-3">
                <Button
                  variant="success"
                  className="mr-5 px-10"
                  size="sm"
                  onClick={(e) => this.handleSubmit(e)}
                >
                  {this.intl.formatMessage({
                    id: "NEWRESIDENT.INSURANCE.ASSIGN",
                  })}
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => this.handleCloseAdd()}
                >
                  {this.intl.formatMessage({
                    id: "NEWRESIDENT.INSURANCE.CANCEL",
                  })}
                </Button>
              </div>
            </Form>
          </Animated>
        )}

        {/* {!this.state.showModalAddPhysician && <Animated animationIn="fadeIn" animationInDuration={1000}> */}
        {/* <Card className="example example-compact">
                <CardHeader title='Inusrance List' />
                <CardBody> */}
        {/* <div className="alert border-primary bg-transparent text-primary fade show" role="alert"> */}
        <div className="d-flex align-items-center">
          {/* <div className="alert-icon">
                                <i className="fas fa-info-circle mr-4 text-primary"></i>
                            </div> */}
          <div className="flex-1">
            {/* <span className="h5 m-0 fw-700">Use buttons to do more actions </span> */}
          </div>
          <Button
            variant="primary"
            className="mr-5"
            size="sm"
            onClick={this.handleShowAddInsurance}
          >
            {this.intl.formatMessage({
              id: "NEWRESIDENT.INSURANCE.ADD_NEW_RESIDENT",
            })}
          </Button>
        </div>
        {/* </div> */}
        {/* </Animated>} */}
        <table
          className="table table-striped table-hover table-sm table-bordered"
          id="physicianList"
        >
          <thead>
            <tr>
              <th>
                {this.intl.formatMessage({
                  id: "NEWRESIDENT.INSURANCE.INSURANCE_NAME",
                })}
              </th>
              <th>
                {this.intl.formatMessage({
                  id: "NEWRESIDENT.INSURANCE.INSURANCE_LEVEL",
                })}
              </th>
              {/* <th>Start Date </th>
                            <th>End Date</th> */}
              <th>
                {this.intl.formatMessage({
                  id: "NEWRESIDENT.INSURANCE.POLICY_NO",
                })}
              </th>
              <th width="130px">
                {this.intl.formatMessage({
                  id: "NEWRESIDENT.INSURANCE.ACTION",
                })}
              </th>
            </tr>
          </thead>
          <tbody>{this.state.residentInsuranceListUI}</tbody>
        </table>
        {/* </CardBody>
            </Card> */}
      </>
    );
  }
}

export default injectIntl(Insurance, { forwardRef: true });

/*eslint-disable */
