import { withMultiRegionProperties } from "@/shared/wrappers/withMultiRegion";

const formatsMedicareMultiRegion = {
  BR: "###.###.###.###.####",
  UK: "#########################################",
  US: "#########################################",
};

export default function formatMedicare(value) {
  if (value === "") {
    return "";
  }

  if (value == null) {
    return "";
  }

  const rawValue = value.replace(/\D/g, "");
  const formatPattern = withMultiRegionProperties(formatsMedicareMultiRegion);

  if (!formatPattern) {
    return value;
  }

  let formattedValue = "";
  let index = 0;

  for (let char of formatPattern) {
    if (char === "#") {
      formattedValue += rawValue[index] || "";
      index++;
    } else {
      formattedValue += char;
    }
    if (index >= rawValue.length) {
      break;
    }
  }

  return formattedValue;
}
