import React from "react";

class ClassComponentHelpers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            operationStack: [],
            operationIsFinished: true,
        };
    }

    PushOperation = (operation,key) => {

        if(this.state.operationStack[key])
            return;

        const newOperation = {
            [key]:operation
        }

        this.setState((prevState) => {
            if (prevState.operationStack.length === 0) {
                return { operationStack: [...prevState.operationStack, newOperation] };
            }
            return prevState;
        });
    };

    PopOperations = () => {
        this.setState((prevState) => {
            const operationStack = [...prevState.operationStack];
            operationStack.pop();
            return { operationStack };
        });
        return this.state.operationStack[this.state.operationStack.length - 1];
    };

    ClearOperations = () => {
        this.setState({ operationStack: [] });
    };

    ExecuteOperation = (payload,id) => {
        if (!this.state.operationIsFinished) return;

        if(!this.state.operationStack.some(x =>{
            var key = Object.keys(x)[0];
            return key === id;
        }))return;
        const operation = this.PopOperations();
        this.setState({ operationIsFinished: false }, async () => {
            if (operation[id]) {
                await operation[id](payload);
                this.setState({ operationIsFinished: true });
            }
        });

    };


}

export default ClassComponentHelpers;