import React from "react";
import * as Common from "@/app/General/common";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import CheckPermission from "@/app/General/CheckPermission";
import RBAC from "@/app/General/roleModuleActions";
import { withMultiRegionFunction } from "@/shared/wrappers/withMultiRegion";

const setFacilityListUIUKBR = ({
  list,
  setIsPartail,
  setShowResident,
  setFacilityObj,
  showAddResidentFacility,
  editFacilityByID,
  intl,
  setFacility,
}) => {
  var rows = [];

  list.map((object, i) => {
    rows.push(
      <tr key={i.toString()}>
        <td align="left" className="customStyleAccorion">
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              setIsPartail(false);
              setShowResident(true);
              setFacilityObj(object);
            }}
          >
            {object.facilityName}
          </button>
        </td>

        <td>
          {Common.replaceNullOrEmptyWithString(object.organizationName, "")}
          {object.organizationName == null && object.chain == null ? "N/A" : ""}
          {object.organizationName != null && object.chain != null ? "/" : ""}
          {Common.replaceNullOrEmptyWithString(object.chain, "")}
        </td>
        <td>
          {Common.replaceNullOrEmptyWithString(object.clinicianName, "N/A")}
        </td>
        <td>{object.totalResident + "/" + object.totalWound}</td>
        <td align="center">
          <>
            <ButtonToolbar className="justify-content-center">
              <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                <DropdownButton
                  size="xs"
                  variant="primary"
                  title={intl.formatMessage({ id: "FACILITY.ACTIONS" })}
                  id={`dropdown-button-drop`}
                >
                  <CheckPermission
                    userRole={[
                      RBAC.Role_Gentell_Administrator,
                    ]}
                    yes={() => (
                      <>
                        <Dropdown.Item
                          eventKey="0"
                          onClick={() => {
                            showAddResidentFacility(object.id);
                          }}
                        >
                          {intl.formatMessage({ id: "FACILITY.ADD_USER" })}
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="6"
                          onClick={() => editFacilityByID(object.id)}
                        >
                          {intl.formatMessage({ id: "FACILITY.EDIT_FACILITY" })}
                        </Dropdown.Item>
                      </>
                    )}
                    no={() => null}
                  />
                </DropdownButton>
              </div>
            </ButtonToolbar>
          </>
        </td>
      </tr>
    );
  });

  setFacility(rows);
};

const setFacilityListUIUS = ({
  list,
  setIsPartail,
  setShowResident,
  setFacilityObj,
  showAddResidentFacility,
  intl,
  setFacility,
}) => {
  var rows = [];

  list.map((object, i) => {
    rows.push(
      <tr key={i.toString()}>
        <td align="left" className="customStyleAccorion">
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              setIsPartail(false);
              setShowResident(true);
              setFacilityObj(object);
            }}
          >
            {object.facilityName}
          </button>
        </td>

        <td>
          {Common.replaceNullOrEmptyWithString(object.organizationName, "")}
          {object.organizationName == null && object.chain == null ? "N/A" : ""}
          {object.organizationName != null && object.chain != null ? "/" : ""}
          {Common.replaceNullOrEmptyWithString(object.chain, "")}
        </td>
        <td>
          {Common.replaceNullOrEmptyWithString(object.clinicianName, "N/A")}
        </td>
        <td>{object.totalResident + "/" + object.totalWound}</td>
      </tr>
    );
  });

  setFacility(rows);
};

const setFacilityListUIByRegion = {
  UK: setFacilityListUIUKBR,
  BR: setFacilityListUIUKBR,
  US: setFacilityListUIUS,
};

const setFacilityListUIMultiRegion = withMultiRegionFunction(setFacilityListUIByRegion);

export default setFacilityListUIMultiRegion;
