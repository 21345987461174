import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useIntl } from "react-intl";
import * as Api from "../../General/api";
import * as Common from "../../General/common";

const ViewWoundInformationChangeReason = (props) => {
    const intl = useIntl();

    // ** States
    const [changeReasonsList, setChangeReasonsList] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        getWoundInfoChangeReasonDetails();
    }, []);

    const getWoundInfoChangeReasonDetails = async () => {
        debugger
        setLoading(true);
        var { data } = await Api.getRequestQry(Api.URI_GetWoundInfoChangeDetailByWoundId,
            "?residentId=" + props.residentId + "&woundId=" + props.woundId);
        if (data.success) {
            setChangeReasonsList(data.result);
        } else {
            Common.showSuccessAlert(
                data.message,
                "error",
                intl.formatMessage({ id: "VIEW_WOUND_INFO_CHANGE_REASON.NO_DATA" }),
                3000
            );
        }
        setLoading(false);
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "VIEW_WOUND_INFO_CHANGE_REASON.REASON_COLUMN" })} />
            <CardBody>
                {isLoading ? (
                    <div>{intl.formatMessage({ id: "VIEW_WOUND_INFO_CHANGE_REASON.LOADING" })}</div>
                ) : (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>{intl.formatMessage({ id: "VIEW_WOUND_INFO_CHANGE_REASON.SR" })}</th>
                                <th>{intl.formatMessage({ id: "VIEW_WOUND_INFO_CHANGE_REASON.CREATED_BY" })}</th>
                                <th>{intl.formatMessage({ id: "VIEW_WOUND_INFO_CHANGE_REASON.REASON_COLUMN" })}</th>
                                <th>{intl.formatMessage({ id: "VIEW_WOUND_INFO_CHANGE_REASON.NOTES_COLUMN" })}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {changeReasonsList.length > 0 ? (
                                changeReasonsList.map((reason, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{reason.createdBy}</td>
                                        <td>{reason.reason}</td>
                                        <td>{reason.notes}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">{intl.formatMessage({ id: "VIEW_WOUND_INFO_CHANGE_REASON.NO_DATA" })}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                )}
            </CardBody>
        </Card>
    );
};

export default ViewWoundInformationChangeReason;