import React from "react";
//import {useLang} from "./Metronici18n";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import enMessages from "./messages/en";
import esMessages from "./messages/es";
import ukMessages from "./messages/uk";
import ptBRMessages from "./messages/pt-BR";
import getRegion from "@/shared/functions/getRegion";

const allMessages = {
  en: enMessages,
  es: esMessages,
  uk: ukMessages,
  "pt-BR": ptBRMessages,
};

export function I18nProvider({ children }) {
  const locale = getRegion().i18n;
  const messages = allMessages[locale]
    ? allMessages[locale]
    : allMessages["en"];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
