import React from "react";

import { withMultiRegionComponent } from "@/shared/wrappers/withMultiRegion";
import { InsuranceBR } from "./lang/insuranceBR";
import insuranceUS from "./lang/insuranceUS";
import { InsuranceUK } from "./lang/insuranceUK";

const InsuranceMultiRegion = withMultiRegionComponent({
  US: insuranceUS,
  BR: InsuranceBR,
  UK: InsuranceUK,
});

function InsuranceWrapper(props) {
  return <InsuranceMultiRegion {...props} />;
}

export default InsuranceWrapper;
