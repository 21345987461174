import React from "react";
import { Animated } from "react-animated-css";
import { Form } from "react-bootstrap";
import * as Common from "@/app/General/common";
import { injectIntl } from "react-intl";

export class InsuranceBR extends React.Component {
  constructor(props) {
    super(props);
    this.intl = props.intl;
    this.state = {
      Resident: props.resident,
    };
  }

  render() {
    return (
      <>
        {
          <Animated animationIn="fadeInLeft" animationInDuration={1000}>
            <Form validated={this.state.formValidated}>
              <>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="InsuranceName">
                        {this.intl.formatMessage({
                          id: "NEWRESIDENT.INSURANCE",
                        })}
                      </label>
                      <input
                        type="text"
                        id="InsuranceName"
                        name="insuranceName"
                        value={Common.replaceNullWithString(
                          this.state.Resident.insuranceName
                        )}
                        required
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Resident,
                            e,
                            this,
                            this.state
                          )
                        }
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  {/* level removed */}
                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="Insurance Information"
                      >
                        {this.intl.formatMessage({
                          id: "NEWRESIDENT.INSURANCE.POLICY_NO",
                        })}
                      </label>
                      <input
                        type="text"
                        id="InsuransePolicyNo"
                        name="insurancePolicyNo"
                        value={Common.replaceNullWithString(
                          this.state.Resident.insurancePolicyNo
                        )}
                        required
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.Resident,
                            e,
                            this,
                            this.state
                          )
                        }
                        className="form-control form-control-sm"
                      />
                      <div className="invalid-feedback">
                        {this.intl.formatMessage({
                          id: "NEWRESIDENT.INSURANCE.POLICY_NO_IS_REQUIRED",
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </Form>
          </Animated>
        }
      </>
    );
  }
}

export default injectIntl(InsuranceBR, { forwardRef: true });
