import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { facilityWoundReportStyles } from './reportDocument';

const tableHeaderStyle = StyleSheet.create({
    box: {
        fontFamily: 'Helvetica-Bold',
        minHeight: 45,
        borderTop: '1px solid #EBEDF3',
        borderLeft: '1px solid #EBEDF3'
    }
});

const TableHeader = ({intl}) => {
    const styles = facilityWoundReportStyles;
    return (
        <View style={[styles.tableRow, tableHeaderStyle.box]}>
            <View style={[styles.tableCol, styles.w8, styles.header]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({id: 'REPORTS.WOUNDS.NAME', defaultMessage: 'Name'})}
                </Text>
            </View>
            
            <View style={[styles.tableCol, styles.w8, styles.header]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.UNIT_ROOM",
                        defaultMessage:"Unit/Room"
                    })}
                </Text>
            </View>

            <View style={[styles.tableCol, styles.w8, styles.header]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.DATE_FIRST_OBSERVED",
                        defaultMessage:"Date First Observed"
                    })}
                </Text>
            </View>
            
            <View style={[styles.tableColBorderBottom, styles.w6, styles.header]}>
                <Text style={[styles.tableCell, styles.rotateHeader]}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.ADMITTED",
                        defaultMessage:"Admitted"
                    })}
                </Text>
            </View>
            
            <View style={[styles.tableColBorderBottom, styles.w6, styles.header]}>
                <Text style={[styles.tableCell, styles.rotateHeader]}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.ACQUIRED",
                        defaultMessage:"Acquired"
                    })}
                </Text>
            </View>

            <View style={[styles.tableColBorderBottom, styles.w4, styles.header]}>
                <Text style={[styles.tableCell, styles.rotateHeader]}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.CLOSED",
                        defaultMessage:"Closed"
                    })}
                </Text>
            </View>

            <View style={[styles.tableColBorderBottom, styles.tableColBorderRight, styles.w10, styles.header, {justifyContent: 'flex-start'}]}>
                <Text style={[styles.tableCell, styles.rotateHeader]}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.REOCCURRING",
                        defaultMessage:"Reoccurring"
                    })}
                </Text>
            </View>

            <View style={[styles.tableCol, styles.w6, styles.header]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.LOCATION",
                        defaultMessage:"Location"
                    })}
                </Text>
            </View>

            <View style={[styles.tableCol, styles.w6, styles.header]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.TYPE",
                        defaultMessage:"Type"
                    })}
                </Text>
            </View>

            <View style={[styles.tableCol, styles.w6, styles.header]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.LENGTH",
                        defaultMessage:"Length"
                    })}
                </Text>
            </View>

            <View style={[styles.tableCol, styles.w6, styles.header]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.WIDTH",
                        defaultMessage:"Width"
                    })}
                </Text>
            </View>

            <View style={[styles.tableCol, styles.w6, styles.header]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.DEPTH",
                        defaultMessage:"Depth"
                    })}
                </Text>
            </View>

            <View style={[styles.tableCol, styles.w10, styles.header]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.STAGE_THICKNESS",
                        defaultMessage:"Stage/Thickness"
                    })}
                </Text>
            </View>

            <View style={[styles.tableCol, styles.w6, styles.header]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.EXUDATE",
                        defaultMessage:"Exudate"
                    })}
                </Text>
            </View>

            <View style={[styles.tableCol, styles.w4, styles.header]}>
                <Text style={styles.tableCell}>
                    {intl.formatMessage({
                        id:"REPORTS.WOUNDS.PUSH",
                        defaultMessage:"Push"
                    })}
                </Text>
            </View>
        </View>
    )
}

export default TableHeader;