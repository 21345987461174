import * as Common from "@/app/General/common";

const sampleResidentCommon = {
  id: null,
  fkFacilityId: null,
  btPatientBrightreeID: null,
  residentBtKey: null,
  fkResidentStatusId: Common.ResidentStatusID.open,
  firstName: "",
  middleName: "",
  lastName: "",
  gender: null,
  ssn: "",
  doB: null,
  unit: "",
  room: "",
  medicare: "",
  admissionDate: null,
  dischargeDate: null,
  primaryPhysicianName: null,
  fkPrimaryPhysicianId: null,
  primaryPhysicianNpi: null,
  signingPhysicianNpi: null,
  allergicToLatex: false,
  allergicToHoney: false,
  currentlyMedA: false,
  onHospice: false,
  isMedB: false,
  isEcp: false,
  isMedicaidOnly: false,
  fkOrderingPhysicianId: null,
  fkBillingTypeId: null,
  rfirstName: "",
  rlastName: "",
  rmiddleName: "",
  shippingAddressId: null,
  billingAddressId: null,
  raddressId: null,
  responsiblePartyHomePhone: "",
  responsiblePartyCellPhone: "",
  fkShipToPhysicianId: null,
  isHospicePatient: false,
  allergicToSulpha: false,
  createdDate: "2021-01-21T13:13:13.304Z",
  createdBy: null,
  modifiedBy: null,
  modifiedDate: "2021-01-21T13:13:13.304Z",
  raddress: {
    addressId: null,
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateId: null,
    zip: "",
    countryId: null,
    phone1: "",
    phone2: "",
    fax: "",
    mobile1: "",
    mobile2: "",
    email: "",
    active: true,
  },
  shippingAddress: {
    addressId: null,
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateId: null,
    zip: "",
    countryId: null,
    phone1: "",
    phone2: "",
    fax: "",
    mobile1: "",
    mobile2: "",
    email: "",
    active: true,
  },
  physicianViewModel: {
    physicianId: 0,
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    physicianNote: "",
    npi: "",
    stateMedicaidId: "",
    licenseNo: "",
    TaxonomyCode: "",
    brightreeId: 0,
    doctorGroupId: 0,
    address: {
      addressId: 0,
      addressLine1: "",
      addressLine2: "",
      city: "",
      sateId: 1,
      countryId: 1,
      zip: "",
      phone1: "",
      email: "",
      mobile1: "",
      fax: "",
    },
  },
  fkSigningPhysicianId: null,
  signingPhysicianName: "",
  signingContactPerson: "",
  signingPhysicianPhone: "",
  signingPhysicianEmail: "",
  physicianSigningOrder: {
    physicianId: 0,
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    physicianNote: "",
    npi: "",
    stateMedicaidId: "",
    licenseNo: "",
    TaxonomyCode: "",
    brightreeId: 0,
    doctorGroupId: 0,
    address: {
      addressId: 0,
      addressLine1: "",
      addressLine2: "",
      city: "",
      sateId: 1,
      countryId: 1,
      zip: "",
      phone1: "",
      email: "",
      mobile1: "",
      fax: "",
    },
  },
};

export default sampleResidentCommon;
