import { withMultiRegionComponent } from "@/shared/wrappers/withMultiRegion";
import React from "react";

function FacilityQueueHeaderCommon({ intl, children }) {
  return (
    <tr>
      <th> {intl.formatMessage({ id: "FACILITY.FACILITY_NAME" })}</th>
      <th>
        {intl.formatMessage({
          id: "FACILITY.OWNERSHIP_HEADQUARTERS",
        })}
        <br />{" "}
        {intl.formatMessage({
          id: "FACILITY.PURCHASING_GORUP_CHAIN",
        })}
      </th>
      <th>
        {intl.formatMessage({
          id: "FACILITY.CLINICIAN_NAME",
        })}
      </th>
      <th>
        {intl.formatMessage({
          id: "FACILITY.DATA_TABLE.RESIDENTS_WOUNDS",
        })}
      </th>
      {children}
    </tr>
  );
}

function FacilityQueueHeaderUKBR({ intl }) {
  return (
    <FacilityQueueHeaderCommon intl={intl}>
      <th>
        {intl.formatMessage({
          id: "FACILITY.ACTIONS",
        })}
      </th>
    </FacilityQueueHeaderCommon>
  );
}

const FacilityQueueHeaderByRegion = {
  UK: FacilityQueueHeaderUKBR,
  BR: FacilityQueueHeaderUKBR,
  US: FacilityQueueHeaderCommon,
};

const FacilityQueueHeaderMultiRegion = withMultiRegionComponent(
  FacilityQueueHeaderByRegion
);

export default FacilityQueueHeaderMultiRegion;
