import sampleResidentCommon from "./sampleResidentCommon";

const sampleResidentUS = {
  ...sampleResidentCommon,
  allergicToCollagen: false,
  allergicToTape: false,
  allergicToIodine: false,
};

export default sampleResidentUS;
