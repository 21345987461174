import { withMultiRegionFunction } from "../wrappers/withMultiRegion";

const getPhoneNumberUK = (field) => {
  return (field !== null && field !== "") ? field.replace(/[^0-9]/g, "").substr(0, 11) : null;
};

const getPhoneNumberUS = (field) => {
  return (field !== null && field !== "") ? field.replace(/[^0-9]/g, "").substr(0, 10) : null;
};

const getPhoneNumberBR = (field) => {
  return (field !== null && field !== "") ? field.replace(/[^0-9]/g, "").substr(0, 11) : null;
};

const getPhoneNumberByRegion = {
  BR: getPhoneNumberBR,
  US: getPhoneNumberUS,
  UK: getPhoneNumberUK,
};

const getPhoneNumber = withMultiRegionFunction(getPhoneNumberByRegion);

export default getPhoneNumber;
