import { withMultiRegionFunction } from "@/shared/wrappers/withMultiRegion";
import formatHeaderDateBR from "./formatHeaderDateBR.fn";
import formatHeaderDateUK from "./formatHeaderDateUK.fn";
import formatHeaderDateUS from "./formatHeaderDateUS.fn";

const ssnRegions = {
  BR: formatHeaderDateBR,
  UK: formatHeaderDateUK,
  US: formatHeaderDateUS,
};

const formatHeaderDateRegion = withMultiRegionFunction(ssnRegions);

export default function formatHeaderDate(day, month) {
    return formatHeaderDateRegion(day, month);
}
