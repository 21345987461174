export default function formatSSNUS(value) {
    if (value !== null) {
        value = value.replace(/\D/g, "");
        value = value.replace(/^(\d{3})/, "$1-");
        value = value.replace(/-(\d{2})/, "-$1-");
        value = value.replace(/(\d)-(\d{4}).*/, "$1-$2");
    }
  
  return value;
}
