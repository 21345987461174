import React from "react";
import * as Api from "@/app/General/api";
import * as Common from "@/app/General/common";
import { withMultiRegionFunction } from "@/shared/wrappers/withMultiRegion";

const upsertResidentWoundWithoutUpsertSalesOrder = (payload) => {
  Api.postRequest(Api.URI_UpsertResidentWound, payload.state.Resident)
    .then((res) => {
      if (res.data.statusCode == 200) {
        if (res.data.success) {
          Common.showSuccessAlert(
            payload.intl.formatMessage({
              id: "NEW_WOUND_ASSESSMENT.UPDATE_SUCCESSFULLY",
            }),
            "success",
            payload.intl.formatMessage({ id: "NEW_WOUND_ASSESSMENT.SUCCESS" }),
            3000
          );
          payload.props.close();
        }
      }
    })
    .catch(function(error) {
      Common.LogError(error, "Resident", "upsertResidentWound");
    })
    .then(() => {});
};

const upserResidentWoundUS = (payload) => {
  let obj = payload.state.Resident;
  obj.wound.WoundSkinAndUlcerTreatmentIds =
    payload.state.selectedWoundSkinAndUlcerTreatmentIds;

  if (obj.wound.fkDebridementTypeId == "") {
    obj.wound.fkDebridementTypeId = null;
  }
  if (obj.wound.debridementDate == "") {
    obj.wound.debridementDate = null;
  }
  payload.setState({ IsLoading: false });
  Api.postRequest(Api.URI_UpsertResidentWound, obj)
    .then((res) => {
      if (res.data.statusCode == 200) {
        if (res.data.result != null) {
          if (payload.props.isEdit) {
            Common.showSuccessAlert(
              payload.intl.formatMessage({
                id: "NEW_WOUND_ASSESSMENT.UPDATE_SUCCESSFULLY",
              }),
              "success",
              payload.intl.formatMessage({
                id: "NEW_WOUND_ASSESSMENT.SUCCESS",
              }),
              3000
            );
            payload.props.close();
          } else {
            payload.setProductSelectionDetailToSend(res.data.result, obj);
          }
        }
      }
      payload.setState({ IsLoading: true });
    })
    .catch((ex) => {
      Common.showSuccessAlertAutoClose(
        payload.intl.formatMessage({
          id: "NEW_WOUND_ASSESSMENT.ERROR_OCCURED",
        }),
        "warning",
        payload.intl.formatMessage({ id: "NEW_WOUND_ASSESSMENT.FAILED" }),
        3000
      );
      payload.setState({ IsLoading: true });
    });
};

export  function UpsertResidentWound(payload) {
    debugger;
  const upsertResidentWoundCountries = {
    UK: upsertResidentWoundWithoutUpsertSalesOrder,
    BR: upsertResidentWoundWithoutUpsertSalesOrder,
    US: upserResidentWoundUS,
  };

  withMultiRegionFunction(upsertResidentWoundCountries)(payload);
}
