const groupingHashMap = {
    "Pressure/Mucosal Membrane PI/Medical Device-Related PI": [
      "Pressure",
      "Medical Device PI",
      "Mucosal MembranePI",
    ],
    "Diabetic/Neuropathic": ["Diabetic"],
    Arterial: ["Arterial"],
    Venous: ["Venous"],
    "Mixed Vascular": ["Mixed Vascular"],
    Surgical: ["Surgical"],
    "Skin Trauma": ["Skin Injury"],
    Fistula: ["Fistula"],
    MASD: ["MASD"],
    "Kennedy Terminal Ulcer": ["Kennedy Terminal Ulcer"],
    Other: ["Other", "Non-Pressure"],
  };
  const requiredFieldsForAlternativeShippingAddress = [
    "addressLine1",
    "city",
    "stateId",
    "zip",
    "countryId",
  ];
  export { groupingHashMap,requiredFieldsForAlternativeShippingAddress };
  