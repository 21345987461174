import { withMultiRegionProperties } from "@/shared/wrappers/withMultiRegion";

import menuConfigBR from "./menuConfigBR";
import menuConfigUS from "./menuConfigUS";
import menuConfigUK from "./menuConfigUK";

const menuConfigMultiRegion = {
  US: menuConfigUS,
  UK: menuConfigUK,
  BR: menuConfigBR,
};

export default withMultiRegionProperties(menuConfigMultiRegion);
