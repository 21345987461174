import sampleResidentCommon from "./sampleResidentCommon";

const sampleResidentBR = {
  ...sampleResidentCommon,
  allergicToCollagen: false,
  allergicToTape: false,
  allergicToIodine: false,
  gpAddress: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateId: null,
    zip: "",
  }
};

export default sampleResidentBR;
