import React from "react";

import * as Common from "@/app/General/common";
import { Button } from "react-bootstrap";
import AsyncSelect from "@/shared/components/atoms/AsyncSelect";
import { injectIntl } from "react-intl";
import moment from "moment";
import FastcareInputToolkit from "@/shared/toolkits/fastcareInputToolkit";
import InsuranceWrapper from "../../insurance";

function CreateResidentFormInputUS({
  intl,
  state,
  setState,
  insuranceSelectionRef,
  setOptionsUI,
  setPrimaryPhysicianInputValue,
  loadPrimaryPhysicians,
  setPrimaryPhysician,
  handleHospiceChange,
  handleECPChange,
  handleMedAChange,
  handleMedBChange,
  handleMedicateOnlyChange,
  setSingingPhysicianInputValue,
  loadSingingPhysicians,
  setSingingPhysician,
  selectedResidentInsurance,
  onChangeHospicePt,
  thisReference,
  getCountryList,
  ...props
}) {
  const { placeholderSSN, patternSSN, formatSSN } = FastcareInputToolkit.ssn;

  return (
    <>
      {state.isLoading && (
        <>
          <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
            <legend className="text-primary fw-500">
              {intl.formatMessage({
                id: "NEWRESIDENT.BASIC_INFORMATION",
              })}
            </legend>
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="Status">
                    {intl.formatMessage({
                      id: "RESIDENT.STATUS",
                    })}
                  </label>
                  <select
                    className="form-control form-control-sm"
                    id="fkResidentStatusId"
                    name="fkResidentStatusId"
                    disabled={state.Resident.id == null ? true : false}
                    required
                    value={Common.replaceNullWithString(
                      state.Resident.fkResidentStatusId
                    )}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  >
                    <option></option>
                    {setOptionsUI(state.residentStatus)}
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="firstName">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.FIRST_NAME",
                    })}
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="form-control form-control-sm"
                    name="firstName"
                    defaultValue={state.Resident.firstName}
                    required
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="lastName">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.LAST_NAME",
                    })}
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="form-control form-control-sm"
                    name="lastName"
                    defaultValue={state.Resident.lastName}
                    required
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="gender">
                    {intl.formatMessage({
                      id: "RESIDENT.GENDER",
                    })}
                  </label>
                  <select
                    className="form-control form-control-sm"
                    id="gender"
                    name="gender"
                    value={Common.replaceNullWithString(state.Resident.gender)}
                    required
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  >
                    <option></option>
                    {setOptionsUI(state.gender)}
                  </select>
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group">
                  <label className="form-label" htmlFor="doB">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.DOB",
                    })}
                  </label>
                  <input
                    type="date"
                    id="doB"
                    className="form-control form-control-sm"
                    name="doB"
                    min="1900-01-01"
                    max={Common.getInputStringDateUsingMoment(moment())}
                    required
                    defaultValue={
                      state.Resident.doB != null
                        ? Common.getInputStringDate(
                            new Date(state.Resident.doB)
                          )
                        : ""
                    }
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group">
                  <label className="form-label" htmlFor="ssn">
                    {intl.formatMessage({
                      id: "RESIDENT.SSN",
                    })}
                  </label>
                  <input
                    type="text"
                    id="ssn"
                    placeholder={placeholderSSN}
                    className="form-control form-control-sm"
                    name="ssn"
                    pattern={patternSSN}
                    value={formatSSN(state.Resident.ssn)}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group">
                  <label className="form-label" htmlFor="lastName">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.MEDICARE",
                    })}
                  </label>
                  <input
                    type="text"
                    id="medicare"
                    className="form-control form-control-sm"
                    name="medicare"
                    defaultValue={state.Resident.medicare}
                    onChange={(e) => {
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      );

                      if (insuranceSelectionRef.current) {
                        insuranceSelectionRef.current.addMedicareInsurance(
                          e.target.value
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="unit">
                    {intl.formatMessage({
                      id: "RESIDENTDETAILS.UNIT",
                    })}
                  </label>
                  <input
                    type="text"
                    id="unit"
                    className="form-control form-control-sm"
                    name="unit"
                    defaultValue={Common.replaceNullWithString(
                      state.Resident.unit
                    )}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <div className="form-group">
                  <label className="form-label" htmlFor="room">
                    {intl.formatMessage({
                      id: "RESIDENTDETAILS.ROOM",
                    })}
                  </label>
                  <input
                    type="text"
                    id="room"
                    className="form-control form-control-sm"
                    name="room"
                    defaultValue={Common.replaceNullWithString(
                      state.Resident.room
                    )}
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group">
                  <label className="form-label" htmlFor="admissionDate">
                    {intl.formatMessage({
                      id: "RESIDENTDETAILS.CURRENT_ADMISSION_DATE",
                    })}
                  </label>
                  <input
                    type="date"
                    id="admissionDate"
                    className="form-control form-control-sm"
                    name="admissionDate"
                    min="1900-01-01"
                    max={Common.getInputStringDateUsingMoment(moment())}
                    required
                    defaultValue={
                      state.Resident.admissionDate != null
                        ? Common.getInputStringDate(
                            new Date(state.Resident.admissionDate)
                          )
                        : ""
                    }
                    onChange={(e) =>
                      Common.updateInputValueInState(
                        state.Resident,
                        e,
                        thisReference,
                        state
                      )
                    }
                  />
                </div>
              </div>

              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group">
                  <label className="form-label" htmlFor="gender">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.PRIMARY_PHYSICIAN",
                    })}
                  </label>
                  <AsyncSelect
                    name="primaryPhysicianName"
                    noOptionsMessage={() =>
                      state.primaryPhysiciansInputValue.length >= 3 ? (
                        <Button
                          variant="success"
                          className="mr-5 px-10"
                          size="sm"
                          onClick={() =>
                            setState({
                              physicianModal: true,
                              isPrimaryModal: true,
                            })
                          }
                        >
                          {intl.formatMessage({
                            id: "NEWRESIDENT.NEWPHYSICIAN.NEW_BUTTON",
                          })}
                        </Button>
                      ) : (
                        intl.formatMessage({ id: "INPUT_SELECT.NO_OPTIONS" })
                      )
                    }
                    inputValue={state.primaryPhysiciansInputValue}
                    onInputChange={setPrimaryPhysicianInputValue}
                    value={state.primaryPhysician}
                    onChange={(obj) => {
                      setPrimaryPhysician(obj);
                    }}
                    placeholder={intl.formatMessage({
                      id: "SELECT",
                    })}
                    loadOptions={loadPrimaryPhysicians}
                    isClearable
                  />
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group">
                  <label className="form-label" htmlFor="State">
                    {intl.formatMessage({
                      id: "NEWRESIDENT.NPI",
                    })}
                  </label>
                  <input
                    id="primaryPhysicianNpi"
                    name="primaryPhysicianNpi"
                    disabled
                    value={Common.replaceNullWithString(
                      state?.Resident?.primaryPhysicianNpi
                    )}
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              {/* } */}
              <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="onHospice"
                      name="onHospice"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.onHospice,
                        false
                      )}
                      onChange={(e) => handleHospiceChange(e)}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.HOSPICE",
                    })}
                  </label>
                </div>
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="allergicToSulpha"
                      name="isEcp"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.isEcp,
                        false
                      )}
                      onChange={(e) => handleECPChange(e)}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ECP",
                    })}
                  </label>
                </div>
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="allergicToSulpha"
                      name="currentlyMedA"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.currentlyMedA,
                        false
                      )}
                      onChange={(e) => handleMedAChange(e)}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.MED_SKILLED",
                    })}
                  </label>
                </div>
              </div>

              <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="allergicToSulpha"
                      name="isMedB"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.isMedB,
                        false
                      )}
                      onChange={(e) => handleMedBChange(e)}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.MED_B",
                    })}
                  </label>
                </div>
              </div>

              <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="allergicToSulpha"
                      name="isMedicaidOnly"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.isMedicaidOnly,
                        false
                      )}
                      onChange={(e) => handleMedicateOnlyChange(e)}
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.MEDICAID_ONLY",
                    })}
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="allergicToSulpha"
                      name="allergicToSulpha"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.allergicToSulpha,
                        false
                      )}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_SULFA",
                    })}
                  </label>
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="allergicToCollagen"
                      checked={Common.replaceNullWithString(
                        state.Resident.allergicToCollagen,
                        false
                      )}
                      id="allergicToCollagen"
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_COLLAGEN",
                    })}
                  </label>
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="allergicToTape"
                      checked={Common.replaceNullWithString(
                        state.Resident.allergicToTape,
                        false
                      )}
                      id="allergicToTape"
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_TAPE",
                    })}
                  </label>
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="allergicToIodine"
                      id="allergicToIodine"
                      checked={Common.replaceNullWithString(
                        state.Resident.allergicToIodine,
                        false
                      )}
                      className="m-1"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_IODINE",
                    })}
                  </label>
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="allergicToLatex"
                      name="allergicToLatex"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.allergicToLatex,
                        false
                      )}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_LATEX",
                    })}
                  </label>
                </div>
              </div>

              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="m-1"
                      id="allergicToHoney"
                      name="allergicToHoney"
                      defaultChecked={Common.replaceNullWithString(
                        state.Resident.allergicToHoney,
                        false
                      )}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          state.Resident,
                          e,
                          thisReference,
                          state
                        )
                      }
                    />
                    <span className="mr-3" />
                    {intl.formatMessage({
                      id: "NEWRESIDENT.ALLERGIC_TO_HONEY",
                    })}
                  </label>
                </div>
              </div>
            </div>
          </fieldset>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                <legend className="text-primary fw-500">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.ADDRESS_INFORMATION",
                  })}
                </legend>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div className="form-group mt-0 mt-sm-3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="isHospicePatient"
                          id="isHospicePatient"
                          checked={Common.replaceNullWithString(
                            state.Resident.isHospicePatient,
                            false
                          )}
                          className="m-1"
                          onChange={(e) => onChangeHospicePt(e)}
                        />
                        <span className="mr-3" />
                        {intl.formatMessage({
                          id: "NEWRESIDENT.ALTERNATIVE_SHIPPING_ADDRESS",
                        })}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">
                        {intl.formatMessage({
                          id: "NEWRESIDENT.ADDRESS_LINE_1",
                        })}
                      </label>
                      <input
                        id="addressLine1"
                        name="addressLine1"
                        disabled={!state.Resident.isHospicePatient}
                        value={Common.replaceNullWithString(
                          state.Resident.shippingAddress.addressLine1
                        )}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            state.Resident.shippingAddress,
                            e,
                            thisReference,
                            state
                          )
                        }
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="addressLine2">
                        {intl.formatMessage({
                          id: "NEWRESIDENT.ADDRESS_LINE_2",
                        })}
                      </label>
                      <input
                        id="addressLine2"
                        name="addressLine2"
                        disabled={!state.Resident.isHospicePatient}
                        value={Common.replaceNullWithString(
                          state.Resident.shippingAddress.addressLine2
                        )}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            state.Resident.shippingAddress,
                            e,
                            thisReference,
                            state
                          )
                        }
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="city">
                        {intl.formatMessage({
                          id: "NEWRESIDENT.CITY",
                        })}
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        disabled={!state.Resident.isHospicePatient}
                        value={Common.replaceNullWithString(
                          state.Resident.shippingAddress.city
                        )}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            state.Resident.shippingAddress,
                            e,
                            thisReference,
                            state
                          )
                        }
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">
                        {intl.formatMessage({
                          id: "NEWRESIDENT.STATE",
                        })}
                      </label>
                      <select
                        className="form-control form-control-sm"
                        name="stateId"
                        disabled={!state.Resident.isHospicePatient}
                        value={Common.replaceNullWithString(
                          state.Resident.shippingAddress.stateId
                        )}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            state.Resident.shippingAddress,
                            e,
                            thisReference,
                            state
                          )
                        }
                      >
                        <option></option>
                        {setOptionsUI(state.states)}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="zip">
                        {intl.formatMessage({
                          id: "NEWRESIDENT.POSTAL_CODE",
                        })}
                      </label>
                      <input
                        type="text"
                        id="zip"
                        name="zip"
                        disabled={!state.Resident.isHospicePatient}
                        value={Common.replaceNullWithString(
                          state.Resident.shippingAddress.zip
                        )}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            state.Resident.shippingAddress,
                            e,
                            thisReference,
                            state
                          )
                        }
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="countryId">
                        {intl.formatMessage({
                          id: "NEWRESIDENT.COUNTRY",
                        })}
                      </label>
                      <select
                        className="form-control form-control-sm"
                        name="countryId"
                        disabled={!state.Resident.isHospicePatient}
                        value={Common.replaceNullWithString(
                          state.Resident.shippingAddress.countryId
                        )}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            state.Resident.shippingAddress,
                            e,
                            thisReference,
                            state
                          )
                        }
                        id="countryId"
                      >
                        <option></option>
                        {setOptionsUI(state.countryList)}
                      </select>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </>
      )}
      <fieldset
        className={
          "p-2 mb-2 bg-light border border-dark rounded " +
          (state.isLoading ? "" : "d-none")
        }
      >
        <legend className="text-primary fw-500">
          {intl.formatMessage({
            id: "NEWRESIDENT.INSURANCE_INFORMATION",
          })}
        </legend>

        <InsuranceWrapper
          intl={intl}
          ref={insuranceSelectionRef}
          residentID={state.Resident.id}
          residentInsuranceList={state.residentInsuranceList}
          selectInsurance={selectedResidentInsurance}
        />
      </fieldset>
      {state.isLoading && (
        <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
          <legend className="text-primary fw-500">
            {intl.formatMessage({
              id: "NEWRESIDENT.PHYSICIAN_SIGNATURE_INFORMATION",
            })}
          </legend>
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div className="row">
                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                  <div className="form-group">
                    <label className="form-label" htmlFor="gender">
                      {intl.formatMessage({
                        id: "NEWRESIDENT.PHYSICIAN_SIGNING_ORDER",
                      })}
                    </label>
                    <AsyncSelect
                      name="organizationID"
                      placeholder={intl.formatMessage({
                        id: "SELECT",
                      })}
                      noOptionsMessage={() =>
                        state.singingphysiciansInputValue.length >= 3 ? (
                          <Button
                            variant="success"
                            className="mr-5 px-10"
                            size="sm"
                            onClick={() =>
                              setState({
                                physicianModal: true,
                                isPrimaryModal: false,
                              })
                            }
                          >
                            {intl.formatMessage({
                              id: "NEWRESIDENT.NEWPHYSICIAN.NEW_BUTTON",
                            })}
                          </Button>
                        ) : (
                          ""
                        )
                      }
                      inputValue={state.singingphysiciansInputValue}
                      onInputChange={setSingingPhysicianInputValue}
                      value={state.singingPhysician}
                      isDisabled={!!props.facility.singingForAllCmns}
                      onChange={(obj) => {
                        setSingingPhysician(obj);
                      }}
                      loadOptions={loadSingingPhysicians}
                      isClearable
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
              <div className="form-group">
                <label className="form-label" htmlFor="State">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.NPI",
                  })}
                </label>
                <input
                  id="signingPhysicianNpi"
                  name="signingPhysicianNpi"
                  disabled
                  value={Common.replaceNullWithString(
                    state?.Resident?.signingPhysicianNpi
                  )}
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div className="form-group">
                <label className="form-label" htmlFor="LastName">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.CONTACT_PERSON_FOR_PHYSICIAN",
                  })}
                </label>
                <input
                  type="text"
                  id="ContactPerson"
                  name="signingContactPerson"
                  value={Common.replaceNullWithString(
                    state.Resident.signingContactPerson
                  )}
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      state.Resident,
                      e,
                      thisReference,
                      state
                    )
                  }
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div className="form-group">
                <label className="form-label" htmlFor="FirstName">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.NUMBER_FAX",
                  })}
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="signingPhysicianPhone"
                  value={Common.getFormattedPhoneNum(
                    Common.replaceNullWithString(
                      state.Resident.signingPhysicianPhone
                    )
                  )}
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      state.Resident,
                      e,
                      thisReference,
                      state
                    )
                  }
                  className="form-control form-control-sm"
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div className="form-group">
                <label className="form-label" htmlFor="NPI">
                  {intl.formatMessage({
                    id: "NEWRESIDENT.EMAIL_ADDRESS",
                  })}
                </label>
                <input
                  type="text"
                  id="NPI"
                  name="signingPhysicianEmail"
                  value={Common.replaceNullWithString(
                    state.Resident.signingPhysicianEmail
                  )}
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      state.Resident,
                      e,
                      thisReference,
                      state
                    )
                  }
                  className="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </>
  );
}

export default injectIntl(CreateResidentFormInputUS);
