import { withMultiRegionProperties } from "@/shared/wrappers/withMultiRegion";
import roleToPageMap from "./currentOrDefaultPageProps";

function getCurrentPageWithMultiRegion(user) {
  const currentOrDefaultPagePropsByRegion = withMultiRegionProperties(
    roleToPageMap
  );

  return (
    user.roles.roleList
      .map((role) => currentOrDefaultPagePropsByRegion[role.roleName])
      .find((page) => page !== undefined) ||
    currentOrDefaultPagePropsByRegion.default
  );
}

export default getCurrentPageWithMultiRegion;
