import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { facilityWoundReportStyles } from './reportDocument';


const woundDetailSecondRowStyle = StyleSheet.create({
    detailSecondRow: {
        backgroundColor: '#FFF',
        color: '#3F4254',
        fontSize: 8,
        borderBottom: '1px solid #EBEDF3',
        minHeight: 35,
        alignItems: 'center',
    },
    secondRowCol: {
        borderRight: '1px solid #EBEDF3',
        height: '100%',
        padding: 5
    },
    badge: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: 5,
        padding: 5,
        color: '#ffffff',
        fontFamily: 'Helvetica-Bold'
    },
    badgeOpen: {
        backgroundColor: '#1BC5BD',
    },
    badgeClosed: {
        backgroundColor: '#F64E60'
    },
    treatmentDiv: {
        display: 'flex',
        justifyContent: 'center'
    }
});

const WoundDetailSecondRow = ({detail, intl}) => {
    const styles = facilityWoundReportStyles;

    const messageId = detail?.isOpen ? "REPORTS.WOUNDS.OPEN" :  "REPORTS.WOUNDS.CLOSED";
    const badgeStyle = detail?.isOpen ? [woundDetailSecondRowStyle.badgeOpen, woundDetailSecondRowStyle.badge] : [woundDetailSecondRowStyle.badgeClosed, woundDetailSecondRowStyle.badge];

    return (
        <View style={[styles.tableRow, woundDetailSecondRowStyle.detailSecondRow]}>
            <View style={[styles.w8, woundDetailSecondRowStyle.secondRowCol, {flexDirection: 'row', display: 'flex', alignItems: 'center'}]}>
                <View style={badgeStyle}>
                    <Text>{intl.formatMessage({id: messageId})}</Text>
                </View>
                <Text style={{width: 'auto', opacity: 0}}></Text>
            </View>
            <View style={[styles.w42, woundDetailSecondRowStyle.secondRowCol]}>
                <Text>
                    {intl.formatMessage({id: 'REPORTS.WOUNDS.SURROUNDING_SKIN'})}: {detail.wounSurroundingSkinName}
                </Text>
                <Text>
                    {intl.formatMessage({id: 'REPORTS.WOUNDS.WOUND_EDGES'})}: {detail.woundEdgesName}
                </Text>
            </View>
            <View style={[styles.w50, woundDetailSecondRowStyle.secondRowCol, woundDetailSecondRowStyle.treatmentDiv]}>
                <Text>
                    {detail.treatment}
                </Text>
            </View>
        </View>
    )
}

export default WoundDetailSecondRow;