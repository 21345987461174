import * as React from "react";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { injectIntl } from "react-intl";

export class ResidentStatus extends React.Component {
    constructor(props) {
        super(props);
        this.intl = this.props.intl;
        this.state = {
            isLoading:false,
            residentStatusList:[],
            resident:props.resident
        }
    }
    
    componentDidMount() {
        this.getResidentStatus();
    }

    getResidentStatus = () => {
        debugger
        Api.getRequestById(Api.Uri_GetLookupValuesByFkId, Common.LookupsFK.Fk_ResidentStatusID)
            .then((res) => {
                debugger
                if (res.data.statusCode == 200) {
                    if (res.data.result != null) {
                        if (res.data.result.length > 0) {
                            this.setState({ residentStatusList: res.data.result });
                        }
                    }
                }
            })
            .catch((ex) => {

                Common.LogError(ex, "Resident", "getDropDownValueApiCall");

            });
    }

    setOptionsUI = (options) => {
        return options.map((option) => (
            <option key={option.key} value={option.key}>
                {option.value}
            </option>
        ));
    }
    
    onSubmit = () => {
        var msg = this.intl.formatMessage({ id: "BUTTON.SUCCESS.YOU_WANT_TO" }) +
            (this.state.resident.fkResidentStatusId === Common.ResidentStatusID.open ?
                this.props.intl.formatMessage({ id: "CONFIRMATION.OPEN" }) :
                this.props.intl.formatMessage({ id: "CONFIRMATION.CHANGE" }));
        Common.showConfirmation(msg, this.props.intl.formatMessage({ id: "BUTTON.ARE_YOU_SURE" }), this.updateActiveInActive, null, this.state.resident)
    };

    updateActiveInActive = (obj) => {
        if (obj) {
            Api.postRequest(Api.apiUrls.updateResidentStatus, obj).then((res) => {
                if (res.data.success) {
                    this.props.close();
                    Common.showSuccessAlertAutoClose(this.intl.formatMessage({ id: "SUCCESS.RESIDENT_STATUS_UPDATED" }), "success", this.intl.formatMessage({ id: "BUTTON.SUCCESS.TITLE" }), 3000)
                } else {
                    Common.showSuccessAlert(res.data.message, "warning", this.props.intl.formatMessage({ id: "WARNING.TITLE" }), 3000);
                }
            });
        }
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Status">
                                {this.props.intl.formatMessage({ id: "RESIDENT_STATUS.TITLE" })}
                            </label>
                            <select
                                className="form-control form-control-sm"
                                id="fkResidentStatusId"
                                name="fkResidentStatusId"
                                required
                                value={Common.replaceNullWithString(
                                    this.state.resident.fkResidentStatusId
                                )}
                                onChange={(e) =>
                                    Common.updateInputValueInState(
                                        this.state.resident,
                                        e,
                                        this,
                                        this.state
                                    )
                                }
                            >
                                {this.setOptionsUI(this.state.residentStatusList)}
                            </select>

                        </div>

                    </div>
                </div>


            </>
        )

    }
}
export default injectIntl(ResidentStatus, { forwardRef: true });