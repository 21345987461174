import ICDReport from '../modal/reports/icdreport';
import React, { useEffect, useRef, useState } from 'react';
import { Animated } from "react-animated-css";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import { Card, CardBody } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import PopupModal from "../modal/popupmodal";
import WoundProducts from "../products/woundProducts";
import NewWound from './newwound';
import WoundEvaluationAndAssessmentTab from './woundEvaluationAndAssesmentTab';

import Loader from "react-loader-spinner";
import CheckPermission from "../../General/CheckPermission";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import NewWoundAssessment from './newWoundAssessment';
import AddSalesOrderMsg from './salesOrerMsg';
import WoundInformationChangeReason from './woundInformationChangeReason';
import SkipWoundReason from './skipWoundReason';
import ViewWound from './viewWound';


import SelectableContext from "react-bootstrap/SelectableContext";
import moment from 'moment';

import uuid from 'node-uuid'
import { common } from '@material-ui/core/colors';
import { injectIntl } from 'react-intl';
var woundAssessmentModel = null;
var woundDetailsModal = null;
var editWoundmodal = null;
var woundInfoModal, newWoundInfo = null;
var lastOrdermodal = null;
var woundProductModal = null;
var salesOrderModal = null;
var skipOrderModal = null;



export function WorkQueueWounds(props) {
    // const ICDReportModalRef= useRef(null);
    const { intl } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isReorder, setIsReOrder] = useState(false);
    const [woundModelShow, setWoundModelShow] = useState(false);


    const [isPreviosOrderPress, setPreviosOrderPress] = useState(false);



    const [woundDetail, setSelectedDetail] = useState(null);
    const [salesOrderId, setSalesOrderId] = useState(null);



    // const [isProductShow, setIsProductShow] = useState(false);



    const { user } = useSelector((state) => state.auth);

    var _sampleWoundObj = {
        fkResidentId: props.Resident.id,
        residentName: Common.replaceNullWithString(props.Resident.firstName) + " " + Common.replaceNullWithString(props.Resident.lastName),
        facilityName: Common.replaceNullWithString(props.Resident.facilityName),
        roomUnit: Common.replaceNullWithString(props.Resident.room) + "/" + Common.replaceNullWithString(props.Resident.unit),
        evaluatedBy: Common.replaceNullWithString(user.firstName) + " " + Common.replaceNullWithString(user.lastName),
        physicanName: Common.replaceNullWithString(props.Resident.primaryPhysicianName),
        createdBy: user.userID,
        modifiedBy: user.userID,
        btPatientBrightreeID: props.Resident.btPatientBrightreeID,
        fkLastWoundDetailId: null,
        isWoundInfoChange: false,
        changeReason: '',
        fkSalesOrderId: 0,

        wound:
        {
            id: 0,
            woundDetailId: 0,
            // evaluationDate: '',
            // dateFirstObserved: '',
            // fkAcquisitionId: null,
            fkWoundLocationId: null,
            woundLocation: "",
            fkWoundTypeId: null,
            woundDescription: '',
            fkWoundThicknessId: null,
            //fkWoundSevereTissueTypeId: null,
            fkWoundLateralityId: null,
            woundLength: '',
            woundWidth: '',
            woundDepth: '',
            isUtd: false,
            tunneling: false,
            tunnelingDepth: null,
            tunnelingClockPosition: '',
            undermining: false,
            underminingDepth: null,
            underminingClockPosition: null,
            toUnderminingClockPosition: null,
            icdcode: "",
            fkExudateAmountId: null,
            fkExudateTypeId: null,
            exudateColor: '',
            fkDebridementTypeId: null,
            fkSelectedDebridementTypeId: null,

            debridementDate: null,
            surgicalDate: null,
            odor: false,
            Pain: false,
            painMedicationOrdered: false,
            sloughPercentage: null,
            necroticPercentage: null,
            //FkWoundCleanseId :'',
            cleanseDescription: null,
            fkDressingChangeFrequencyId: 8,
            //fkPrimaryDressingId :null,
            //FkAdditionalPrimaryDressingId :'',
            otherAdditionalPrimaryDressing: '',
            //fkSecondaryDressingId :null,
            //FkAdditionalSecondaryDressingId :'',
            otherAdditionalSecondaryDressing: '',
            treatment: '',
            comments: '',
            thicknessIdForBackStagging: null,
            orientationLeftRight: null,
            orientationUpperLower: null,
            orientationInnerOuter: null,
            orientationAnteriorPosterior: null,
            orientationMedialLateral: null,
            orientationDorsalDistalProximal: null,
            isDocumentation: false,
            isPartailOrder: false,
            bedDescPercentage: 0,
            fkWoundColorId: null
            // FkWoundcareProductTypeId :'',

        }
    };
    const [showAddWoundComponent, setAddWoundComponent] = useState(null);
    const [showLastOrderWoundComponent, setLastOrderWoundComponent] = useState(null);
    const [showWoundInfoChangeModel, setShowWoundInfoChangeModel] = useState(null);


    const [residentWoundListUI, setResidentWoundListUI] = useState([]);
    useEffect(() => {
        getResidentWound();
        //setResidentListTemp();
        //Common.LoadDataTable("facilityList")
    }, []);

    //#region Set Resident List
    const getResidentWound = async () => {
        setIsLoading(false);
        var processStatusID = 0;
        var isNoOrder = 2;
        if (props.isBillingQueue) {
            isNoOrder = 0;
            if (props.isProcess) {
                processStatusID = Common.ProcessStatus.brighreeQueueID;
            } else {
                if (props.isBTQueue) {
                    processStatusID = Common.ProcessStatus.brighreeQueueID;

                } else {
                    processStatusID = Common.ProcessStatus.billingQueueID;

                }
            }
        } else {
            if (props.isProcess) {
                processStatusID = Common.ProcessStatus.billingQueueID;
            } else {
                processStatusID = Common.ProcessStatus.workQueueID;
            }


        }
        var isPartailOrder = props.isPartailOrder ? 1 : 2;
        var partailOrderBillingDate = props.isPartailOrder ? props.facility.cycleDate : '';
        await Api.getRequestQry(Api.URI_GetResidentWoundByStatusID, "?residentId=" + props.Resident.id + "&processStatusID=" + processStatusID +
            "&isOpen=" + 1 + "&isPartailOrder=" + isPartailOrder +
            "&partailOrderDate=" + partailOrderBillingDate + "&isNoOrder=" + isNoOrder)

            .then((res) => {
                setIsLoading(false);
                if (res.data.statusCode == 200) {
                    if (res.data.result != null) {
                        if (res.data.result.length > 0) {
                            setResidentWoundListUI([]);
                            setResidentWoundList(res.data.result);

                        } else {
                            setResidentWoundListUI([]);
                        }
                        setIsLoading(true);
                    }
                }

                //Common.LoadDataTable("woundList"+props.Resident.id)
            }).catch((ex) => {
                Common.LogError(ex, "ResidentNote", "Get");

            });
    }
    const handleResidentWoundStatus = (obj) => {
        let tempObj = JSON.parse(JSON.stringify(obj));
        tempObj.wound.isOpen = tempObj.wound.isOpen == true ? false : true;
        updateResidentWoundStatus(tempObj);
    };
    const updateResidentWoundStatus = (obj) => {
        var msg = "You want to " + (obj.wound.isOpen ? "Open" : "Close") + " Wound.";
        Common.showConfirmation(msg, "", updateWoundStatus, null, obj)

    };
    const updateWoundStatus = (obj) => {
        if (obj) {
            setIsLoading(false)
            Api.postRequest(Api.apiUrls.updateResidentWoundStatus, obj).then((res) => {
                if (res.data.success) {
                    getResidentWound();
                    setIsLoading(true)
                    Common.showSuccessAlertAutoClose("Wound Status Updated Successfully", "success", "Success!", 3000)
                } else {
                    setIsLoading(true)
                    Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
                }
            });
        }
    }

    const handleResidentPartailWoundStatus = (obj) => {

        let tempObj = JSON.parse(JSON.stringify(obj));
        tempObj.wound.isPartailOrder = tempObj.wound.isPartailOrder == true ? false : true;

        if (Common.GetDateString(props.facility.cycleDate) != "01-01-01") {
            var date = Common.getDifferenceInDays(new Date(), new Date(props.facility.cycleDate));

            if (obj.wound.isPartailOrder == false && date < 7) {
                Common.showConfirmation("You are within 7 days of the Primary Ship Date. Do you want to initiate this partial order.",
                    "", updatePartailWoundStatus, null, tempObj)
                return false
            }
        }
        var msg = "You want to " + (tempObj.wound.isPartailOrder ? "initiate" : "Remove") + " this patial order.";
        Common.showConfirmation(msg, "", updatePartailWoundStatus, null, tempObj)

    };
    const handleResidentOrderNowWoundStatus = (obj) => {

        let tempObj = JSON.parse(JSON.stringify(obj));
        tempObj.wound.isOrderNow = tempObj.wound.isOrderNow == true || tempObj.wound.isPartailOrder == true ? false : true;

        if (Common.GetDateString(props.facility.cycleDate) != "01-01-01") {
            var date = Common.getDifferenceInDays(new Date(), new Date(props.facility.cycleDate));

            if (tempObj.wound.isOrderNow && date < 7) {
                Common.showConfirmation("You are within 7 days of the Primary ship Date. Do you want to process this Order now.",
                    "", updateOrderNowWoundStatus, null, tempObj)
                return false
            }
        }
        var msg = "You want to " + (tempObj.wound.isOrderNow ? "mark" : "Cancel") + " this.";
        Common.showConfirmation(msg, "", updateOrderNowWoundStatus, null, tempObj)

    };
    const updatePartailWoundStatus = (obj) => {
        if (obj) {
            Api.postRequest(Api.apiUrls.updateResidentWoundPartailStatus, obj).then((res) => {
                if (res.data.success) {
                    getResidentWound();
                    Common.showSuccessAlertAutoClose("Wound Status Updated Successfully", "success", "Success!", 3000)
                } else {
                    Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
                }
            });
        }
    }
    const updateOrderNowWoundStatus = (obj) => {
        if (obj) {
            Api.postRequest(Api.apiUrls.updateResidentWoundOrderNowStatus, obj).then((res) => {
                if (res.data.success) {
                    getResidentWound();
                    Common.showSuccessAlertAutoClose("Wound Status Updated Successfully", "success", "Success!", 3000)
                } else {
                    Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
                }
            });
        }
    }
    const handleResidentNoWoundStatus = (obj) => {

        let tempObj = JSON.parse(JSON.stringify(obj));
        if (props.isBillingQueue == true) {
            tempObj.wound.isNoOrder = tempObj.wound.isProcessorNoOrder == true ? false : true;
        } else {
            tempObj.wound.isNoOrder = tempObj.wound.isNoOrder == true ? false : true;
        }

        tempObj.wound.isProcessorNoOrder = props.isBillingQueue == true ? true : false;
        var msg = "You want to " + (tempObj.wound.isNoOrder ? "no order." : "order products.");
        Common.showConfirmation(msg, "", updateNoWoundStatus, null, tempObj)

    };
    const updateNoWoundStatus = (obj) => {
        if (obj.wound.isNoOrder == true) {
            setSelectedDetail(obj)
            skipOrderModal.showModal()
        } else {
            if (obj) {
                Api.postRequest(Api.apiUrls.updateResidentWoundNoOrderStatus, obj).then((res) => {
                    if (res.data.success) {
                        getResidentWound();
                        Common.showSuccessAlertAutoClose("Wound Status Updated Successfully", "success", "Success!", 3000)
                    } else {
                        Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
                    }
                });
            }
        }
    }
    const handleSalesOrderMsgModel = async (salesOrderId) => {
        setSalesOrderId(salesOrderId);
        salesOrderModal.showModal()
    }
    const setResidentWoundList = (list) => {
        var cylceDays = 2;

        let rows = [];
        try {
            var partialWoundCount = 0;
            var isReorder = false;
            for (var i = 0; i < list.length; i++) {
                const obj = list[i];
                if (obj.wound.evaluationDate != "" && obj.wound.evaluationDate != null) {
                    cylceDays = Common.getDifferenceInDays(new Date(), new Date(obj.wound.evaluationDate))
                }
                if (obj.wound.fkPartialProcessStatusId == Common.ProcessStatus.workQueueID) {
                    partialWoundCount = partialWoundCount + 1;
                }
                if (obj.wound.isReorder && isReorder == false) {
                    isReorder = true;
                }
                rows.push(
                    <React.Fragment key={i}>
                        <tr key={uuid()}>
                            <th>{intl.formatMessage({ id: "RESIDENT_WOUNDS.DATA_TABLE.NUM" })}</th>
                            <th>{intl.formatMessage({ id: "RESIDENT_WOUNDS.DATA_TABLE.BODY_REGION_WOUND_LOCATION" })}</th>
                            <th>{obj.wound.fkWoundTypeId == Common.WoundType.pressure ? intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.STAGE_THICKNESS" })
                                : obj.wound.fkWoundTypeId == Common.WoundType.Surgical || obj.wound.fkWoundTypeId == Common.WoundType.tubeSize ? intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.STAGE_THICKNESS" }) : intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.THICKNESS_TISSUE" })}</th>
                            <th>{intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.ICD10_CODE" })}</th>
                            <th>{intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.WOUND_SIZE" })}</th>
                            <th>{intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.EXUDATE" })}</th>


                            {/* <th>{props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID && obj.wound.isClinicianReviewRequired == true ? "Last Assessment Date" : "Evaluated on"}</th> */}
                            <th>{intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.EVALUATED_ON" })}</th>
                            <th>{intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.EVALUATED_BY" })}</th>
                            {props.facility.canAddAssessment && <th>{intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.LAST_ASSESSMENT_DATE" })}</th>}
                            <th>{intl.formatMessage({ id: "FACILITY.ACTIONS" })}</th>
                        </tr>
                        <tr key={uuid()}>
                            <td className={`justify-content-center`}>
                                <span className={(obj.wound.fkPartialProcessStatusId == Common.ProcessStatus.billingQueueID) && props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID && "badge badge-success"}> <b>
                                    {obj.wound.isPartailOrder && (!props.isBillingQueue ? obj.wound.isNoOrder : obj.wound.isProcessorNoOrder) ? 'PS'
                                        : obj.wound.isOrderNow && (!props.isBillingQueue ? obj.wound.isNoOrder : obj.wound.isProcessorNoOrder) ? '*S'
                                            : obj.wound.isOrderNow && !obj.wound.isNoOrder ? '*'
                                                : obj.wound.isPartailOrder && !obj.wound.isNoOrder ? 'P'
                                                    : !obj.wound.isPartailOrder && (!props.isBillingQueue ? obj.wound.isNoOrder : obj.wound.isProcessorNoOrder) ? 'S' : ''}
                                    {i + 1}</b></span>
                                {isReorder && obj.wound.totalEvaluation < 2 && !obj.wound.isnewAssessment && <span
                                    className={`badge my-auto mx-4 ml-auto badge-info`}
                                >
                                    {intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.NEW" })}
                                </span>}
                                {/* <button className="ml-1 badge badge-pill badge-warning" title={obj.wound.msg} onClick={() => handleSalesOrderMsgModel(obj.wound.saleOrderID)}>Msg</button> */}
                            </td>
                            <td>
                                <button className={
                                    obj.wound.isClinicianReviewRequired ? "btn btn-outline-info" :
                                        !obj.residentIsNoOrder && !obj.wound.isNoOrder && obj.wound.isnewAssessment
                                            && props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID && cylceDays < 28 ? "btn btn-outline-danger" : "btn btn-link"
                                }
                                    //isbillingqueue works just as fine here, but I used comparison by ID to fit with the current formatting, is processor function on seems to be a requirement that doesn't fit in this context, but if it is relevant in the future change && to ||, and the code should still work without issue
                                    disabled={props.facility.fkProcessStatusId == Common.ProcessStatus.billingQueueID}
                                    //disabled={props.isProcessorFunctionOn == false &&
                                    //props.facility.fkProcessStatusId == Common.ProcessStatus.billingQueueID ? true : false}
                                    onClick={() => handleWoundAssessmentModal(obj, false,
                                        ((obj.wound.fkPartialProcessStatusId == Common.ProcessStatus.billingQueueID)
                                            && props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID)
                                            || obj.wound.isOrderNow ? true : false, false)}>

                                    {obj.wound.orientationLeftRightName == null || obj.wound.orientationLeftRightName == "" ? "" : obj.wound.orientationLeftRightName + " - "} {Common.replaceNullWithString(obj.wound.bodyRegion)}{obj.residentWoundLocation != null ? "/" : ""}
                                    {Common.replaceNullWithString(obj.residentWoundLocation)}
                                    {obj.wound.orientation == null || obj.wound.orientation == "" ? "" : " - " + obj.wound.orientation}
                                </button>

                                {/* {(
                                obj.wound.fkPartialProcessStatusId == Common.ProcessStatus.billingQueueID) && props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID
                                && <span
                                    className={`badge my-auto mx-4 ml-auto badge-success`}
                                >
                                    Processed
                                </span>} */}
                            </td>
                            {/* <td>{Common.replaceNullWithString(obj.wound.woundLateralityName)}</td> */}
                            <td>
                                {/* {Common.replaceNullWithString(obj.woundType)} */}

                                {Common.replaceNullWithString(obj.wound.woundThicknessName)}
                                {obj.wound.fkWoundTypeId == Common.WoundType.pressure ||
                                    obj.wound.fkWoundTypeId == Common.WoundType.Surgical
                                    || obj.wound.fkWoundTypeId == Common.WoundType.tubeSize ? ' '
                                    : obj.wound.woundThicknessName != null && obj.wound.woundSevereTissueName != null ?


                                        props.facility.fkProcessStatusId != Common.ProcessStatus.billingQueueID ?
                                            " - " + Common.replaceNullWithString(obj.wound.woundSevereTissueName)

                                            :
                                            <CheckPermission
                                                userRole={[RBAC.Role_Gentell_Administrator, RBAC.Role_Gentell_IntakeProcessor,
                                                RBAC.Role_Gentell_SeniorBiller]}

                                                yes={() => (
                                                    " - " + Common.replaceNullWithString(obj.wound.woundSevereTissueDec)
                                                )}
                                                no={() =>
                                                    " - " + Common.replaceNullWithString(obj.wound.woundSevereTissueName)
                                                }
                                            />
                                        : " "}

                            </td>
                            <td>{Common.replaceNullWithString(obj.wound.icdcode) != '' ? obj.wound.icdcode : 'N/A'}</td>
                            <td >  {obj.wound.woundLength + " x " + obj.wound.woundWidth + " x " + obj.wound.woundDepth + " " + (obj.wound.isUtd == true ? "U" : "")}</td>
                            <td >  {Common.replaceNullWithString(obj.wound.exudateAmountName)}</td>


                            <td>{((props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID && obj.wound.isClinicianReviewRequired == true) || obj.wound.isDocumentation) ? 'N/A' : Common.zipCodeToTimeZone(obj.wound.evaluationDate, props.facility.zip)}</td>
                            <td>{((props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID && obj.wound.isClinicianReviewRequired == true) || obj.wound.isDocumentation) ? 'N/A' : obj.evaluatedBy}</td>
                            {/* <td>{props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID && obj.wound.isClinicianReviewRequired == true ? Common.zipCodeToTimeZone(obj.wound.evaluationDate,props.facility.zip) : 'N/A'}</td> */}
                            {
                                props.facility.canAddAssessment ?
                                    ((props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID && obj.wound.isClinicianReviewRequired) || obj.wound.isDocumentation) ? <td>{Common.zipCodeToTimeZone(obj.wound.evaluationDate, props.facility.zip)}</td> : <td>N/A</td>
                                    : null
                            }

                            <td align="center">
                                <>
                                    {(props.isProcessorFunctionOn || !props.isBillingQueue) &&
                                        <SelectableContext.Provider value={false}>
                                            <ButtonToolbar className="justify-content-center">{ }
                                                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                                                    {[DropdownButton].map((DropdownType, idx) => (
                                                        <DropdownType
                                                            size="xs"
                                                            variant="primary"
                                                            title={intl.formatMessage({ id: "FACILITY.ACTIONS" })}
                                                            id={`dropdown-button-drop-${idx}`}
                                                            key={uuid()}
                                                        >
                                                            {!props.isReleasedFacility &&
                                                                <>
                                                                    {
                                                                        obj.wound.isnewAssessment && props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID ?
                                                                            //  <Dropdown.Item eventKey="0" onClick={() =>{ setSelectedDetail(obj);  woundDetailsModal.showModal()}}>View Details</Dropdown.Item>
                                                                            <CheckPermission
                                                                                permission={UserActions.Create}
                                                                                module={ModulesName.Module_Wound}
                                                                                yes={() => (
                                                                                    <Dropdown.Item onClick={e => handleNewWoundAssessmentModal(obj, e)}> New Evaluation</Dropdown.Item>
                                                                                )}
                                                                                no={() => null}
                                                                            />

                                                                            :
                                                                            <>
                                                                                <Dropdown.Item eventKey="102" onClick={() => { handleWoundAssessmentModal(obj, true, false, true) }}>View Order</Dropdown.Item>
                                                                            </>



                                                                    }

                                                                    {

                                                                        obj.wound.isReorder ?
                                                                            <Dropdown.Item eventKey="102" onClick={() => { getLastWoundDetailByWoundId(obj) }}>View Previous Order</Dropdown.Item>
                                                                            : null
                                                                    }
                                                                    {props.facility.canAddAssessment &&
                                                                        <Dropdown.Item eventKey="0" onClick={() => handleLatestDocumentModal(obj)}>View Previous Assessment</Dropdown.Item>
                                                                    }
                                                                    {!props.isBillingQueue &&
                                                                        <Dropdown.Item eventKey="8" className="text-danger" onClick={() => handleResidentWoundStatus(obj)}>{obj.wound.isOpen ? 'Close Wound' : 'Open Wound'}</Dropdown.Item>
                                                                    }

                                                                    {(props.facility.fkProcessStatusId == Common.ProcessStatus.billingQueueID ||
                                                                        props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID) &&
                                                                        <CheckPermission
                                                                            userRole={[RBAC.Role_Gentell_SeniorBiller, RBAC.Role_Gentell_IntakeProcessor,
                                                                            RBAC.Role_Gentell_Administrator, RBAC.Role_Gentell_WoundCareSpecialist, RBAC.Role_Gentell_RegionalManager]}

                                                                            yes={() => (
                                                                                <>
                                                                                    <Dropdown.Item eventKey="8"
                                                                                        onClick={() => handleResidentNoWoundStatus(obj)}>{
                                                                                            props.isBillingQueue ?
                                                                                                (obj.wound.isProcessorNoOrder ?
                                                                                                    'Order Products' : 'No Order')
                                                                                                :
                                                                                                (obj.wound.isNoOrder ?
                                                                                                    'Order Products' : 'No Order')

                                                                                        }
                                                                                    </Dropdown.Item>
                                                                                </>
                                                                            )}
                                                                            no={() => null}
                                                                        />
                                                                    }
                                                                </>
                                                            }

                                                            <CheckPermission
                                                                userRole={[
                                                                    RBAC.Role_Gentell_Administrator]}

                                                                yes={() => (
                                                                    <>
                                                                        <Dropdown.Item eventKey="102"
                                                                            disabled={
                                                                                // obj.wound.fkPartialProcessStatusId == Common.ProcessStatus.billingQueueID || 
                                                                                obj.wound.fkPartialProcessStatusId == Common.ProcessStatus.brighreeQueueID
                                                                                    || (props.facility.fkProcessStatusId == Common.ProcessStatus.brighreeQueueID)
                                                                                    || (props.facility.fkPartialProcessStatusId == Common.ProcessStatus.billingQueueID)
                                                                                    ? true : false}
                                                                            onClick={() => { handleWoundInfoChangeModal(obj) }}>
                                                                            Amend Wound Information
                                                                        </Dropdown.Item>
                                                                    </>
                                                                )}
                                                                no={() => null}
                                                            />


                                                            {(!obj.wound.isnewAssessment) &&
                                                                <CheckPermission
                                                                    userRole={[RBAC.Role_Gentell_WoundCareSpecialist,
                                                                    RBAC.Role_Gentell_Administrator,]}

                                                                    yes={() => (
                                                                        <>
                                                                            <Dropdown.Item eventKey="8"
                                                                                disabled={
                                                                                    // obj.wound.fkPartialProcessStatusId == Common.ProcessStatus.billingQueueID || 
                                                                                    obj.wound.fkPartialProcessStatusId == Common.ProcessStatus.brighreeQueueID
                                                                                        || (props.facility.fkProcessStatusId == Common.ProcessStatus.billingQueueID && !props.isPartailOrder)
                                                                                        || obj.wound.isNoOrder ? true : false}
                                                                                onClick={() => handleResidentOrderNowWoundStatus(obj)}>{
                                                                                    obj.wound.isOrderNow || obj.wound.isPartailOrder ?

                                                                                        `Cancel ${obj.wound.isOrderNow ? "Order Now" : " Partial Order"}` : "Order Now"
                                                                                }

                                                                            </Dropdown.Item>
                                                                        </>
                                                                    )}
                                                                    no={() => null}
                                                                />
                                                            }



                                                        </DropdownType>
                                                    ))}
                                                </div>
                                            </ButtonToolbar>
                                        </SelectableContext.Provider>
                                    }

                                </>
                            </td>
                        </tr>
                        {
                            obj.wound.isnewAssessment ?
                                null :
                                // props.isShowProduct || !props.isProcess ?
                                <tr key={uuid()}>
                                    <td colSpan="10">
                                        <Card className="example example-compact">
                                            <CardBody>
                                                <WoundProducts
                                                    ModulesName={ModulesName.Module_MyWorkQueue}
                                                    isProcessorFunctionOn={props.isProcessorFunctionOn == false &&
                                                        props.facility.fkProcessStatusId == Common.ProcessStatus.billingQueueID ? true : false}
                                                    isReorder={obj.wound.isReorder} residentWound={obj} queue={props.queue} />
                                            </CardBody>
                                        </Card>



                                    </td>

                                </tr>

                        }


                    </React.Fragment>
                );
            }
            setResidentWoundListUI(rows);
            setIsLoading(true);
            if (props.setPartialWoundCount) {
                props.setPartialWoundCount(partialWoundCount);
            }




        } catch (error) {
            Common.LogError(error, "WorkqueueWounds", "SetWoundList");
        }
    }

    var componentArray = [];

    const handleNewWoundModal = async (obj) => {
        setIsReOrder(false)
        if (obj != null) {
            await setAddWoundComponent(<NewWound refresh={props.refresh} isEdit={true}
                isReorder={false}
                showProduct={false}
                isReadOnly={false}
                displayProduct={false}
                queue={props.queue || Common.ProcessStatus.workQueueID}
                isWorkQueue={true}
                user={user} resident={obj} close={handleCloseWoundmodalPopup}
                ref={(ref) => {
                    newWoundInfo = ref;
                }}
            />)
        }
        else {
            await setAddWoundComponent(<NewWound isWorkQueue={true} refresh={props.refresh} isEdit={false} isReorder={false}
                showProduct={false}
                queue={props.queue || Common.ProcessStatus.workQueueID}
                isReadOnly={false}
                displayProduct={true}
                user={user} resident={_sampleWoundObj} close={handleCloseWoundmodalPopup} />)
        }
        setWoundModelShow(true)
        editWoundmodal.showModal()
    }
    const handleWoundAssessmentModal = async (obj, isProductShow, isReadOnly, isProductDisplay, isWoundInfoChange = false, changeReason = '') => {
        debugger
        setIsReOrder(obj.wound.isReorder)
        let tempObj = JSON.parse(JSON.stringify(_sampleWoundObj));
        tempObj.wound = obj.wound;
        tempObj.fkSalesOrderId = obj.fkSalesOrderId;


        if (isWoundInfoChange) {
            tempObj.isWoundInfoChange = true;
            tempObj.changeReason = changeReason
        }

        if (isReadOnly) {
            await setLastOrderWoundComponent(
                // <NewWound refresh={props.refresh}
                //     isEdit={true}
                //     isReorder={obj.wound.isReorder}
                //     showProduct={isProductShow}
                //     isReadOnly={isReadOnly}
                //     displayProduct={isProductDisplay}
                //     user={user} resident={tempObj} close={handleCloseLastOrdermodalPopup} />)
                <ViewWound refresh={props.refresh}
                    isEdit={true}
                    queue={props.queue || Common.ProcessStatus.workQueueID}
                    isReorder={obj.wound.isReorder}
                    showProduct={true}
                    isReadOnly={true}
                    displayProduct={true}
                    user={user} resident={tempObj} />)

            lastOrdermodal.showModal()

        } else {
            await setAddWoundComponent(<NewWound isWorkQueue={true} refresh={props.refresh}
                isEdit={true}
                isReorder={obj.wound.isReorder}
                showProduct={isProductShow}
                isReadOnly={isReadOnly}
                queue={props.queue || Common.ProcessStatus.workQueueID}
                displayProduct={isProductDisplay}
                user={user} resident={tempObj} close={handleCloseWoundmodalPopup}
                backPress={props.backPress}
                ref={(ref) => {
                    newWoundInfo = ref;
                }}
            />)
            setWoundModelShow(true)
            editWoundmodal.showModal()
        }



    }
    const handleWoundInfoChangeModal = async (obj) => {

        await setShowWoundInfoChangeModel(<WoundInformationChangeReason
            isDocumentation={false}
            msg={null} woundInfo={obj} close={handleCloseWoundInfoModal} showWound={handleWoundAssessmentModal} />)
        woundInfoModal.showModal()
    }
    const handleCloseWoundInfoModal = () => {
        woundInfoModal.hideModal()
    }
    const getLastWoundDetailByWoundId = async (obj, isGetInfo = false) => {

        // Api.getRequestById(Api.URI_GetResidentWoundByResidentID, props.Resident.id)
        var { data } = await Api.getRequestQry(Api.URI_GetLastWoundDetailByWoundId, "?woundId=" + obj.wound.id)
        // Api.getRequestQry(Api.URI_GetResidentWounURidByStatusID, "?residentId="+props.Resident.id+"&processStatusID=" + processStatusID)

        // .then((res) => {

        if (data.statusCode == 200) {
            if (data.result != null) {
                // setResidentWoundList(res.data.result);
                if (data.result.id > 0) {
                    if (isGetInfo) {
                        return data.result
                    } else {
                        handleWoundAssessmentModal(data.result, true, true, true)
                    }


                } else {
                    if (isGetInfo) {
                        return null
                    } else {
                        Common.showSuccessAlert("Last Wound evaluation is not available", "warning", "Warning!", 3000);

                    }

                }

            } else {
                if (isGetInfo) {
                    return null
                } else {
                    Common.showSuccessAlert("Wound evaluation is not available", "warning", "Warning!", 3000);


                }

            }
        }

        //Common.LoadDataTable("woundList"+props.Resident.id)
        // }).catch((ex) => {
        //     Common.LogError(ex, "ResidentNote", "Get");

        // });
    }
    const handleLatestDocumentModal = async (obj, isGetInfo = false) => {

        var { data } = await Api.getRequestQry(
            Api.URI_GetWoundHistoryByWoundID,
            "?woundId=" + obj.wound.id + "&isLatest=" + 1
        )
        // .then((res) => {
        if (data.statusCode == 200) {
            if (data.result != null) {
                if (data.result.length > 0) {

                    // setAddWoundComponent(<NewWound refresh={props.refresh} isEdit={false} isReorder={false} user={user} resident={res.data.result[0]} close={handleCloseWoundmodalPopup} />)

                    if (isGetInfo == false) {
                        setAddWoundComponent(<NewWoundAssessment queue={props.queue || Common.ProcessStatus.workQueueID} isEdit={false} canView={false} user={user} resident={data.result[0]} close={handleCloseWoundmodalPopup} />)
                        woundAssessmentModel.showModal()
                    } else {
                        return data.result[0]
                    }

                } else {
                    if (isGetInfo == false) {
                        Common.showSuccessAlert("Wound assessment has not been created", "warning", "Warning!", 3000);
                    }
                }

            }
            return null
        }

        //Common.LoadDataTable("woundList"+props.Resident.id)
        // }).catch((ex) => {
        //     Common.LogError(ex, "ResidentWoundHistory", "Get");

        // });

    }
    const handleNewWoundAssessmentModal = async (obj, event) => {
        setIsReOrder(obj.wound.isReorder)
        let tempObj = JSON.parse(JSON.stringify(_sampleWoundObj));
        tempObj.wound.id = obj.wound.id;
        tempObj.wound.fkWoundTypeId = obj.wound.fkWoundTypeId;
        tempObj.wound.fkWoundSevereTissueTypeId = obj.wound.fkWoundSevereTissueTypeId;
        tempObj.wound.fkWoundThicknessId = obj.wound.fkWoundThicknessId;
        tempObj.wound.thicknessIdForBackStagging = obj.wound.thicknessIdForBackStagging;
        tempObj.wound.debridementDate = obj.wound.debridementDate;
        tempObj.wound.fkDebridementTypeId = obj.wound.fkDebridementTypeId;
        tempObj.wound.fkSelectedDebridementTypeId = obj.wound.fkDebridementTypeId;

        tempObj.fkLastWoundDetailId = obj.wound.woundDetailId;

        tempObj.wound.fkBodyRegionId = obj.wound.fkBodyRegionId;
        tempObj.wound.fkWoundLocationId = obj.wound.fkWoundLocationId;
        tempObj.wound.orientationLeftRight = obj.wound.orientationLeftRight;
        tempObj.wound.orientationUpperLower = obj.wound.orientationUpperLower;
        tempObj.wound.orientationInnerOuter = obj.wound.orientationInnerOuter;
        tempObj.wound.orientationAnteriorPosterior = obj.wound.orientationAnteriorPosterior;
        tempObj.wound.orientationMedialLateral = obj.wound.orientationMedialLateral;
        tempObj.wound.orientationDorsalDistalProximal = obj.wound.orientationDorsalDistalProximal;

        var lastEvaluation = null

        if (obj.wound.isReorder) {
            lastEvaluation = await getLastWoundDetailByWoundId(obj, true)
        }
        var lastAssesment = await handleLatestDocumentModal(obj, true)

        await setAddWoundComponent(<WoundEvaluationAndAssessmentTab refresh={props.refresh} isEdit={false}
            isReorder={obj.wound.isReorder}
            showProduct={false}
            isReadOnly={false}
            displayProduct={true}
            lastEvaluation={lastEvaluation}
            lastAssesment={lastAssesment}
            user={user} resident={tempObj} close={handleCloseWoundmodalPopup}
            ref={(ref) => {
                newWoundInfo = ref;
            }} />)
        setWoundModelShow(true)
        editWoundmodal.showModal()
    }
    const handleCloseWoundmodalPopup = (orderApproval) => {
        try {
            setWoundModelShow(false)
            editWoundmodal.hideModal(true);
            lastOrdermodal.hideModal();
            if (orderApproval) {
                props.setOrderApproval(true)
            }
            props.refresh();

        } catch (error) {
            Common.LogError(error, "WorkqueueWounds", "handleNewWoundModal");
        }

    }
    const handleCloseLastOrdermodalPopup = () => {
        try {
            lastOrdermodal.hideModal();

        } catch (error) {
            Common.LogError(error, "WorkqueueWounds", "handleNewWoundModal");
        }

    }
    return (
        <>

            <PopupModal title="Wound Assessment" dialogClassName="modal-95w"
                ref={ref => { woundAssessmentModel = ref; }}> {showAddWoundComponent} 
            </PopupModal>

            {/* Last order View */}
            <PopupModal
                title="Wound Evaluation" dialogClassName="modal-95w" ref={ref => { lastOrdermodal = ref; }}> {showLastOrderWoundComponent} 
            </PopupModal>
            
            <PopupModal
                title="Amend Wound Information Reason"
                showButton={false}
                ref={(ref) => {
                    woundInfoModal = ref;
                }}
            >
                {" "}

                {showWoundInfoChangeModel}
                {" "}
            </PopupModal>

            <PopupModal
                title="Sales Order Message"
                showButton={false}
                ref={(ref) => {
                    salesOrderModal = ref;
                }}
            >
                {" "}
                <AddSalesOrderMsg msg={null} salesOrderId={salesOrderId} />
                {" "}

            </PopupModal>

            <PopupModal
                title="No Order Reason"
                ref={(ref) => {
                    skipOrderModal = ref;
                }}
            >
                {" "}
                <SkipWoundReason
                    refresh={() => { getResidentWound(); skipOrderModal.hideModal() }}
                    residentWound={woundDetail} />
                {" "}

            </PopupModal>


            <Animated animationIn="fadeIn" animationInDuration={2000} >
                <div className="d-flex justify-content-end">
                    {/* <button className="btn btn-success  btn-xs mr-5 mb-3" size="sm" onClick={() => handleNewWoundModal(null)}>Add New Wound Assessment</button> */}

                    {!props.isBillingQueue && <CheckPermission
                        permission={UserActions.Create}
                        module={ModulesName.Module_Wound}
                        yes={() => (
                            <button className="btn btn-primary btn-xs mr-5 mb-3" size="sm" onClick={() => handleNewWoundModal(null)}>
                                {intl.formatMessage({ id: "RESIDENT_WOUNDS.ADD_NEW_WOUND" })}
                            </button>

                        )}
                        no={() => null}
                    />
                    }
                    {/* <button className="btn btn-info btn-xs mr-5 mb-3" size="sm" onClick={() =>{ getResidentWound(true)}}>Show Product</button> */}

                </div>
                <table key={uuid()} className="table table-striped table-hover table-sm table-bordered" id={"woundList" + props.Resident.id}>
                    {/* <thead>


                    </thead> */}
                    <tbody key={uuid()}>


                        {(isLoading == true) ? residentWoundListUI :
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td> <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={50}
                                    width={50}
                                /></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        }
                        {/* {rows} */}

                    </tbody>
                </table>
                <PopupModal
                    onClose={(ref) => {
                        getResidentWound();
                        ref.setState({ show: false });
                        // Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning',
                        //     () => { ref.setState({ show: false }); }, () => { }, null, "Exit Without Saving");
                    }}
                    title={<div className="card-title">

                        <span>
                            <h3 className="card-label">{intl.formatMessage({ id: "WORKQUEUE_WOUNDS.WOUND_EVALUATION_WOUND_FIRST_NAME" })}{" "}
                                {Common.replaceNullWithString(props.Resident.firstName)},{" "}{intl.formatMessage({ id: "WORKQUEUE_WOUNDS.LAST_NAME" })}: {Common.replaceNullWithString(props.Resident.lastName)}</h3>
                        </span>
                    </div>}
                    dialogClassName="modal-95w" ref={ref => { editWoundmodal = ref; }}>
                    {showAddWoundComponent} 
                </PopupModal>
            </Animated>

        </>
    );
}





export default injectIntl(WorkQueueWounds);