/* eslint-disable no-restricted-imports */
import { FormControlLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import AsyncSelect from "@/shared/components/atoms/AsyncSelect";
import {
  Alert,
  Badge,
  Button,
  ButtonToolbar,
  Dropdown,
  DropdownButton,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { showDecisionAlert, showWarningAlert } from "../../../index";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../_metronic/_partials/controls/Card";

import RoutePaths from "../../constants/routepaths";
import * as Api from "../../General/api";
import CheckPermission from "../../General/CheckPermission";
import { useSelector } from "react-redux";
import * as Common from "../../General/common";
import RBAC, {
  ModulesName,
  UserActions,
} from "../../General/roleModuleActions";
import Consultantslistmodal from "../modal/facilitymodals/consultantslistmodal";
import Contactslistmodal from "../modal/facilitymodals/contactslistmodal";
import FacilityDetail from "../modal/facilitymodals/facilitydetail";
import Facilityorderslist from "../modal/facilitymodals/facilityorderslist";
import PopupModal from "../modal/popupmodal";
import Qapireport from "../modal/reports/qapireport";
import EvalForms from "../modal/reports/showevals";
import Trendreport from "../modal/reports/trendreport";
import Woundreport from "../modal/reports/woundreport";
import NewFacilityModal from "../modal/_newfacilitymodal";
import PlaceOrder from "../orders/placeorder";
import Physician from "../physician/physician";
import WorkQueueWoundOrders from "./workQueueWoundOrders";
import FacilityResident from "../resident/facilityresident";
import BillingResident from "../resident/billingresident";
import AddFacility from "../facility/newFacility";
import EditFacility from "../facility/editFacility";
import AddFacilityMsg from "../facility/facilityMsg";
import FacilityPdfReport from "../reports/facilityPdfReport";
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import { injectIntl } from "react-intl";
import WoundOrderApprovel from "../resident/woundOrderApprovel";
import SetBillingDate from "../facility/setBillingDate";
import { debounce } from "lodash";
import { ArrowDownward, ChevronLeftRounded } from "@material-ui/icons";
import ModalLoading from "./Modal/ModalLoading";
import { blobContainerFacilityReportName } from "../../constants/endpoints";
var editFacilityModal = null;
var editFacility,
  authorizeModal,
  contactFacility = null;
var setBillingDateModal = null;
var facilitymsgModal = null;
var facilityReportModal = null;


export function WorkQueue(props) {
  const { intl } = props;

  //#region resident for facility
  const [sort, setSort] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("CreatedDate");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [shipDateId, setShipDate] = useState(2);

  const [changeDetect, setChangeDetect] = useState(false);

  const { user } = useSelector((state) => state.auth);
  debugger;
  const [showResident, setShowResident] = useState(false);
  const [showBillingResident, setShowBillingResident] = useState(false);
  const [isPartailOrder, setPartailOrder] = useState(false);
  const [FacilityObj, setFacilityObj] = useState("");
  //#endregion

  //#region facility orders list modal
  var FacilityDetailModal = null;
  //#endregion
  const [workQueueListUI, setWorkQueueListUI] = useState([]);
  const [
    workQueueReleasedFacilityListUI,
    setWorkQueueReleasedFacilityListUI,
  ] = useState([]);
  const [Facility, setFacilityForEdit] = useState({});
  const [billingDateDetail, setBillingDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //#region facility orders list modal
  const [showModalFacilityOrders, setShowFacilityOrders] = useState(false);
  const [isProcessorFunctionOn, setIsProcessorFunctionOn] = useState(true);
  const handleCloseFacilityOrders = () => setShowFacilityOrders(false);
  const handleShowFacilityOrders = () => setShowFacilityOrders(true);
  //#endregion

  //#region new facility modal
  const [showModalNewFacility, setShowNewFacility] = useState(false);
  const [isReleasedFacilityShow, setReleasedFacility] = useState(false);
  const [isTrainer, setIsTrainerRole] = useState(false);
  const [clinicianInputValue, setClinicianInputValue] = useState("");
  const [clinician, setClinician] = useState("");

  const [processStatusId, setProcessStatusId] = useState(
    Common.ProcessStatus.billingQueueID
  );
  const [selectedFacilityDate, setselectedFacilityDate] = useState(null);
  const handleCloseNewFacility = () => {
    addFacilityModal.hideModal(true);

    // setBillingDateModal.hideModal();
    getAllFacility();
  };
  const handleShowNewFacility = () => addFacilityModal.showModal();
  //#endregion

  //#region Consultants list modal
  const [showModalConsultantList, setShowConsultantList] = useState(false);
  const [FacilityID, setFacilityID] = useState("");
  const handleCloseConsultantList = () => setShowConsultantList(false);
  // const handleShowConsultantList = () => setShowConsultantList(true);
  const handleShowConsultantList = (_facilityID) => {
    setFacilityID(_facilityID);
    console.log();
    setTimeout(() => {
      setShowConsultantList(true);
    }, 200);
  };

  //#endregion

  //#region Contacts list modal
  const [showModalContacttList, setShowContactList] = useState(false);
  const [partailOrderFacilityListUI, setPartailOrderFacilityListUI] = useState(
    []
  );
  const handleCloseContactList = () => {
    debugger
    if(changeDetect){
      Common.showConfirmation(
        intl.formatMessage({ id: "WORKQUEUE_WOUNDS.ARE_YOU_SURE_YOU_WANT_TO_CLOSE_WITHOUT_SAVING_THE_DATA" }),
        "",
        closeContactList,
        null,
        false
      );    }else{
      closeContactList(false)
    }
  };
  const closeContactList = (close) => {
    setChangeDetect(false);
    setShowContactList(close);
  };

  const handleShowContactList = (_facilityID) => {
    setFacilityID(_facilityID);
    setTimeout(() => {
      setShowContactList(true);
    }, 200);
  };
  //#endregion

  //#region Qapi report modal
  const [showModalQapiReport, setShowQapiReport] = useState(false);

  const handleCloseQapiReport = () => setShowQapiReport(false);
  const handleShowQapiReport = () => setShowQapiReport(true);
  //#endregion

  //#region Trend report modal
  const [showModalTrendReport, setShowTrendReport] = useState(false);

  const handleCloseTrendReport = () => setShowTrendReport(false);
  const handleShowTrendReport = () => setShowTrendReport(true);
  //#endregion

  //#region Wound report modal
  const [showModalWoundReport, setShowWoundReport] = useState(false);

  const handleCloseWoundReport = () => setShowWoundReport(false);
  const handleShowWoundReport = () => setShowWoundReport(true);
  //#endregion

  //#region Eval froms report modal
  const [showModalEvalForms, setShowEvalForms] = useState(false);

  const handleCloseEvalForms = () => setShowEvalForms(false);
  const handleShowEvalForms = () => setShowEvalForms(true);
  //#endregion

  //#region Place Order Modal
  var placeOrderModal = null;
  //#endregion

  //#region Physician modal
  var physicianModal = null;
  //#endregion
  var addFacilityModal = null;
  var addFacility = null;



  const displayWarningAlert = () => showWarningAlert(intl.formatMessage({ id: "TEXT.COMING_SOON" }), "Alert");
  const displayDecisionAlert = () =>
    showDecisionAlert(
      function () { },
      function () { },
      intl.formatMessage({ id: "BUTTON.ARE_YOU_SURE" }),
      intl.formatMessage({ id: "WORKQUEUE.YOU_WANT_TO_DEACTIVATE" })
    );
  useEffect(() => {
    getAllFacility();

    //setResidentListTemp();
    //Common.LoadDataTable("facilityList")
  }, [
    currentPage,
    rowsPerPage,
    sortColumn,
    sort,
    shipDateId,
    clinician,
    isTrainer,
  ]);

  //#region Set Resident List
  const getAllFacility = (search = "") => {
    setReleasedFacility(false);
    setIsLoading(true);
    var parm =
      "?pageIndex=" +
      currentPage +
      "&pageSize=" +
      rowsPerPage +
      "&sortColumn=" +
      sortColumn +
      "&shipDateId=" +
      shipDateId +
      "&sortDirection=" +
      sort +
      "&search=" +
      search +
      (clinician?.value > 0 ? "&clinicianId=" + clinician?.value : "");
    Api.getRequestQry(Api.Uri_GetWorkqueueFacilityList, parm)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode == 200) {
          setIsProcessorFunctionOn(res.data.message == "1" ? true : false);
          if (res.data.result != null) {
            if (res.data.result.totalRows > 0) {
              setPageCount(res.data.result.totalRows);
              setFacilityList(res.data.result.items);
            } else {
              setWorkQueueListUI([]);
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Workqueue", "getAllFacility");
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  const editFacilityByID = (id) => {
    setFacilityID(id);
    getFacilityByID(id);
  };
  const getFacilityByID = (id) => {
    Api.getRequestById(Api.Uri_GetFacilityByID, id)
      .then((response) => {
        setFacilityForEdit(response.data.result);
        editFacilityModal.showModal();
      })
      .catch(function (error) {
        Common.LogError(error, "Facility", "getFacilityByID");
      });
  };
  const getAllReleasedFacility = () => {
    Api.getRequestQry(
      Api.Uri_GetBillingFacility,
      "?queueId=" +
      Common.ProcessStatus.billingQueueID +
      "&serachParm=&orgID=" +
      0
      // + "&isInBillingQueue=" + 0
    )
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setReleasedFacilityList(res.data.result);
            } else {
              setWorkQueueReleasedFacilityListUI([]);
              // getAllBTReleasedFacility([]);
              getAllBillingReleasedPartailOrderFacilityList([]);
            }
          }
        }
      })
      .catch((ex) => { })
      .then(() => {
        setIsLoading(false);
        // Common.LoadDataTable("facilityBillingList");
      });
  };

  const setReleasedFacilityList = (list) => {
    debugger;
    var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const OrganizationName = obj.organizationName;
      const facilityID = obj.id;

      const chain = Common.replaceNullWithString(obj.chain);
      const facilityName = Common.replaceNullWithString(obj.facilityName);
      // var cycleDate = new Date(obj.cycleDate);
      const facilityType = Common.replaceNullWithString(obj.facilityType);
      rows.push(
        <tr key={i.toString()}>
          <td></td>

          <td align="left" className="customStyleAccorion">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                handleShowReleasedFacility(false);
                setShowBillingResident(true);
                setFacilityObj(obj);
                setPartailOrder(false);
              }}
            >
              {facilityName}
            </button>
          </td>
          {/* <td>{Common.GetDateString(obj.billingDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.billingDate)}</td> */}
          {/* <td>{Common.GetDateString(obj.billingQueueReleaseDate) == "01-01-1970" ? "N/A" : Common.GetDateString(obj.billingQueueReleaseDate)+ (obj.isSecondaryCycle ? " (S)" : "")}</td> */}
          <td>
            {Common.GetDateString(obj.cycleDate) == "01-01-01"
              ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" })
              : Common.GetDateString(obj.cycleDate) +
              (obj.isSecondaryCycle ? " (S)" : "")}
          </td>          {/* <td>{Common.GetDateString(obj.secondaryCycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.secondaryCycleDate)}</td> */}


          {/* <td>{Common.GetDateString(cycleDate)}</td> */}



          <td>{Common.replaceNullOrEmptyWithString(obj.organizationName, "")}
          {obj.organizationName == null && obj.chain == null ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" }) : ""}
          {obj.organizationName != null && obj.chain != null ? "/" : ""}
            {Common.replaceNullOrEmptyWithString(obj.chain, "")}</td>

            <td align="center">
            {obj.isEcp == true ? (
              <Badge variant="success">{intl.formatMessage({ id: "TEXT.YES" })}</Badge>
            ) : (
              <Badge variant="primary">{intl.formatMessage({ id: "TEXT.NO" })}</Badge>
            )}{" "}
          </td>

          <td>{obj.totalResident + "/" + obj.totalWound}</td>
          <td>            <div className="mr-md-4 mr-0 mb-1 mb-md-0">
              {obj.isInBillingQueue == true
                ? isProcessorFunctionOn
                  ? intl.formatMessage({ id: "TEXT.PRINT" })
                  : intl.formatMessage({ id: "TEXT.PROCESSOR" })
                : intl.formatMessage({ id: "TEXT.RELEASED" })}
            </div></td>
          <td align="center">
            <>
              <ButtonToolbar className="justify-content-center">
                {/* <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {obj.isBTOn == false ? "Manual" : "Processor Queue"}
                </div> */}
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      size="xs"
                      variant="primary"
                      title={intl.formatMessage({ id: "DATA_TABLE.ACTIONS" })}
                      id={`dropdown-button-drop-${idx}`}
                      key={idx}
                    >
                      {!obj.isInBillingQueue && 
                        <>
                          <Dropdown.Item eventKey="11" onClick={() => 
                            handleReleaseFacility(facilityID,obj.cycleDate)}>  {intl.formatMessage({ id: "WORKQUEUE.RELEASE_FACILITY" })}{" "}
                          </Dropdown.Item>
                
                        </>
                      }
                      <Dropdown.Item eventKey="11" onClick={() => handleUnReleaseFacility(facilityID)}>                        {intl.formatMessage({ id: "WORKQUEUE.UNRELEASE_FACILITY" })}{" "}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="12" onClick={() => handleFacilityReportModel(obj, Common.ProcessStatus.billingQueueID, false)}>                        {intl.formatMessage({ id: "WORKQUEUE.PRINT_FACILITY" })}
                      </Dropdown.Item>
                      {/* <CheckPermission
                        userRole={[RBAC.Role_Gentell_WoundCareSpecialist]}

                        yes={() => (
                          <>
                            <Dropdown.Item eventKey="0" onClick={() => handleBillingDate(obj, true)}>Request Billing Date</Dropdown.Item>
                          </>
                        )}
                        no={() => null}
                      /> */}

                      <Dropdown.Divider />
                    </DropdownType>
                  ))}
                </div>
                {/* <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {obj.isBTOn == false ? "Manual" : "Processor Queue"}
                </div> */}
              </ButtonToolbar>
            </>
          </td>
          {/* <td align="center">
            <Badge variant="success">Active</Badge>
          </td> */}
        </tr>
      );
    }
    // setWorkQueueReleasedFacilityListUI(rows);
    // getAllPartailOrderFacilityList(rows);
    // getAllBTReleasedFacility(rows)
    getAllBillingReleasedPartailOrderFacilityList(rows);
  };

  const getAllBillingReleasedPartailOrderFacilityList = useCallback(
    (regularReleasedFacilityList) => {
      //setIsLoading(true);
      Api.getRequestQry(
        Api.Uri_GetPartailOrderFacilities,
        "?queueId=" + Common.ProcessStatus.billingQueueID
      )
        .then((res) => {
          if (res.data.statusCode == 200) {
            if (res.data.result != null) {
              if (res.data.result.length > 0) {
                seBillingReleasedPartailFacilityList(
                  res.data.result,
                  regularReleasedFacilityList
                );
              } else {
                seBillingReleasedPartailFacilityList(
                  [],
                  regularReleasedFacilityList
                );
              }
            }
          }
        })
        .catch((ex) => { })
        .then(() => {
          setIsLoading(false);
        });
    }
  );
  const seBillingReleasedPartailFacilityList = (
    list,
    regularReleasedFacilityList
  ) => {
    var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const OrganizationName = obj.organizationName;
      const facilityID = obj.id;

      const chain = Common.replaceNullWithString(obj.chain);
      const facilityName = Common.replaceNullWithString(obj.facilityName);
      var dob = new Date(obj.cycleDate);
      const date =
        dob.getMonth() + 1 + "/" + dob.getDate() + "/" + dob.getFullYear();
      const facilityType = Common.replaceNullWithString(obj.facilityType);
      regularReleasedFacilityList.push(
        <tr key={i.toString()}>
          <td>
            {obj.totalWound > 0 && obj.totalOrderNowWound > 0
              ? "P*"
              : obj.totalOrderNowWound > 0
                ? "*"
                : "P"}
          </td>
          <td align="left" className="customStyleAccorion">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                setProcessStatusId(Common.ProcessStatus.billingQueueID);
                setselectedFacilityDate(date);
                handleShowReleasedFacility(false);
                setShowBillingResident(true);
                setFacilityObj(obj);
                setPartailOrder(true);
              }}
            >
              {facilityName}
            </button>
          </td>
          {/* <td>{Common.GetDateString(obj.billingDate) == "01-01-01" ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" }) : Common.GetDateString(obj.billingDate)}</td> */}

          {/* <td>{Common.GetDateString(obj.cycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.cycleDate)+ (obj.isSecondaryCycle ? " (S)" : "")}</td> */}
          <td>
            {Common.GetDateString(obj.billingDate) == "01-01-01"
              ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" })
              : Common.GetDateString(obj.billingDate) +
              (obj.isSecondaryCycle ? " (S)" : "")}
          </td>          {/* <td>{Common.GetDateString(obj.secondaryCycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.secondaryCycleDate)}</td> */}

          <td>{Common.replaceNullOrEmptyWithString(obj.organizationName, "")}
          {obj.organizationName == null && obj.chain == null ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" }) : ""}
          {obj.organizationName != null && obj.chain != null ? "/" : ""}
            {Common.replaceNullOrEmptyWithString(obj.chain, "")}</td>
            <td align="center">
            {obj.isEcp == true ? (
              <Badge variant="success">
                {intl.formatMessage({ id: "TEXT.YES" })}
              </Badge>
            ) : (
              <Badge variant="primary">
                {intl.formatMessage({ id: "TEXT.NO" })}
              </Badge>
            )}{" "}
          </td>          
          <td>{obj.totalResident + "/" + (obj.totalWound + obj.totalOrderNowWound)}</td>
          <td><div className="mr-md-4 mr-0 mb-1 mb-md-0">
              {obj.isInBillingQueue == true
                ? isProcessorFunctionOn
                  ? intl.formatMessage({ id: "TEXT.PRINT" })
                  : intl.formatMessage({ id: "TEXT.PROCESSOR" })
                : intl.formatMessage({ id: "TEXT.RELEASED" })}
            </div></td>


          <td align="center">
            {obj.isEcp == true ? (
              <Badge variant="success">
                {intl.formatMessage({ id: "TEXT.YES" })}
              </Badge>
            ) : (
              <Badge variant="primary">
                {intl.formatMessage({ id: "TEXT.NO" })}
              </Badge>
            )}{" "}
          </td>
          <td>
            {obj.totalResident +
              "/" +
              (obj.totalWound + obj.totalOrderNowWound)}
          </td>
          <td>
            <div className="mr-md-4 mr-0 mb-1 mb-md-0">
              {obj.isInBillingQueue == true
                ? isProcessorFunctionOn
                  ? intl.formatMessage({ id: "TEXT.PRINT" })
                  : intl.formatMessage({ id: "TEXT.PROCESSOR" })
                : intl.formatMessage({ id: "TEXT.RELEASED" })}
            </div>
          </td>

          <td align="center">
            <>
              <ButtonToolbar className="justify-content-center">
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      size="xs"
                      variant="primary"
                      title={intl.formatMessage({ id: "DATA_TABLE.ACTIONS" })}
                      id={`dropdown-button-drop-${idx}`}
                      key={idx}
                    >
                      {!obj.isInBillingQueue && <>
                        <Dropdown.Item eventKey="121" onClick={() => releasePartailFacility(obj)}>                            {intl.formatMessage({ id: "WORKQUEUE.RELEASE_FACILITY" })}
                        </Dropdown.Item>
                      </>}
                      <Dropdown.Item eventKey="12" onClick={() => handleFacilityReportModel(obj, Common.ProcessStatus.brighreeQueueID, false)}>                        {intl.formatMessage({ id: "FACILITY.PRINT_FACILITY" })}
                      </Dropdown.Item>



                      <Dropdown.Divider />
                    </DropdownType>
                  ))}
                </div>
                {/* <div className="mr-md-4 mr-0 mb-1 mb-md-0">
      {obj.isBTOn == false ? "Manual" : "Processor Queue"}
    </div> */}
              </ButtonToolbar>
            </>
          </td>
        </tr>
      );
    }
    // setWorkQueueReleasedFacilityListUI(regularReleasedFacilityList);
    getAllBTReleasedFacility(regularReleasedFacilityList);

    // Common.LoadDataTable("facilityBillingList");
  };

  //#region  BT Released List
  const getAllBTReleasedFacility = (billQueueFacilityList) => {
    Api.getRequestQry(
      Api.Uri_GetBillingFacility,
      "?queueId=" +
      Common.ProcessStatus.brighreeQueueID +
      "&serachParm=&orgID=" +
      0
    )
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setBTReleasedFacilityList(res.data.result, billQueueFacilityList);
            } else {
              // setBillingQueueReleasedFacilityListUI([]);
              getAllBTReleasedPartailOrderFacilityList(billQueueFacilityList);
            }
          }
        }
      })
      .catch((ex) => { })
      .then(() => {
        setIsLoading(false);
        // Common.LoadDataTable("facilityBillingList");
      });
  };
  const setBTReleasedFacilityList = (list, billQueueFacilityList) => {
    // var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const OrganizationName = obj.organizationName;
      const facilityID = obj.id;

      const chain = Common.replaceNullWithString(obj.chain);
      const facilityName = Common.replaceNullWithString(obj.facilityName);
      var cycleDate = new Date(obj.cycleDate);
      const facilityType = Common.replaceNullWithString(obj.facilityType);
      billQueueFacilityList.push(
        <tr key={i.toString()}>
          <td></td>
          <td>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                setFacilityObj(obj);

                // setselectedFacilityDate(date);
                handleShowReleasedFacility(false);
                setShowBillingResident(true);
                setFacilityObj(obj);
                setPartailOrder(false);
              }}
            >
              {facilityName}
            </button>
          </td>
          {/* <td>{Common.GetDateString(obj.billingDate) == "01-01-01" ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" }) : Common.GetDateString(obj.billingDate)}</td> */}
          {/* <td>{Common.getInputStringDate(cycleDate)}</td> */}
          <td>{Common.getInputStringDate(cycleDate)}</td>

          {/* <td>{Common.GetDateString(cycleDate)}</td> */}
          {/* <td>{Common.GetDateString(obj.btqueueReleasedDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.btqueueReleasedDate)}</td> */}
          <td>{Common.replaceNullOrEmptyWithString(obj.organizationName, "")}
          {obj.organizationName == null && obj.chain == null ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" }) : ""}
          {obj.organizationName != null && obj.chain != null ? "/" : ""}
            {Common.replaceNullOrEmptyWithString(obj.chain, "")}</td>

            <td align="center">
            {obj.isEcp == true ? (
              <Badge variant="success">
                {intl.formatMessage({ id: "TEXT.YES" })}
              </Badge>
            ) : (
              <Badge variant="primary">
                {intl.formatMessage({ id: "TEXT.NO" })}
              </Badge>
            )}{" "}
          </td>

          <td>{obj.totalResident + "/" + obj.totalWound}</td>
          <td>
            <div className="mr-md-4 mr-0 mb-1 mb-md-0">{"BT"}</div>
          </td>
          <td align="center">
            <>
              <ButtonToolbar className="justify-content-center">
                {/* <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {obj.isManualProcess == true ? "Manual" : "Brightree Queue"}
                </div> */}
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      size="xs"
                      variant="primary"
                      title={intl.formatMessage({ id: "DATA_TABLE.ACTIONS" })}
                      id={`dropdown-button-drop-${idx}`}
                      key={idx}
                    >
                      <Dropdown.Item eventKey="12" onClick={() => handleFacilityReportModel(obj, Common.ProcessStatus.brighreeQueueID, false)}>                        {intl.formatMessage({ id: "WORKQUEUE.PRINT_FACILITY" })}
                      </Dropdown.Item>


                      <Dropdown.Divider />
                    </DropdownType>
                  ))}
                </div>
                {/* <div className="mr-md-4 mr-0 mb-1 mb-md-0">
{obj.isBTOn == false ? "Manual" : "Processor Queue"}
</div> */}
              </ButtonToolbar>
            </>
          </td>
          {/* <td align="center">
            <Badge variant="success">Active</Badge>
          </td> */}
        </tr>
      );
    }
    // setBillingQueueReleasedFacilityListUI(rows);
    getAllBTReleasedPartailOrderFacilityList(billQueueFacilityList);
  };
  const getAllBTReleasedPartailOrderFacilityList = useCallback(
    (regularReleasedFacilityList) => {
      //setIsLoading(true);
      Api.getRequestQry(
        Api.Uri_GetPartailOrderFacilities,
        "?queueId=" + Common.ProcessStatus.brighreeQueueID
      )
        .then((res) => {
          if (res.data.statusCode == 200) {
            if (res.data.result != null) {
              if (res.data.result.length > 0) {
                setBTReleasedPartailFacilityList(
                  res.data.result,
                  regularReleasedFacilityList
                );
              } else {
                setBTReleasedPartailFacilityList(
                  [],
                  regularReleasedFacilityList
                );
              }
            }
          }
        })
        .catch((ex) => { })
        .then(() => {
          setIsLoading(false);
        });
    }
  );
  const setBTReleasedPartailFacilityList = (
    list,
    regularReleasedFacilityList
  ) => {
    var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const OrganizationName = obj.organizationName;
      const facilityID = obj.id;

      const chain = Common.replaceNullWithString(obj.chain);
      const facilityName = Common.replaceNullWithString(obj.facilityName);
      var dob = new Date(obj.cycleDate);
      const date =
        dob.getMonth() + 1 + "/" + dob.getDate() + "/" + dob.getFullYear();
      const facilityType = Common.replaceNullWithString(obj.facilityType);
      regularReleasedFacilityList.push(
        <tr key={i.toString()}>
          <td>{obj.totalWound}</td>
          <td align="left" className="customStyleAccorion">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                setProcessStatusId(Common.ProcessStatus.brighreeQueueID);
                setselectedFacilityDate(date);
                handleShowReleasedFacility(false);
                setShowBillingResident(true);
                setFacilityObj(obj);
                setPartailOrder(true);
              }}
            >
              {facilityName}
            </button>
          </td>
          {/* <td>{Common.GetDateString(obj.billingDate) == "01-01-01" ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" }) : Common.GetDateString(obj.billingDate)}</td> */}

          {/* <td>{Common.GetDateString(obj.cycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.cycleDate)+ (obj.isSecondaryCycle ? " (S)" : "")}</td> */}
          <td>
            {Common.GetDateString(obj.billingDate) == "01-01-01"
              ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" })
              : Common.GetDateString(obj.billingDate) +
              (obj.isSecondaryCycle ? " (S)" : "")}
          </td>          
          {/* <td>{Common.GetDateString(obj.secondaryCycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.secondaryCycleDate)}</td> */}



          <td>
            {Common.replaceNullOrEmptyWithString(obj.organizationName, "")}
            {obj.organizationName == null && obj.chain == null ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" }) : ""}
            {obj.organizationName != null && obj.chain != null ? "/" : ""}
            {Common.replaceNullOrEmptyWithString(obj.chain, "")}</td>
            
          <td align="center">
            {obj.isEcp == true ? (
              <Badge variant="success">{intl.formatMessage({ id: "TEXT.YES" })}</Badge>
            ) : (
              <Badge variant="primary">{intl.formatMessage({ id: "TEXT.NO" })}</Badge>
            )}{" "}
          </td>
          
          <td>{obj.totalResident + "/" + obj.totalWound}</td>
          <td>
            <div className="mr-md-4 mr-0 mb-1 mb-md-0">{"BT"}</div>
          </td>
          <td align="center">
            <>
              <ButtonToolbar className="justify-content-center">
                {/* <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {obj.isManualProcess == true ? "Manual" : "Brightree Queue"}
                </div> */}
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      size="xs"
                      variant="primary"
                      title={intl.formatMessage({ id: "DATA_TABLE.ACTIONS" })}
                      id={`dropdown-button-drop-${idx}`}
                      key={idx}
                    >
                      <Dropdown.Item
                        eventKey="12"
                        onClick={() =>
                          handleFacilityReportModel(
                            obj,
                            Common.ProcessStatus.brighreeQueueID,
                            false
                          )
                        }
                      >
                        {intl.formatMessage({ id: "WORKQUEUE.PRINT_FACILITY" })}
                      </Dropdown.Item>

                      <Dropdown.Divider />
                    </DropdownType>
                  ))}
                </div>
              </ButtonToolbar>
            </>
          </td>
        </tr>
      );
    }
    // setWorkQueueReleasedFacilityListUI([]);
    setWorkQueueReleasedFacilityListUI(regularReleasedFacilityList);

    Common.LoadDataTable("facilityBillingList");
  };
  //#endregion

  const handleShowReleasedFacility = (value) => {
    setReleasedFacility(value);
    if (value) {
      getAllReleasedFacility();
      // getAllPartailOrderFacilityList();
    }
  };
  const refreshFacilityList = () => {
    setShowResident(false);
    getAllFacility();
  };
  const refreshReleasedFacilityList = () => {
    setShowBillingResident(false);
    handleShowReleasedFacility(true);
  };
  const handleReleaseFacility = (facilityID, billingDate) => {
    var obj = {};
    obj.FacilityID = facilityID;
    obj.isInBillingQueue = true;
    obj.BillingDate = billingDate;
    var msg = intl.formatMessage({ id: "WORKQUEUE.YOU_WANT_TO_RELEASE" });
    Common.showConfirmation(msg, "", releaseFacility, null, obj)

  };
  const releaseFacility = (obj) => {
    //setIsLoading(true);
    Api.postRequest(Api.Uri_UpdateFacilityStatus, obj)
      .then((res) => {
        if (res.data.success) {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "success",
            "Success!",
            3000
          );
          getAllReleasedFacility();
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            "Warning!",
            3000
          );
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Workqueue", "handleReleaseFacility");

        // // debugger;
        Common.showSuccessAlertAutoClose(
          intl.formatMessage({ id: "NEWRESIDENT.SOME_ERROR_OCCURED_WHILE_PROCESSING_YOUR_REQUEST" }),
          "warning",
          "Failed!",
          3000
        );
      })
      .then(() => {
        // setIsLoading(false);
      });
  };
  const handleBillingDate = (facility, isRequested) => {
    debugger;
    if (facility.zip == "" || facility.zip == null) {
      Common.showSuccessAlert(
        intl.formatMessage({ id: "WORKQUEUE.FACILITY_DO_NOT_HAVE_POSTAL" }),
        "warning",
        "Warning!",
        3000
      );
      return false;
    }
    setFacilityID(facility.id);
    var billingDateObj = {};
    if (Common.GetDateString(facility.cycleDate) == null) {
      billingDateObj.billingDate = null;
    } else {
      billingDateObj.billingDate = facility.cycleDate;
    }

    if (Common.GetDateString(facility.secondaryCycleDate) == null) {
      billingDateObj.secondayBillingDate = null;
    } else {
      billingDateObj.secondayBillingDate = facility.secondaryCycleDate;
    }

    if (Common.GetDateString(facility.primaryCycleDate) == null) {
      billingDateObj.primaryCycleDate = null;
    } else {
      billingDateObj.primaryCycleDate = facility.primaryCycleDate;
    }
    billingDateObj.isRequested = isRequested;
    billingDateObj.requestedCycleDate = facility.requestedCycleDate;
    billingDateObj.requestedSecondaryCycleDate =
      facility.requestedSecondaryCycleDate;
    billingDateObj.isPartial = false;
    billingDateObj.isSecondaryCycle = facility.isSecondaryCycle;
    setBillingDate(billingDateObj);
    setBillingDateModal.showModal();
  };
  const handleFacilityMsgModel = async (facility) => {
    await setFacilityID(facility.id);
    facilitymsgModal.showModal();
  };
  const handleUnReleaseFacility = (facId, isSecondaryCycle) => {
    var msg = intl.formatMessage({ id: "WORKQUEUE.YOU_WANT_TO_UNRELEASE" });
    Common.showConfirmation(msg, "", unReleaseFacility, null, facId)
    // handleReleaseFacility(FacilityId);
  };
  const unReleaseFacility = async (facilityId) => {
    debugger;
    var obj = {};
    setReleasedFacility(true);
    obj.FacilityID = facilityId;

    Api.postRequest(Api.Uri_UnReleaseBillingFacility, obj)
      .then((res) => {
        if (res.data.success) {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "success",
            "Success!",
            3000
          );
          setReleasedFacility(false);
          getAllFacility();
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            "Warning!",
            3000
          );
        }
      })
      .catch((ex) => {
        Common.showSuccessAlertAutoClose(
          intl.formatMessage({ id: "NEWRESIDENT.SOME_ERROR_OCCURED_WHILE_PROCESSING_YOUR_REQUEST" }),
          "warning",
          "Failed!",
          3000
        );
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  const releasePartailFacility = (facility) => {
    var msg = intl.formatMessage({ id: "WORKQUEUE.DO_YOU_WANT_TO_RELEASE_PARTIAL_ORDERS" });
    Common.showConfirmation(msg, "", handleReleaseFacilityPartailOrder, null, facility)
    // handleReleaseFacility(FacilityId);
  };
  const handleReleaseFacilityPartailOrder = (partailFacility) => {
    debugger;
    Api.getRequestQry(
      Api.apiUrls.releaseFacilityPartailOrders,
      "?facilityId=" +
      partailFacility.id +
      "&queueID=" +
      Common.ProcessStatus.workQueueID +
      "&partailOrderDate=" +
      partailFacility.cycleDate
    )
      .then((res) => {
        if (res.data.success) {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "success",
            "Success!",
            3000
          );
          getAllReleasedFacility();
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            "Warning!",
            3000
          );
        }
      })
      .catch((ex) => {
        Common.showSuccessAlertAutoClose(
          intl.formatMessage({ id: "WORKQUEUE.SOME_ERROR_OCCURED_WHILE" }),
          "warning",
          "Failed!",
          3000
        );
      })
      .then(() => { });
  };

  const skipFacility = (selectedfacility, isSecondaryCycle) => {
    debugger;
    var facility = {};
    facility.processQueueId = selectedfacility.processQueueID;
    facility.facilityId = selectedfacility.id;

    if (isSecondaryCycle) {
      facility.isSkip = true;
      var msg = intl.formatMessage({ id: "WORKQUEUE.DO_YOU_WANT_TO_SKIP_TO_PRIMARY" });
      Common.showConfirmation(msg, "", handleSendSkipOrHoldFacility, null, facility)
    } else {
      facility.isOnHold = true;
      var msg = intl.formatMessage({ id: "WORKQUEUE.DO_YOU_WANT_TO_SKIP_TO_PRIMARY" });
      Common.showConfirmation(msg, "", handleSendSkipOrHoldFacility, null, facility)
    }

    // handleReleaseFacility(FacilityId);
  };
  const handleSendSkipOrHoldFacility = (facility) => {
    debugger;
    Api.postRequest(Api.apiUrls.updateSkipOrHoldFacilityStatus, facility)
      .then((res) => {
        if (res.data.success) {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "success",
            "Success!",
            3000
          );
          getAllFacility();
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            "Warning!",
            3000
          );
        }
      })
      .catch((ex) => {
        Common.showSuccessAlertAutoClose(
          intl.formatMessage({ id: "WORKQUEUE.SOME_ERROR_OCCURED_WHILE" }),
          "warning",
          "Failed!",
          3000
        );
      })
      .then(() => { });
  };
  const handleFacilityReportModel = async (facility, facilityProcessStatusId, isPartial) => {
    var { data } = await Api.getRequestQry(Api.URI_GetFacilitesReport,
      "?facilityId=" + facility.id + "&facilityProcessStatusId=" + facilityProcessStatusId + "&isPartial=" + isPartial)

    if (data.success) {
      if (data.result != null && data.result != '') {
        debugger;
        const fileUrl = Common.getUrlBlobSAS(
          blobContainerFacilityReportName,
          data.result
        );
        window.location.assign(fileUrl)
      }
    } else {
    }
  };
  const setFacilityList = (list) => {
    debugger;
    var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const OrganizationName = obj.organizationName;
      const facilityID = obj.id;

      const chain = Common.replaceNullWithString(obj.chain);
      const facilityName = Common.replaceNullWithString(obj.facilityName);
      // var cycleDate = new Date(obj.cycleDate);
      const facilityType = Common.replaceNullWithString(obj.facilityType);
      var dateDif = 3;
      if (
        Common.GetDateString(obj.cycleDate) != "01-01-01" &&
        obj.cycleDate != null
      ) {
        dateDif = Common.getDifferenceInDays(
          new Date(),
          new Date(obj.cycleDate)
        );
        // if(dateDif<0){
        //   dateDif=3
        //   if (Common.GetDateString(obj.secondaryCycleDate) != "01-01-01" && obj.secondaryCycleDate!=null) {
        //   dateDif = Common.getDifferenceInDays(new Date(), new Date(obj.secondaryCycleDate));
        //   }
        // }
      }
      rows.push({
        obj: obj,
        dateDif: dateDif,
        facilityName: facilityName,
        dos:
          Common.GetDateString(obj.cycleDate) == "01-01-01" ||
            obj.cycleDate == null
            ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" })
            : Common.GetDateString(obj.cycleDate),
        secondaryCycleDate:
          Common.GetDateString(obj.secondaryCycleDate) == "01-01-01" ||
            obj.secondaryCycleDate == null
            ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" })
            : Common.GetDateString(obj.secondaryCycleDate),
        primaryCycleDate:
          Common.GetDateString(obj.primaryCycleDate) == "01-01-01" ||
            obj.primaryCycleDate == null
            ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" })
            : Common.GetDateString(obj.primaryCycleDate),

        isSecondaryCycle: obj.isSecondaryCycle,
        organizationName: Common.replaceNullOrEmptyWithString(
          obj.organizationName,
          ""
        ),
        // (Common.replaceNullOrEmptyWithString(obj.organizationName, "")(obj.organizationName == null && obj.chain == null ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" }) : "")
        //   (obj.organizationName != null && obj.chain != null ? "/" : "")),
        ecp:
          obj.isEcp == true ? (
            <Badge variant="success">{intl.formatMessage({ id: "TEXT.YES" })}</Badge>
          ) : (
            <Badge variant="primary">{intl.formatMessage({ id: "TEXT.NO" })}</Badge>
          ),
        residentsWounds: obj.totalResident + "/" + obj.totalWound,
        ps: obj.totalPartailWound,
        clinicianName: Common.replaceNullOrEmptyWithString(
          obj.clinicianName,
          intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" })
        ),
      });
    }
    debugger;
    setWorkQueueListUI(rows);
    // Common.LoadDataTable("facilityTable");
  };
  const advSearchColumns = [
    {
      name: intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.FACILITY_NAME.TITLE" }),
      //   <div className="d-flex align-items-center mb-sm-0 mb-1">
      //   <label className="mb-0" htmlFor="search-invoice">
      //     Facility Name:
      //   </label>
      //   <input

      //     id="search-invoice"
      //     className="ms-50 w-100"
      //     type="text"
      //     value={searchTerm}
      //     onChange={(e) => {
      //       handleFilter(e.target.value);
      //       setSearchTerm(e.target.value)
      //     }}
      //   />
      // </div>
      sortable: true,
      width: "400px",
      selector: (row) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                handleShowReleasedFacility(false);
                setShowResident(true);
                setFacilityObj(row.obj);
              }}
            >
              {row.facilityName}
            </button>
            {/* <button className="ml-1 badge badge-pill badge-warning" title={row.msg} onClick={() => handleFacilityMsgModel(row.obj)}>Msg</button> */}
          </>
        );
      },
    },
    {
      name: intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.NEXT_SHIP_DATE" }),
      sortable: true,
      sortColumn: "cycleDate",
      minWidth: "150px",
      selector: (row) => row.dos + (row.isSecondaryCycle ? " (S)" : ""),
    },
    {
      name: intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.PRIMARY_SHIP_DATE" }),
      sortable: true,
      sortColumn: "cycleDate",
      minWidth: "150px",
      selector: (row) => row.primaryCycleDate,
    },
    {
      name: intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.SECONDARY_SHIP_DATE" }),
      sortable: true,
      sortColumn: "cycleDate",
      minWidth: "150px",
      selector: (row) => row.secondaryCycleDate,
    },

    // {
    //   name: "Secondary Date",
    //   sortable: true,
    //   sortColumn:"secondaryCycleDate",
    //   minWidth: "100px",
    //   // selector: (row) => row.funeral_home,
    //   selector: (row) => row.secondaryCycleDate
    // },
    {
      name: intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.OWNERSHIP" }),
      sortable: true,
      minWidth: "200px",
      selector: (row) => row.organizationName,
    },
    {
      name: intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.EQUAL_CARE" }),
      sortable: true,
      minWidth: "25px",
      selector: (row) => row.ecp,
    },
    {
      name: intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.RESIDENTS_WOUNDS" }),
      sortable: true,
      minWidth: "100px",
      selector: (row) => row.residentsWounds,
    },
    // {
    //   name: "PS",
    //   sortable: true,
    //   minWidth: "25px",
    //   selector: (row) => row.ps
    // },
    {
      name: intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.CLINICIAN_NAME.TITLE" }),
      sortable: true,
      minWidth: "100px",
      selector: (row) => row.clinicianName,
    },
    {
      name: intl.formatMessage({ id: "DATA_TABLE.ACTIONS" }),
      allowOverflow: true,
      minWidth: "50px",
      cell: (row) => {
        return (
          <ButtonToolbar className="justify-content-center">
            {/* <div className="mr-md-4 mr-0 mb-1 mb-md-0">
            <CheckPermission
              permission={UserActions.Update}
              module={ModulesName.Module_MyWorkQueue}
              yes={() => (
                <Button
                  size="xs"
                  variant="success"
                  onClick={() => handleReleaseFacility(facilityID)}
                >
                  Release
                </Button>
              )}
              no={() => null}
            />
          </div> */}
            <div className="mr-md-4 mr-0 mb-1 mb-md-0">
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  size="xs"
                  variant="primary"
                  title={intl.formatMessage({ id: "DATA_TABLE.ACTIONS" })}
                  id={`dropdown-button-drop-${idx}`}
                  key={idx}
                >
                  <CheckPermission
                    permission={UserActions.Update}
                    module={ModulesName.Module_Facility}
                    yes={() => (
                      <Dropdown.Item
                        eventKey="6"
                        onClick={() => editFacilityByID(row.obj.id)}
                      >
                        {intl.formatMessage({ id: "WORKQUEUE.EDIT_FACILITY" })}
                      </Dropdown.Item>
                    )}
                    no={() => null}
                  />
                  <Dropdown.Item
                    eventKey="61"
                    disabled={row.obj.isOnHold || row.obj.isSkip}
                    onClick={() => skipFacility(row.obj, row.isSecondaryCycle)}
                  >
                    {row.isSecondaryCycle ? intl.formatMessage({ id: "WORKQUEUE.SKIP_TO_PRIMARY" }) : intl.formatMessage({ id: "WORKQUEUE.HOLD_FACILITY" })}
                  </Dropdown.Item>

                  <CheckPermission
                    userRole={[RBAC.Role_Gentell_Administrator]}
                    yes={() => (
                      <>
                        {/* <Dropdown.Item eventKey="6" onClick={() => editFacilityByID(obj.id)}>Edit Facility</Dropdown.Item> */}
                        <Dropdown.Item
                          eventKey="0"
                          onClick={() => handleBillingDate(row.obj, false)}
                        >
                          {intl.formatMessage({ id: "WORKQUEUE.SET_SHIP_DATE" })}
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() => handleFacilityMsgModel(row.obj)}
                        >
                          {intl.formatMessage({ id: "WORKQUEUE.MSG" })}
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="3"
                          onClick={() =>
                            handleFacilityReportModel(
                              row.obj,
                              Common.ProcessStatus.workQueueID,
                              false
                            )
                          }
                        >
                          {intl.formatMessage({ id: "WORKQUEUE.PRINT_FACILITY" })}
                        </Dropdown.Item>
                      </>
                    )}
                    no={() => null}
                  />
                  {/* <CheckPermission
                    userRole={[RBAC.Role_Gentell_WoundCareSpecialist]}

                    yes={() => (
                      <>
                        <Dropdown.Item eventKey="0" onClick={() => handleBillingDate(row.obj, true)}>Request Billing Date</Dropdown.Item>
                      </>
                    )}
                    no={() => null}
                  /> */}

                  {/* <Dropdown.Item eventKey="3" onClick={() => { handleShowContactList(obj.id) }}>Contacts</Dropdown.Item> */}
                  {/* <Dropdown.Item eventKey="4" onClick={() => { handleShowConsultantList(obj.id) }}>Consultants</Dropdown.Item> */}
                  <Dropdown.Item
                    eventKey="3"
                    onClick={() => {
                      handleShowContactList(row.obj.id);
                    }}
                  >
                    {intl.formatMessage({ id: "WORKQUEUE.CONTACTS" })}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <CheckPermission
                    permission={UserActions.Update}
                    module={ModulesName.Module_ApproveOrder}
                    yes={() => {
                      return (
                        <>
                          {!row.obj.isApproved && (
                            <Dropdown.Item
                              eventKey="8"
                              className="text-danger"
                              onClick={() => {
                                setFacilityObj(row.obj);
                                authorizeModal.showModal();
                              }}
                            >
                              {intl.formatMessage({ id: "WORKQUEUE.AUTHORIZE_ORDERS" })}
                            </Dropdown.Item>
                          )}
                          {row.obj.isApproved && (
                            <Dropdown.Item
                              title="Order for this facility is Authorized"
                              eventKey="8"
                              className="text-success"
                              onClick={() => {
                                setFacilityObj(row.obj);
                                authorizeModal.showModal();
                              }}
                            >
                              {intl.formatMessage({ id: "WORKQUEUE.ORDERS_AUTHORIZED" })}
                            </Dropdown.Item>
                          )}
                          {/* <Dropdown.Item eventKey="8" className="text-danger" onClick={()=>handleFacilityStatus(obj)}>{obj.active==false?'Active': 'Deactivate'}</Dropdown.Item> */}
                        </>
                      );
                    }}
                    no={() => null}
                  />
                  <CheckPermission
                    userRole={[
                      RBAC.Role_Facility_RegionalClinician,
                      RBAC.Role_Gentell_Administrator,
                    ]}
                    yes={() => {
                      return (
                        <>
                          <Dropdown.Item
                            eventKey="9"
                            className="text-danger"
                            onClick={() => handleFacilityStatus(row.obj)}
                          >
                            {row.obj.active == false ? intl.formatMessage({ id: "ACTIVE" }) : intl.formatMessage({ id: "DEACTIVATE" })}
                          </Dropdown.Item>
                        </>
                      );
                    }}
                    no={() => null}
                  />
                </DropdownType>
              ))}
            </div>
          </ButtonToolbar>
        );
      },
    },
  ];
  // ** Function to handle Pagination
  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  // ** Function in get data on rows per page
  const handlePerPage = async (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };
  const handleShipDate = async (e) => {
    setShipDate(e.target.value);
  };
  // ** Function in get data on search query change
  const handleFilter = debounce((val) => {
    debugger;
    if (val != "" && val.length >= 2) {
      setCurrentPage(1);
      // setRowsPerPage(10)
      getAllFacility(val);
    }
    if (val == "") {
      setCurrentPage(1);
      // setRowsPerPage(10)
      getAllFacility(val);
    }
  }, 1000);

  const handleSort = (column, sortDirection) => {
    setSort(sortDirection);
    setSortColumn(column.sortColumn);
  };

  // ** Table data to render
  const dataToRender = () => {
    return workQueueListUI.slice(0, rowsPerPage);
  };
  const conditionalRowStyles = [
    {
      // var dateDif = 3
      // if (Common.GetDateString(obj.cycleDate) != "01-01-01") {
      //   dateDif = Common.getDifferenceInDays(new Date(), new Date(obj.cycleDate));
      // }
      when: (row) => row.dateDif != 10000,
      style: (row) => ({
        // color: "white",
        backgroundColor:
          row.dateDif < 1 &&
            !row.obj.isSkip &&
            !row.obj.isOnHold &&
            !row.obj.isSecondaryCycle
            ? "#FFCCCB"
            : row.dateDif < 3 &&
              !row.obj.isSkip &&
              !row.obj.isOnHold &&
              !row.obj.isSecondaryCycle
              ? "#FFFFE0"
              : row.dateDif < 8 &&
                !row.obj.isSkip &&
                !row.obj.isOnHold &&
                !row.obj.isSecondaryCycle
                ? "#90EE90"
                : row.obj.isSkip ||
                  row.obj.isOnHold ||
                  row.obj.cycleDate == "" ||
                  row.obj.cycleDate == null
                  ? "#ADD8E6"
                  : "",
      }),

      // when: row => row.obj.isSkip,
      // style: row => ({
      //   backgroundColor: 'blue' ,
      // }),
    },
  ];

  // ** Custom Pagination
  const CustomPagination = () => {
    // const count = Number(Math.ceil(store.total / rowsPerPage))
    const count = Number(Math.ceil(pageCount / rowsPerPage));
    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(
    ShowAdvancedSearch ? true : false
  );
  const showAdvanceSearchSection = () => {
    showAdvanceSearchSectionState(!ShowAdvancedSearch);
  };
  const handleFacilityStatus = (obj) => {
    obj.active = obj.active == true ? false : true;
    updateFacilityStatus(obj);
  };
  const updateFacilityStatus = (obj) => {
    var msg =
      intl.formatMessage({ id: "YOU_WANT_TO" }) + (obj.active ? intl.formatMessage({ id: "ACTIVE" }) : intl.formatMessage({ id: "DEACTIVATE" })) + ' ' + intl.formatMessage({ id: "FACILITY" }) + '.';
    Common.showConfirmation(msg, "", updateStatus, null, obj);
  };
  const updateStatus = (obj) => {
    if (obj) {
      Api.postRequest(Api.apiUrls.updateFacilityStatus, obj).then((res) => {
        if (res.data.success) {
          getAllFacility();
          Common.showSuccessAlertAutoClose(
            intl.formatMessage({ id: "WORKQUEUE.FACILITY_STATUS_UP_SUCCESSFULLY" }),
            "success",
            "Success!",
            3000
          );
        } else {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "error",
            "Error!",
            3000
          );
        }
      });
    }
  };
  const loadClinicians = async (inputValue) => {
    try {
      if (inputValue.length > 1) {
        var { data } = await Api.getRequestQry(
          Api.Uri_GetUserByRoleID,
          `?id=${Common.LookupsFK.Fk_RoleClinicianID} &userName=${inputValue}`
        );
        if (data.success) {
          let userList = [];
          if (data.result.length) {
            userList = data.result;
          }
          // this.setState({ primaryPhysicianList: orgList })
          // setOrganizations(orgList)
          return userList.map((e) => {
            return {
              label: e.value,
              value: e.key,
            };
          });
        }
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  const handleClinicianChange = (clinicain) => {
    debugger;
    setClinician(clinicain);
  };
  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
        <Button
          variant="success"
          size="sm"
          className="mr-3"
          onClick={() => {
            handleShowReleasedFacility(true);
          }}
        >
          {intl.formatMessage({ id: "WORKQUEUE.SHOW_RELEASED_FACILITY" })}
        </Button>
        {/* <Button
          variant="warning"
          className="mr-3"
          size="sm"
          onClick={showAdvanceSearchSection}
        >
          Advanced Search
        </Button> */}
        <CheckPermission
          permission={UserActions.Create}
          module={ModulesName.Module_Facility}
          yes={() => (
            <Button variant="primary" size="sm" onClick={handleShowNewFacility}>
              {intl.formatMessage({ id: "WORKQUEUE.NEW_FACILITY" })}
            </Button>
          )}
          no={() => null}
        />
      </div>
    </div>
  );
  const toolbarReleasesFacility = (
    <div className="card-toolbar">
      <div className="example-tools">
        <Button
          variant="dark"
          size="sm"
          className="mr-3"
          onClick={() => {
            handleShowReleasedFacility(false);
          }}
        >
          {intl.formatMessage({ id: "WORKQUEUE.HIDE_RELEASED_FACILITIES" })}
        </Button>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <>
        {showResident && !isLoading && !isReleasedFacilityShow && (
          <Animated
            animationIn="fadeIn"
            animationInDuration={1000}
            animationOut="fadeOut"
            isVisible={showResident}
          >
            <FacilityResident
              backPress={() => {
                refreshFacilityList();
                setSearchTerm("");
              }}
              facility={FacilityObj}
            // afterAuthorize={()=>getAllFacility}
            />
          </Animated>
        )}
        {showBillingResident && !isReleasedFacilityShow && (
          <Animated
            animationIn="fadeIn"
            animationInDuration={1000}
            animationOut="fadeOut"
            isVisible={showBillingResident}
          >
            <BillingResident
              // backPress={() => refreshFacilityList()}
              // facility={FacilityObj}

              refresh={() => refreshReleasedFacilityList()}
              backPress={() => refreshReleasedFacilityList()}
              facility={FacilityObj}
              isPartailOrder={isPartailOrder}
              // processStatusId={Common.ProcessStatus.billingQueueID}
              isReleasedFacility={true}
              isBillingQueue={false}
              selectedFacilityDate={selectedFacilityDate}
              isProcessorFunctionOn={isProcessorFunctionOn}
              processStatusId={processStatusId}
            // isReleasedFacility={isReleasedFacility}
            />
          </Animated>
        )}
        {!showResident && !showBillingResident && (
          <Animated animationIn="fadeIn" animationInDuration={1000}>
            <Card className="example example-compact">
            <CardHeader title={intl.formatMessage({ id: "HEADER.MY_WORK_QUEUE" })} toolbar={toolbar} />
            <CardBody>
                {
                  

                    <div className="react-dataTable">
                      <DataTable
                        highlightOnHover
                        striped
                        noHeader
                        subHeader
                        sortServer
                        pagination
                        responsive
                        paginationServer
                        columns={advSearchColumns}
                        onSort={handleSort}
                        // sortIcon={<ChevronLeftRounded />}
                        // ChevronLeftRounded 
                        progressPending={isLoading}
                        progressComponent={<ModalLoading />}
                        className="react-dataTable"
                        paginationComponent={CustomPagination}
                        data={dataToRender()}
                        conditionalRowStyles={conditionalRowStyles}
                        subHeaderComponent={
                          <div className="w-100 mb-75">
                            <Row>
                              <Col
                                xl="3"
                                lg="3"
                                md="3"
                                style={{ width: "5rem" }}
                                className="d-flex align-items-sm-start justify-content-md-start justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column p-0 mt-md-0 mt-1"
                              >
                                <div className="d-flex align-items-start mb-sm-0 mb-1">
                                <label htmlFor="rows-per-page">
                                  {intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.FACILITY_NAME.TITLE" })}:
                                </label>
                                  <input
                                    id="search-invoice"
                                    className="ms-50 w-100"
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => {
                                      handleFilter(e.target.value);
                                      setSearchTerm(e.target.value)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col
                                xl="2"
                                lg="2"
                                md="2"
                                className="d-flex align-items-start p-0 "
                              >
                              <div className="d-flex align-items-start w-100">
                              <label htmlFor="rows-per-page">
                                  {intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.SHOW_ENTRIES" })}
                                </label>                                  <select
                                    className="ms-50 w-30"
                                    id="rows-per-page"
                                    value={rowsPerPage}
                                    onChange={handlePerPage}
                                    style={{ width: "5rem" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                  </select>
                                  {/* <label htmlFor="rows-per-page">Entries</label> */}
                                </div>
                              </Col>
                              <Col
                                xl="2"
                                lg="2"
                                md="2"
                                className="d-flex align-items-start p-0 "
                              >
                                <div className="d-flex align-items-start w-100">
                                <label htmlFor="rows-per-page">
                                  {intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.PRIMARY_SECONDARY" })}
                                </label>
                                <select
                                  className="ms-50 w-100"
                                  id="rows-per-page"
                                  value={shipDateId}
                                  onChange={handleShipDate}
                                  style={{ width: "5rem" }}
                                >
                                  <option value="2">{intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.ALL" })}</option>
                                  <option value="0">{intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.PRIMARY_SHIP_DATE" })}</option>
                                  <option value="1">{intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.SECONDARY_SHIP_DATE" })}</option>
                                </select>
                                  {/* <label htmlFor="rows-per-page">Entries</label> */}
                                </div>

                              </Col>
                              <CheckPermission
                                userRole={[RBAC.Role_Gentell_Trainer]}

                                yes={() => (
                                  <>
                                    <Col
                                      xl="2"
                                      lg="2"
                                      md="2"
                                      className="d-flex align-items-sm-end justify-content-md-end justify-content-end flex-xl-nowrap flex-wrap flex-sm-row flex-column p-0 mt-md-0 mb-1"
                                    >
                                      {/* <div className="d-flex align-items-center mb-sm-0 mb-1"> */}
                                      <div className="d-flex align-items-center mb-sm-0 mb-1" >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="trainer"
                                            checked={isTrainer}
                                            onChange={(e) => {
                                              setIsTrainerRole(
                                                e.target.checked
                                              );
                                              setSearchTerm("");
                                              setClinicianInputValue("");
                                              setShipDate(2);
                                              setRowsPerPage(50);
                                              setCurrentPage(1);
                                              setClinician("");
                                              // getAllFacility()
                                            }}
                                            value="gilad"
                                          />
                                        }
                                        label={
                                          user?.roles?.roleList.find(
                                            (x) =>
                                              x.roleName ==
                                              RBAC.Role_Gentell_RegionalManager
                                          ) == undefined
                                            ? intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.TRAINER_ROLE" })
                                            : intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.CLINICIAN_ROLE" })
                                        }
                                        className="font-weight-bold"
                                      />
                                      </div>
                                    </Col>
                                    <Col
                                      xl="3"
                                      lg="3"
                                      md="3"
                                      className="d-flex align-items-end  flex-sm-row flex-column mb-1  mt-md-0 "
                                    >
                                      {/* {isTrainer && */}
                                        <>
                                          <div className="d-flex align-items-center w-100">
                                         
                                          {intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.CLINICIAN.TITLE" })}
                                            <AsyncSelect
                                              name='Clinician'
                                              isDisabled={!isTrainer}
                                              className="ms-50 w-100"
                                              // className="d-flex align-items-start p-0 "
                                              inputValue={clinicianInputValue}
                                              onInputChange={setClinicianInputValue}
                                              value={clinician}
                                              onChange={(obj) => {
                                                handleClinicianChange(obj)
                                              }}

                                              loadOptions={loadClinicians}
                                              isClearable
                                            />
                                          </div>
                                        </>

                                    {/* } */}
                                  </Col>
                                </>
                              )}
                              no={() => null}
                            />
                          </Row>
                        </div>
                      }
                    />
                  </div>
                },
              </CardBody>
            </Card>
          </Animated>
        )}
        {isReleasedFacilityShow && (
          <Animated animationIn="fadeIn" animationInDuration={1000}>
            <hr />
            <Card className="example example-compact">
            <CardHeader
                title={intl.formatMessage({ id: "RELEASED_FACILITIES.TITLE" })}
                toolbar={toolbarReleasesFacility}
              />              {/* toolbar={toolbar} */}
              <CardBody>
                {isLoading ? (
                  <div className="row d-flex justify-content-center">
                    <span className="mt-5 spinner spinner-warning"></span>
                  </div>
                ) : (
                  <table
                    className="table table-striped table-hover table-sm table-bordered"
                    id="facilityBillingList"
                  >
                       <thead>
                      <tr>
                        <th></th>

                        <th>{intl.formatMessage({ id: "FACILITY.DATA_TABLE.FACILITY_NAME.TITLE" })}</th>
                        {/* <th>Processing</th> */}
                        <th>{intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.NEXT_SHIP_DATE" })}</th>
                        {/* <th>Secondary Date</th> */}

                        {/* <th>OUT'S Date</th> */}

                        <th>
                          {intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.OWNERSHIP_HEADQUARTERS" })} <br />
                          {intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.PURCHASING_GROUP_CHAIN" })}
                        </th>
                        {/* <th>Clinician Name</th> */}
                        <th>{intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.EQUAL_CARE" })}</th>
                        <th>
                          {intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.RESIDENTS" })} <br /> {intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.WOUNDS" })}
                        </th>
                        <th>{intl.formatMessage({ id: "WORKQUEUE.DATA_TABLE.LOCATION" })}</th>
                        <th>{intl.formatMessage({ id: "DATA_TABLE.ACTIONS" })}</th>
                      </tr>
                    </thead>
                    <tbody>{workQueueReleasedFacilityListUI}</tbody>
                  </table>
                )}
              </CardBody>
            </Card>
          </Animated>
        )}
        <PopupModal
          size="lg"
          title={intl.formatMessage({ id: "WORKQUEUE.PICK_ORDER_TYPE" })}
          ref={(ref) => {
            placeOrderModal = ref;
          }}
        >
          {" "}
          <PlaceOrder />{" "}
        </PopupModal>

        {/** physicianModal */}
        <PopupModal
          title={intl.formatMessage({ id: "WORKQUEUE.PHYSICIANS" })}
          showButton={false}
          ref={(ref) => {
            physicianModal = ref;
          }}
        >
          {" "}
          <Physician />{" "}
        </PopupModal>
        {/** physicianModal */}
        <PopupModal
          title={intl.formatMessage({ id: "WORKQUEUE.FACILITY_MSG" })}
          showButton={false}
          ref={(ref) => {
            facilitymsgModal = ref;
          }}
        >
          {" "}
          <AddFacilityMsg msg={null} facilityID={FacilityID} />{" "}
        </PopupModal>

        {/** physicianModal */}
        <PopupModal
          title={intl.formatMessage({ id: "WORKQUEUE.FACILITY_REPORT" })}
          showButton={false}
          ref={(ref) => {
            facilityReportModal = ref;
          }}
        >
          {" "}
          <FacilityPdfReport facilityID={FacilityID} />{" "}
        </PopupModal>
        {/* facility Detail modal */}

        <PopupModal
          title={intl.formatMessage({ id: "WORKQUEUE.FACILITY_DETAIL" })}
          dialogClassName="modal-90w"
          showButton={false}
          ref={(ref) => {
            FacilityDetailModal = ref;
          }}
        >
          {" "}
          <FacilityDetail />{" "}
        </PopupModal>

        {/* new facility modal */}
        <Modal
          aria-labelledby="newFacilityModal"
          scrollable
          backdrop="static"
          dialogClassName="modal-90w"
          keyboard={false}
          show={showModalNewFacility}
          onHide={handleCloseNewFacility}
        >
          <Modal.Header className="py-3" closeButton>
          <Modal.Title id="newFacilityModal">{intl.formatMessage({ id: "WORKQUEUE.NEW_FACILITY" })}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NewFacilityModal />
          </Modal.Body>
          <Modal.Footer className="py-2">
            <Button variant="danger" size="sm" onClick={handleCloseNewFacility}>
            {intl.formatMessage({ id: "BUTTON.CLOSE.TITLE" })}
            </Button>
            <CheckPermission
              permission={UserActions.Create}
              module={ModulesName.Module_Facility}
              yes={() => (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={handleCloseNewFacility}
                >
                  {intl.formatMessage({ id: "BUTTON.SAVE_CHANGES.TITLE" })}
                  </Button>
              )}
              no={() => null}
            />
          </Modal.Footer>
        </Modal>
        <Modal
        size="xl"
        aria-labelledby="showModalContacttList"
        dialogClassName="modal-90w"
        centered
        scrollable
        backdrop="static"
        keyboard={false}
        show={showModalContacttList}
        onHide={handleCloseContactList}
      >
        <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: "WORKQUEUE.CONTACTS" })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Contactslistmodal 
           changeDetect={setChangeDetect}
           isChangeDetect={changeDetect}
             FacilityID={FacilityID} />
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Button variant="danger" size="sm" onClick={handleCloseContactList}>
          {intl.formatMessage({ id: "BUTTON.CLOSE.TITLE" })}
          </Button>
        </Modal.Footer>
      </Modal>
      <PopupModal
          dialogClassName="modal-90w"
          title={intl.formatMessage({ id: "WORKQUEUE.ADD_FACILITY" })}
          module={ModulesName.Module_Facility}
          action={UserActions.Create}
          buttonPress={() => addFacility.onSubmit()}
          ref={(ref) => {
            addFacilityModal = ref;
          }}
          onClose={(ref) => {
            Common.showConfirmation(
              intl.formatMessage({ id: "WORKQUEUE_WOUNDS.ARE_YOU_SURE_YOU_WANT_TO_CLOSE_WITHOUT_SAVING_THE_DATA" }),
              "Warning",
              () => {
                ref.setState({ show: false });
              },
              () => { },
              null,
              intl.formatMessage({ id: "BUTTON.EXIT_WITHOUT_SAVING.TITLE" })
            );
          }}
        >
          {" "}
          <AddFacility
            ref={(ref) => {
              addFacility = ref;
            }}
            onSaveAPI={() => handleCloseNewFacility()}
          />{" "}
        </PopupModal>
        <PopupModal
          size="lg"
          title={intl.formatMessage({ id: "WORKQUEUE.NEW_SHIP_DATE" })}
          module={ModulesName.Module_MyWorkQueue}
          action={UserActions.Create}
          buttonPress={() => addFacility.onSubmit()}
          ref={(ref) => {
            setBillingDateModal = ref;
          }}
        >
          {" "}
          <SetBillingDate
            ref={(ref) => {
              addFacility = ref;
            }}
            onSaveAPI={() => {
              getAllFacility();
              setBillingDateModal.hideModal();
            }}
            facilityId={FacilityID}
            billingDateDetail={billingDateDetail}
          />{" "}
        </PopupModal>
        <PopupModal
          dialogClassName="modal-90w"
          title={intl.formatMessage({ id: "WORKQUEUE.EDIT_FACILITY" })}
          module={ModulesName.Module_Facility}
          action={UserActions.Update}
          buttonPress={() => editFacility.onSubmit()}
          ref={(ref) => {
            editFacilityModal = ref;
          }}
          onClose={(ref) => {
            Common.showConfirmation(
              intl.formatMessage({ id: "WORKQUEUE_WOUNDS.ARE_YOU_SURE_YOU_WANT_TO_CLOSE_WITHOUT_SAVING_THE_DATA" }),
              "Warning",
              () => {
                ref.setState({ show: false });
              },
              () => { },
              null,
              intl.formatMessage({ id: "BUTTON.EXIT_WITHOUT_SAVING.TITLE" })
            );
          }}
        >
          {" "}
          <EditFacility
            ref={(ref) => {
              editFacility = ref;
            }}
            FacilityID={FacilityID}
            Facility={Facility}
            onSaveAPI={() => {
              getAllFacility();
              editFacilityModal.hideModal(true);
            }}
          />{" "}
        </PopupModal>
        {/** Patient Orders approvel Modal */}
        <PopupModal
          title={
            <div className="card-title">
              <span>
                <h3 className="card-label">{intl.formatMessage({ id: "WORKQUEUE.FACILITY_AUTHORIZE" })}</h3>
                <small className="">
                  <b>{intl.formatMessage({ id: "WORKQUEUE.FACILITY.TITLE" })}:</b> {FacilityObj.facilityName},{" "}
                  <b>{intl.formatMessage({ id: "WORKQUEUE.OWNERSHIP_HEADQUARTERS" })}:</b>{" "}
                  {FacilityObj.organizationName == ""
                    ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" })
                    : FacilityObj.organizationName}
                  , <b>{intl.formatMessage({ id: "WORKQUEUE.PURCHASING_GROUP_CHAIN" })}:</b>{" "}
                  {FacilityObj.chain == "" ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" }) : FacilityObj.chain},{" "}
                </small>
              </span>
            </div>
          }
          dialogClassName="modal-90w"
          showButton={false}
          ref={(ref) => {
            authorizeModal = ref;
          }}
        >
          {" "}
          <WoundOrderApprovel
            facility={FacilityObj}
            close={() => {
              authorizeModal.hideModal();
              refreshFacilityList();
            }}
          />{" "}
        </PopupModal>
      </>
    </React.Fragment>
  );
}

export default injectIntl(WorkQueue);
