// ** React Imports
import * as React from "react";
import { Fragment, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import ViewWoundInformationChangeReason from './viewWoundInformationChangeReason';

const WoundInformationChangeReason = (props) => {
    const intl = useIntl();

    // ** States
    const [woundInfoChangeReasons, setWoundInfoChangeReasons] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const Schema = Yup.object().shape({
        woundInfoChangeReasonId: Yup.number()
            .required(intl.formatMessage({ id: "WOUND_INFO_CHANGE_REASON.REASON_REQUIRED" }))
            .typeError(intl.formatMessage({ id: "WOUND_INFO_CHANGE_REASON.REASON_REQUIRED" }))
            .min(1, intl.formatMessage({ id: "WOUND_INFO_CHANGE_REASON.REASON_REQUIRED" })),
        msg: Yup.string()
            .max(500, intl.formatMessage({ id: "WOUND_INFO_CHANGE_REASON.MAX_LIMIT" }))
            .typeError(intl.formatMessage({ id: "WOUND_INFO_CHANGE_REASON.INVALID_VALUE" }))
            .required(intl.formatMessage({ id: "WOUND_INFO_CHANGE_REASON.NOTE_REQUIRED" }))
    });

    const formik = useFormik({
        initialValues: {
            woundInfoChangeReasonId: 0,
            msg: ''
        },
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            handleValidSubmit();
            setSubmitting(false);
        },
    });

    const handleValidSubmit = () => {
        debugger
        Api.postRequest(Api.apiUrls.createWoundInfoChangeDetail, 
            {
                description: formik.values.msg,
                woundId: props.woundInfo.wound.id,
                residentId: props.woundInfo.id,
                fKWoundChangeReasonID: formik.values.woundInfoChangeReasonId
            }).then((res) => {
            if (res.data.success) {
                props.close();
                if (props.isDocumentation) {
                    props.showWound(props.woundInfo, true, true, props.woundEvalutionId);
                } else {
                    props.showWound(props.woundInfo, false, false, false, true, formik.values.woundInfoChangeReasonId);
                }
                Common.showSuccessAlertAutoClose(
                    intl.formatMessage({ id: "WOUND_INFO_CHANGE_REASON.SAVE_SUCCESS" }),
                    "success",
                    "Success!",
                    3000
                );
            } else {
                Common.showSuccessAlert(
                    res.data.message,
                    "error",
                    intl.formatMessage({ id: "WOUND_INFO_CHANGE_REASON.SAVE_ERROR" }),
                    3000
                );
            }
        });
    };

    useEffect(() => {
        getWoundInfoChangeReasons();
    }, []);

    const getWoundInfoChangeReasons = () => {
        debugger
        var residentNoOrderStatusID = Common.LookupsFK.Fk_WoundInfoChangeReasons;
        Api.getRequestById(Api.Uri_GetLookupValuesByFkId, residentNoOrderStatusID)
            .then((res) => {
                debugger
                if (res.data.statusCode == 200) {
                    if (res.data.result != null) {
                        if (res.data.result.length > 0) {
                            setWoundInfoChangeReasons(res.data.result);
                        }
                    }
                }
            })
            .catch((ex) => {
                Common.LogError(ex, "Resident", "GetLookupValuesByFkId");
            });
    };

    const setOptionsUI = (options) => {
        return options.map((option) => (
            <option key={option.key} value={option.key}>
                {option.value}
            </option>
        ));
    };

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <label className="form-label" htmlFor="Status">
                            {intl.formatMessage({ id: "WOUND_INFO_CHANGE_REASON.REASON" })}
                        </label>
                        <select
                            className="form-control form-control-sm"
                            id="woundInfoChangeReasonId"
                            name="woundInfoChangeReasonId"
                            value={formik.values.woundInfoChangeReasonId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            <option></option>
                            {setOptionsUI(woundInfoChangeReasons)}
                        </select>
                        <div style={{ fontSize: ".8rem" }} className="text-danger">
                            {formik.errors.woundInfoChangeReasonId}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <label className="form-label font-weight-bold" htmlFor="Length">
                            {intl.formatMessage({ id: "WOUND_INFO_CHANGE_REASON.NOTES" })}
                        </label>
                        <textarea
                            id="msg"
                            rows="3"
                            className="form-control"
                            name="msg"
                            value={formik.values.msg}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <div style={{ fontSize: ".8rem" }} className="text-danger">
                            {formik.errors.msg}
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end py-3">
                <Button variant="success" className="mr-5 px-10" size="sm" onClick={formik.handleSubmit}>
                    {intl.formatMessage({ id: "WOUND_INFO_CHANGE_REASON.SAVE_BUTTON" })}
                </Button>
            </div>
            <ViewWoundInformationChangeReason
                residentId={props.woundInfo.id}
                woundId={props.woundInfo.wound.id}
            />
        </Fragment>
    );
};

export default WoundInformationChangeReason;