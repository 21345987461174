
import { isThisSecond } from 'date-fns';
import React from 'react';
import { Animated } from "react-animated-css";
import { Button, ButtonToolbar, Dropdown, DropdownButton, Form } from "react-bootstrap";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";
import NewWoundAssessment from '@/app/pages/resident/newWoundAssessment';


var componentArray = [];
class ICDReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formValidated: false,
            showComponents: "addEdit",
            woundHistoryList: [],
            latestWoundDocumentation: props.residentWoundDocumentationList,

        }

    }
    componentDidMount() {
        //  this.GetWoundHistoryList()
        this.handleShowAddWound()
    }


    handleShowComponents = (tag) => {
        this.setState({ showComponents: tag })
    };
    handleCloseAdd = () => {
        this.setState({ showComponents: 'list' })
        // this.GetWoundHistoryList()
        this.setWoundHistoryList(this.props.residentWoundDocumentationList)

    };
    // handleShowComponentsOnEdit = (tag, woundType) => {
    //     this.setState({ showComponents: tag })
    //     this.setState({ WoundType: woundType });
    // };
    handleShowAddWound = () => {
var residenttest=this.props.residentWound;
        this.setState({
            NewWoundComponent: <NewWoundAssessment 
            isEdit={false} canView={false} user={this.props.user} 
            resident={this.props.residentWound}
            isReorder={ this.props.isReorder?true: this.props.residentWound.wound.isReorder} 
             close={this.handleCloseAdd} />,
            showModalAddWound: true
        })
        debugger
        this.setState({ showComponents: "addEdit" })

    };
    edtitResidentWound = (obj) => {
         // debugger
        var test = {};
        test.residentName = obj.residentName;

        this.setState(
            {
                //Resident:test,
                NewWoundComponent: <NewWoundAssessment isEdit={true} canView={false} user={this.props.user} resident={obj} close={this.handleCloseAdd} />,
                showModalAddWound: true
            })
            this.setState({ showComponents: "addEdit" })
    }
  

    GetWoundHistoryList = () => {
         debugger
        // Api.getRequestById(Api.URI_GetWoundHistoryByWoundID, this.props.residentWound.wound.id)
        Api.getRequestQry(
            Api.URI_GetWoundHistoryByWoundID,
            "?woundId=" + this.props.residentWound.wound.id+"&isLatest="+2
          )
        .then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        debugger
                        this.setWoundHistoryList(res.data.result);
                    }
                }
            }

            Common.LoadDataTable("woundList")
        }).catch((ex) => {
      Common.LogError(ex, "IcdReport", "LoadData");

        });
    }
    setWoundHistoryList = (list) => {
        var rows = [];
        var woundType = ''
         woundType = 'Pressure';
        //woundType='NonPressure';

        for (var i = 0; i < list.length; i++) {
            const obj = list[i];
            rows.push(<tr key={i.toString()}>
                  <td>{Common.replaceNullWithString(obj.residentWoundLocation)}</td>
                  <td>{Common.replaceNullWithString(obj.wound.evaluationDate) != '' ? new Date(obj.wound.evaluationDate).toDateString() : 'N/A'}</td>
                  <td>{Common.replaceNullWithString(obj.evaluatedBy) != '' ? obj.evaluatedBy : 'N/A'}</td>
                <td align="center">
                    <>
                        <ButtonToolbar className="justify-content-center">
                            <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                                {[DropdownButton].map((DropdownType, idx) => (
                                    <DropdownType
                                        size="xs"
                                        variant="primary"
                                        title="Actions"
                                        id={`dropdown-button-drop-${idx}`}
                                        key={idx}
                                    >
                                        {/* <Dropdown.Item eventKey="0" onClick={() => this.handleShowComponents("detail")}>View Details</Dropdown.Item> */}
                                        <Dropdown.Item eventKey="1" onClick={() => this.edtitResidentWound( obj)}>View Detail</Dropdown.Item>
                                    </DropdownType>
                                ))}
                            </div>
                        </ButtonToolbar>
                    </>
                </td>
            </tr>);
        }
        this.setState({ woundHistoryList: rows })
    }


    getcomponentArray = () => {
        componentArray["detail"] = <Animated animationIn="fadeIn" animationInDuration={1000}>
            <div>
                <table className="table table-sm border-0 w-100 table-striped" id="">
                    <tbody>
                        <tr>
                            <td width="25%" className="font-weight-bolder">Patient</td>
                            <td>Anthony Fairley</td>
                            <td className="font-weight-bolder">Location Name</td>
                            <td>Left Great Toe</td>
                        </tr>
                        <tr>
                            <td width="25%" className="font-weight-bolder">Evaluated on</td>
                            <td>10/02/2020</td>
                            <td className="font-weight-bolder">Evaluated By</td>
                            <td>Colleen Escamilla</td>
                        </tr>
                        <tr>
                            <td width="25%" className="font-weight-bolder">Side/Location</td>
                            <td>Side/Location</td>
                            <td className="font-weight-bolder">ICD10 Code</td>
                            <td>L97.522</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bolder">Date First Observed</td>
                            <td>07/07/2020</td>
                            <td className="font-weight-bolder">Length / Width / Depth</td>
                            <td>0.2 / 0.3 / 0.1 cm</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bolder">Exudate Amount</td>
                            <td>Light</td>
                            <td className="font-weight-bolder">Order Info</td>
                            <td>349845</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bolder">Thickness/Stage</td>
                            <td>Full</td>
                            <td className="font-weight-bolder">Thickness Detail</td>
                            <td>FAT LAYER EXPOSED</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bolder">Debridement Type</td>
                            <td>Enzymatic</td>
                            <td className="font-weight-bolder">Debridement Date</td>
                            <td>07/07/2020</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bolder">Slough %</td>
                            <td>0</td>
                            <td className="font-weight-bolder">Necrotic %</td>
                            <td>0</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="font-weight-bolder">Signer Name</td>
                            <td>Colleen Escamilla RN per Cortnee Luke DON</td>
                            <td className="font-weight-bolder">Date</td>
                            <td>10/02/2020</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="mt-3">
                <button type="button" className="btn btn-dark btn-sm" onClick={() => this.handleShowComponents("list")}>Back</button>
            </div>
        </Animated>



        componentArray["list"] = <Animated animationIn="fadeIn" animationInDuration={2000} >
          <div className="alert border-primary bg-transparent text-primary fade show" role="alert">
                        <div className="d-flex align-items-center">
                            <div className="alert-icon">
                                <i className="fas fa-info-circle mr-4 text-primary"></i>
                            </div>
                            <div className="flex-1">
                                <span className="h5 m-0 fw-700">Use buttons to do more actions </span>
                            </div>
                            <Button variant="primary" className="mr-5" size="sm" onClick={this.handleShowAddWound}>Latest Assessment</Button>

                        </div>
                    </div>
                <table className="table table-striped table-hover table-sm table-bordered" id="woundList">
                    <thead>
                        <tr>
                            <th>Location Name</th>
                            <th>Evaluated on</th>
                            <th>Evaluated By</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.woundHistoryList}
                    </tbody>
                </table>
           
        </Animated>


        componentArray["addEdit"] = <Animated animationIn="fadeIn" animationInDuration={2000} >
          {this.state.NewWoundComponent}
        </Animated>



    }
   


    render() {
        { this.getcomponentArray() }
        return (
            <>
                <Form
                    validated={this.state.formValidated}
                >
                    {this.state.showComponents == "list" &&
                        componentArray[this.state.showComponents]
                    }

                    {this.state.showComponents == "detail" &&
                        componentArray[this.state.showComponents]
                    }

                    {this.state.showComponents == "addEdit" &&
                        componentArray[this.state.showComponents]
                    }
                </Form>
            </>
        );
    }
}

export default ICDReport;