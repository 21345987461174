import { Switch,Typography } from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import {
  Accordion,
  AccordionCollapse,
  AccordionToggle, Button, Dropdown, DropdownButton
} from "react-bootstrap";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "../../../_metronic/_partials/controls/Card";
import { useSelector } from 'react-redux';
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import WorkQueueWounds from "../../pages/resident/workqueuewounds";
import PopupModal from "../modal/popupmodal";
import Newresident from "../modal/residentsmodals/newresident";
import ResidentDetails from "@/shared/components/residentDetails";
import FindResident from "./findResident";
import CheckPermission from "../../General/CheckPermission";
import WoundOrderApprovel from "../resident/woundOrderApprovel";
import uuid from 'node-uuid'
import moment from 'moment';
import ResidentStatus from "../resident/residentStatus";
import CloseWound from '../resident/closeWound';
import SelectableContext from "react-bootstrap/SelectableContext";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import SkipResidentMsg from './skipResidentMsg';
import SkipWoundMsg from './skipWoundMsg';
import NewWound from '../resident/newwound';

import ViewWoundInformationChangeReason from './viewWoundInformationChangeReason';



import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import de from "date-fns/esm/locale/de/index.js";
import { WOUND_TYPES } from "../../General/wound-type";
import { injectIntl } from "react-intl";
import InsuranceWrapper from "@/shared/components/insurance";
var UpsertNewresident, residentStatusModal, addStatusRef, AddResidentModal,
insuranceModal,woundAmendModal, authorizeModal, closeWoundModal, residentInfoModal,
 noOrderModal,showSkipWoundMsgModal, editWoundmodal = null;
 var _sampleWoundObj = {
  fkResidentId: 0,
  residentName: '',
  facilityName: '',
  roomUnit: '',
  // evaluatedBy: Common.replaceNullWithString(user.firstName) + " " + Common.replaceNullWithString(user.lastName),
  physicanName: '',
  btPatientBrightreeID: null,
  fkLastWoundDetailId: null,
  isWoundInfoChange: false,
  changeReason: '',
  fkSalesOrderId: 0,

  wound:
  {
      id: 0,
      woundDetailId: 0,
      // evaluationDate: '',
      // dateFirstObserved: '',
      // fkAcquisitionId: null,
      fkWoundLocationId: null,
      woundLocation: "",
      fkWoundTypeId: null,
      woundDescription: '',
      fkWoundThicknessId: null,
      //fkWoundSevereTissueTypeId: null,
      fkWoundLateralityId: null,
      woundLength: '',
      woundWidth: '',
      woundDepth: '',
      isUtd:false,
      tunneling: false,
      tunnelingDepth: null,
      tunnelingClockPosition: '',
      undermining: false,
      underminingDepth: null,
      underminingClockPosition: null,
      toUnderminingClockPosition:null,
      icdcode: "",
      fkExudateAmountId: null,
      fkExudateTypeId: null,
      exudateColor: '',
      fkDebridementTypeId: null,
      debridementDate: null,
      surgicalDate: null,
      odor: false,
      Pain: false,
      painMedicationOrdered: false,
      sloughPercentage: null,
      necroticPercentage: null,
      //FkWoundCleanseId :'',
      cleanseDescription: null,
      fkDressingChangeFrequencyId: 8,
      //fkPrimaryDressingId :null,
      //FkAdditionalPrimaryDressingId :'',
      otherAdditionalPrimaryDressing: '',
      //fkSecondaryDressingId :null,
      //FkAdditionalSecondaryDressingId :'',
      otherAdditionalSecondaryDressing: '',
      treatment: '',
      comments: '',
      thicknessIdForBackStagging: null,
      orientationLeftRight: null,
      orientationUpperLower: null,
      orientationInnerOuter: null,
      orientationAnteriorPosterior: null,
      orientationMedialLateral: null,
      orientationDorsalDistalProximal: null,
      isDocumentation: false,
      isPartailOrder: false,
      // FkWoundcareProductTypeId :'',

  }
};
export function FacilityResident(props) {
  //#region Resident modal
  const { intl } = props;
  const [showModalResident, setShowResident] = useState(false);
  const [isResidentInfoEdit, setisResidentInfoEdit] = useState(false);
  const [Resident, setResident] = useState("");
  const [ResidentID, setResidentID] = useState("");
  const [isNoOrder, setIsNoOrder] = useState(false);
  const [isProcessorNoOrder, setIsProcessorNoOrder] = useState(false);
  
  const [residentListState, setResidentListState] = useState([]);
  const [residentListUI, setResidentListUI] = useState([]);
  const [partialWoundCount, setPartialWoundCount] = useState(0);
  const [addWoundComponent, setAddWoundComponent] = useState(null);
  const [residentLoading, setResidentLoading] = useState(false);
  
  const { user } = useSelector((state) => state.auth);
  const [orderApproved, setOrderApproved] = useState(props.facility.isApproved ? true : false);

  const FacilityId = props.facility.id;
  //#endregion



  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllResident();
    //setResidentListTemp();
    //Common.LoadDataTable("facilityList")
  }, []);

  //#region Set Resident List
  const getAllResident = () => {
    setIsLoading(true);
    Api.getRequestQry(
      Api.URI_GetAllWorkQueueResidentByFacilityID,
      "?facilityID=" + FacilityId
    )
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setResidentList(res.data.result);

            }
          }
        }

      })
      .catch((ex) => { })
      .then(() => {
        setIsLoading(false);

      });

  };
  const setOrderApproval = (woundApprovel) => {
    if (woundApprovel) {
      setOrderApproved(false)
    }
  }
  const handlePartialWoundCount = (count) => {
    setPartialWoundCount(count)

  }
  const [residentStatus, setResidentStatus] = useState({});

  const checkAllProccessed = () => { };
  const handleResidentStatus = (residentObj) => (event) => {
    try {
      if(residentObj.isNoOrder==true &&event.target.checked==false){
        handleResidentNoOrderStatus(residentObj)
      }else{
        let resident = {};
        resident.ResidentID = residentObj.id;
        resident.Status = event.target.checked;
        updateResidentStatus(resident);
      }
    } catch (ex) {
      Common.LogError(ex, "FacilityResident", "handleResidentStatus");
    }
  };
  const updateResidentStatus = (obj) => {
    // var msg = (obj.Status ? "Do you want to process this resident." : "You want to move back to review/edit mode?");
    // Common.showConfirmation(msg, "Are you sure", updateStatus, null, obj);
    updateStatus(obj);
  };
  const updateStatus = (obj) => {
    if (obj) {
      setIsLoading(true);
      Api.postRequest(Api.Uri_UpdateSaleOrderStatus, obj).then((res) => {
        if (res.data.success) {
          getAllResident();
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "success",
            "Success!",
            3000
          );
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            "Warning!",
            3000
          );
        }
        setIsLoading(false);
        //Common.showSuccessAlertAutoClose("User Added Successfully", "success", "Success!", 3000);
      });
    }
  };
  const releaseFacility = () => {
    var msg = "You want to release facility.";
    Common.showConfirmation(msg, "", handleReleaseFacility, null, FacilityId)
    // handleReleaseFacility(FacilityId);
  };

  const handleResidentActiveStatus = async (resident) => {
     setResident(resident)
    residentStatusModal.showModal();
  }


  const handleResidentNoOrderStatus = (obj) => {
  
    let tempObj = JSON.parse(JSON.stringify(obj));
    tempObj.isProcessorNoOrder =  false;
    tempObj.isNoOrder = tempObj.isNoOrder == true ? false : true;
    var msg = "" + (tempObj.isNoOrder ? "You want to no order." : "Ordering for this resident was skipped. The No Order will be removed. Do you want to proceed?");
    Common.showConfirmation(msg, "", updateNoOrderStatus, null, tempObj)

};
const updateNoOrderStatus = (obj) => {
    if (obj) {
      setIsNoOrder(obj.isNoOrder);
      setIsProcessorNoOrder(obj.isProcessorNoOrder);
   if(obj.isNoOrder){
    setResidentID(obj.id);
    noOrderModal.showModal()
   }
      
  else{
    Api.postRequest(Api.apiUrls.updateResidentNoOrderStatus, {msg:'',fkResidentId:obj.id,isNoOrder:obj.isNoOrder,isProcessorNoOrder:obj.isProcessorNoOrder
  }).then((res) => {
      if (res.data.success) {
          getAllResident();
          Common.showSuccessAlertAutoClose("Resident Status Updated Successfully", "success", "Success!", 3000)
      } else {
          Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
      }
  });
}
}
      

   
}

const handleNewWound =(newWoundDetail)=>{
  debugger
  setResident(newWoundDetail)
  Common.showConfirmation("Would you like to enter a wound.",
  "", handleNewWoundModal, null, newWoundDetail,"Yes",false,null,"No")
}
const handleNewWoundModal = (obj) => {
  let residenObj = JSON.parse(JSON.stringify(_sampleWoundObj));
  residenObj.fkResidentId = obj.id;
  residenObj.residentName = Common.replaceNullWithString(obj.firstName) + " " + Common.replaceNullWithString(obj.lastName);
  residenObj.facilityName = Common.replaceNullWithString(props.facility.facilityName);
  residenObj.roomUnit = Common.replaceNullWithString(obj.room) + "/" + Common.replaceNullWithString(obj.unit);
  residenObj.physicanName = Common.replaceNullWithString(obj.primaryPhysicianName);
  // residenObj.evaluatedBy= Common.replaceNullWithString(this.props.user.firstName) + " " + Common.replaceNullWithString(this.props.user.lastName);
  residenObj.btPatientBrightreeID = obj.btPatientBrightreeID;
  if(obj.fkResidentStatusId==Common.ResidentStatusID.open){
    setAddWoundComponent(<NewWound refresh={props.refresh}  isEdit={false} 
    isReorder={false} 
    showProduct={false}
    isReadOnly={false}
    user={user}
    displayProduct={true}
    queue={props.queue || Common.ProcessStatus.workQueueID}
    resident={residenObj} close={() => handleCloseWoundmodalPopup()} />,
    // ResidentFirstName:obj.firstName,
    // ResidentLastName:obj.lastName

   )

  editWoundmodal.showModal()
}else{
  Common.showSuccessAlert("Resident is not open please update resident status to add/update new wound", "warning", "Warning!", 3000);

}
}
const handleCloseWoundmodalPopup=()=>{
  debugger
  editWoundmodal.hideModal()
  getAllResident()
  // Common.showConfirmation("You want to add new wound.",
  // "", handleNewWoundModal, editWoundmodal.hideModal(), Resident)
  
}
  const handleReleaseFacility = (facilityID) => {
    setIsLoading(true);
    var obj = {};
    obj.FacilityID = facilityID;
    obj.billingDate=props.facility.cycleDate;
    obj.isSecondaryCycle=props.facility.isSecondaryCycle;

    Api.postRequest(Api.Uri_UpdateFacilityStatus, obj)
      .then((res) => {
        if (res.data.success) {

          Common.showSuccessAlertAutoClose(
            res.data.message,
            "success",
            "Success!",
            3000
          );
          props.backPress();
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            "Warning!",
            3000
          );
        }
      })
      .catch((ex) => {

        Common.showSuccessAlertAutoClose(
          ex.message,
          "warning",
          "Failed!",
          3000
        );
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  const setResidentList = (list) => {
    var rows = [];

    //const statusBadgeType = ["badge-warning", "badge-success"];
    //const status = ["Pending", "Processed"];

    var resStatus = residentStatus;

    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const name = obj.lastName + ", " + obj.firstName;
      const residentID = obj.id;

      if (resStatus[residentID] == null) {
        resStatus[residentID] = false;
      }
      var insurance = "No insurance on file";
      // this.intl.formatMessage({id:"FACILITY_RESIDENT.RESIDENT.NO_INSURANCE"})

      if (obj.residentInsuranceList != null) {
        insurance = "";
        for (var d = 0; d < obj.residentInsuranceList.length; d++) {
          let objIns = JSON.parse(JSON.stringify(obj.residentInsuranceList[d]));
          insurance = insurance + (insurance != "" ? ", " : "") + " " + (objIns.isAddEdit ? "*" : "") + "=>" + (objIns.fkInsuranceLevelId == 1 ? "Primary"
            : objIns.fkInsuranceLevelId == 2 ? "Secondary"
              : objIns.fkInsuranceLevelId == 3 ? "Tertiary"
                : "") + " " + objIns.insuranceName
        }
      }
      // else {
      //   insurance = "N/A"
      // }
      // var obj = usersDatajson[Math.floor(Math.random() * usersDatajson.length)];
      const dob = moment(obj.doB).format('MM/DD/YYYY');
      // let randomCount = Math.floor(Math.random() * status.length);
      // var randStatus = resStatus[residentID];
      var statusBadgeType =
        obj.isProcess == true || obj.isNoOrder==true ? "badge-success" : "badge-warning";

      rows.push(
        <Accordion key={uuid()} defaultActiveKey={!obj.isProcess && !obj.isNoOrder  ? i.toString() + obj.id : ""}>
          <Card>
            <CardHeader>
              <div
                className={`row w-100 d-flex border-bottom m-0  ${obj.isProcess || obj.isNoOrder==true 
                  ? (obj.isNoOrder==true ? "bg-light-warning border-warning" :  "bg-light-success border-success")
                  : "border-light-dark"
                  }`}

              >
                <div className="customStyleAccorion" style={{ display: "grid" }}>
                  <AccordionToggle
                    as={Button}
                    variant="link"
                    eventKey={i.toString() + obj.id}
                  >

                    <div title={obj.fkPrimaryPhysicianId == null ? "Primary physician is not assigned" : ""} 
                    className={`h5 mb-0 text-dark ${obj.fkPrimaryPhysicianId == null ? "bg-light-danger border-danger" : ""}`}> {name}
                    
                    </div>

                  
                  </AccordionToggle>

                  {obj.fkPrimaryPhysicianId == null ? <small> Primary physician is not assigned <br /></small> : ""}

                  <small> {insurance}</small>
                </div>
                <span className={`badge my-auto mx-4 ml-auto `} >
                {obj.isEcp &&
                        <span
                          className={`badge badge-success mr-2`}
                        >
                          ECP
                        </span>
                      }
                  <span
                    className={`badge my-auto mx-4 ml-auto ${statusBadgeType}`}
                  >
                    {obj.isProcess == true || obj.isNoOrder==true  ? obj.isNoOrder==true? intl.formatMessage({ id: "FACILITY_RESIDENT.SKIPPED" }): intl.formatMessage({ id: "FACILITY_RESIDENT.PROCESSED" }) : intl.formatMessage({ id: "FACILITY_RESIDENT.PENDING" })}
                  </span>
                  <Switch
                    checked={obj.isProcess || obj.isNoOrder==true ?true:false}
                    onChange={handleResidentStatus(obj)}
                    value="checkedB"
                    color="primary"
                  />
                  <span>
                    <SelectableContext.Provider value={false}>
                      <DropdownButton
                        className="d-inline"
                        drop={"down"}
                        size="xs"
                        variant="primary"
                        title={intl.formatMessage({ id: "FACILITY.ACTIONS" })}
                        id={`dropdown-button-drop-actions`}
                        key={'down'}>
                        <CheckPermission
                          permission={UserActions.Update}
                          module={ModulesName.Module_Residents}
                          yes={() => (
                            <>
                              <Dropdown.Item eventKey="1" onClick={() => { setResident(obj); setisResidentInfoEdit(false); setResidentID(obj.id); residentInfoModal.showModal(); }}>Resident Info</Dropdown.Item>
                              <Dropdown.Item eventKey="8" onClick={() => handleResidentActiveStatus(obj)}>Set Resident Status</Dropdown.Item>

               


                              <Dropdown.Item eventKey="89" onClick={() => { setResident(obj); closeWoundModal.showModal() }}>Show Closed Wound</Dropdown.Item>

                              {(props.facility.fkProcessStatusId == Common.ProcessStatus.billingQueueID ||
                                props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID) &&
                                <CheckPermission
                                  userRole={[RBAC.Role_Gentell_SeniorBiller, RBAC.Role_Gentell_IntakeProcessor,
                                  RBAC.Role_Gentell_Administrator, RBAC.Role_Gentell_WoundCareSpecialist, RBAC.Role_Gentell_RegionalManager]}

                                  yes={() => (
                                    <>
                                      <Dropdown.Item eventKey="8"
                                        // disabled={obj.wound.isPartailOrder ? true : false}
                                        onClick={() => handleResidentNoOrderStatus(obj)}>{obj.isNoOrder ?
                                          'Order Products' : 'No Order'
                                        }
                                      </Dropdown.Item>
                                    </>
                                  )}
                                  no={() => null}
                                />
                              }

                              {/* <Dropdown.Item eventKey="8" className="text-danger" onClick={()=>handleResidentActiveStatus(obj)}>{obj.fkResidentStatusId==Common.ResidentStatusID.open?'Close Resident': 'Open Resident'}</Dropdown.Item> */}
                            </>
                          )}
                          no={() => null}
                        />
    
    <Dropdown.Item eventKey="2" onClick={() => {  setResidentID(obj.id); showSkipWoundMsgModal.showModal(); }} >View Skip Wound Messages</Dropdown.Item>

                        <Dropdown.Item eventKey="2" onClick={() => {  setResidentID(obj.id); woundAmendModal.showModal(); }} >View Wound Amend Details</Dropdown.Item>
                      </DropdownButton>
                    </SelectableContext.Provider>

                  </span>
                </span>
                {/* <>
                  {obj.isProcess &&
                    <span className={`badge my-auto `}>
                      <button className="btn btn-info btn-xs mb-3" size="sm" onClick={() => { getAllResident(true) }}>Show Product</button>
                    </span>
                  }
                </> */}

              </div>


            </CardHeader>
            <AccordionCollapse eventKey={i.toString() + obj.id} >
              <CardBody >
                <fieldset disabled={obj.isProcess || obj.isNoOrder==true?true:false} >
                  <WorkQueueWounds facility={props.facility}
                    isShowProduct={true}
                    setOrderApproval={setOrderApproval} refresh={() => { getAllResident() }}
                    isProcess={obj.isProcess}
                    Resident={obj}
                    setPartialWoundCount={handlePartialWoundCount} />
                </fieldset>
              </CardBody>
            </AccordionCollapse>
          </Card>
        </Accordion>
      );
    }

    //return rows
    setResidentListUI([]);
    setResidentListUI(rows);
    Common.LoadDataTable("residentsList");
  };

  // for (var i = 0; i < 3; i++) {
  //     var obj = usersDatajson[Math.floor(Math.random() * usersDatajson.length)];
  //     var dob = new Date(obj.birthdate);
  //     let randomCount = Math.floor(Math.random() * status.length);
  //     var randStatus = status[randomCount];
  //     var randStatusBadgeType = statusBadgeType[randomCount];
  //     //${randStatus == "Processed" ? "bg-light-success" :""}
  //     rows.push(
  //         <Accordion>
  //             <Card>
  //                 <CardHeader>
  //                     <div className={`w-100 d-flex border-bottom  ${randStatus == "Processed" ? "bg-light-success border-success" :"border-light-dark"}`}>
  //                         <AccordionToggle as={Button} variant="link" className="h5 mb-0" eventKey={i.toString()}>
  //                             {obj.first_name + " " + obj.last_name}
  //                         </AccordionToggle>
  //                         <span className={`badge my-auto mx-4 ml-auto ${randStatusBadgeType}`}>{randStatus}</span>
  //                     </div>
  //                 </CardHeader>
  //                 <AccordionCollapse eventKey={i.toString()}>
  //                     <CardBody><WorkQueueWounds /></CardBody>
  //                 </AccordionCollapse>
  //             </Card>
  //         </Accordion >
  //     );
  // }

  const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(
    ShowAdvancedSearch ? true : false
  );
  const showAdvanceSearchSection = () => {
    showAdvanceSearchSectionState(!ShowAdvancedSearch);
  };
  const handleShowAddResident = (obj) => {
    setResident(obj);
    AddResidentModal.showModal();
  };
  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
        <CheckPermission
          permission={UserActions.View}
          module={ModulesName.Module_Residents}
          yes={() => (
            <Button
              variant="primary"
              size="sm"
              className="mr-2"
              onClick={() => {
                handleShowAddResident(null);
              }}
            >
              {intl.formatMessage({ id: "FACILITY_RESIDENT.NEW_RESIDENT" })}
            </Button>
          )}
          no={() => null}
        />
        <CheckPermission
          permission={UserActions.View}
          module={ModulesName.Module_Residents}
          yes={() => (
            <Button className="mr-2" variant="warning" size="sm" onClick={showAdvanceSearchSection}>
             {intl.formatMessage({ id: "FACILITY_RESIDENT.FIND_RESIDENT" })}
            </Button>
          )}
          no={() => null}
        />
        {!orderApproved &&
          <CheckPermission
            permission={UserActions.Create}
            module={ModulesName.Module_ApproveOrder}
            yes={() => (
              <button
                type="button"
                className="mr-2 btn btn-danger btn-sm"
                onClick={() => authorizeModal.showModal()}
              >
                {intl.formatMessage({ id: "WORKQUEUE.AUTHORIZE_ORDERS" })}
              </button>
            )}
            no={() => null}
          />

        }
        {orderApproved &&
          <button
            type="button"
            title="Order for this facility is Authorized"
            className="mr-2 btn btn-info btn-sm"
            onClick={() => authorizeModal.showModal()}

          >
            Orders Authorized
          </button>
        }
        <CheckPermission
          permission={UserActions.Update}
          module={ModulesName.Module_MyWorkQueue}
          yes={() => (
            <button
              type="button"
              className="mr-1 btn btn-success btn-sm"
              // disabled={Common.GetDateString(props.facility.cycleDate) == "01-01-01"
              // || props.facility.cycleDate==null?true:false}
              onClick={() => {
                releaseFacility();
              }}
            >
              {intl.formatMessage({ id: "FACILITY_RESIDENT.RELEASE_FACILITY" })}
            </button>
          )}
          no={() => null}
        />
        {/* <CheckPermission
          permission={UserActions.Update}
          module={ModulesName.Module_MyWorkQueue}
          yes={() => (
            <button
              type="button"
              disabled={partialWoundCount > 0 ? false : true}
              className="mr-1 btn btn-success btn-sm"
              onClick={() => {
                releasePartailFacility();
              }}
            >
              Release Partial Shipment
            </button>
          )}
          no={() => null}
        /> */}
        <button
          type="button"
          className="mr-2 btn btn-dark btn-sm"
          onClick={() => props.backPress()}
        >
          {intl.formatMessage({ id: "FACILITY_RESIDENT.BACK_TO_FACILITY_LIST" })}
        </button>


      </div>
    </div>
  );

  //initialize datatable
  //   $(document).ready(function() {
  //     if ($.fn.dataTable.isDataTable("#residentsList")) {
  //       $("#residentsList").DataTable = $("#residentsList").DataTable();
  //     } else {
  //       $("#residentsList").DataTable({
  //         responsive: true,
  //         pageLength: 25,
  //         lengthMenu: [
  //           [25, 50, 100, -1],
  //           [25, 50, 100, "All"],
  //         ],
  //       });
  //     }
  //   });

  return (
    <>
      {ShowAdvancedSearch && (
        <Animated
          animationIn="fadeInLeft"
          animationInDuration={800}
          animationOut="fadeOut"
          isVisible={ShowAdvancedSearch}
        >
          <FindResident createWoundType={WOUND_TYPES.EVALUATION} user={user} facility={props.facility} refresh={() => { getAllResident() }} facilityId={FacilityId} closeSearch={() => { showAdvanceSearchSection() }} />
        </Animated>

      )}

      <Card className="example example-compact">
        <CardHeader>
          <div className="row">
            <div className="col-sm-7 col-md-7 col-lg-7 col-xl-7">
              <div className="card-title ">
                <span>

                 {intl.formatMessage({ id: "RESIDENT.FACILITY" })}:  <b className="text-success">{props.facility.facilityName}</b> ,{" "} 
                 {intl.formatMessage({ id: "FACILITY_RESIDENT.DATA_TABLE.PURCHASING" })}: {" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
                 {intl.formatMessage({ id: "FACILITY.OWNERSHIP_HEADQUARTERS" })}:  {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
                  <br />
                  {intl.formatMessage({ id: "FACILITY.SHIP_DATE" })}:{" "} {Common.GetDateString(props.facility.cycleDate) == "01-01-01" || props.facility.cycleDate==null ? "N/A" 
                  : Common.GetDateString(props.facility.cycleDate)+(props.facility.isSecondaryCycle ? " (S)" : "")}
                  <b>
                    <FormControlLabel
                       label={<Typography > 
                        <h4 className="mt-4"> {intl.formatMessage({ id: "FACILITY_RESIDENT.DATA_TABLE.PART_B" })}:</h4> 
                        </Typography>
                        }
                      labelPlacement='start'
                      control={
                        <Checkbox className="mt-2" checked={props.facility.medicarePartBbyGentell}  value="checkedA" />
                      }
                    />
                      <FormControlLabel
                        label={<Typography > 
                          <h4 className="mt-4"> {intl.formatMessage({ id: "FACILITY_RESIDENT.DATA_TABLE.EQUAL_CARE" })}:</h4> 
                          </Typography>
                          }
                      labelPlacement='start'
                      control={
                        <Checkbox className="mt-2" checked={props.facility.isEcp}  value="checkedB" />
                      }
                    />
                      <FormControlLabel
                          label={<Typography > 
                            <h4 className="mt-4">{intl.formatMessage({ id: "FACILITY_RESIDENT.DATA_TABLE.OUTS" })}:</h4> 
                            </Typography>
                            }
                      labelPlacement='start'
                      control={
                        <Checkbox className="mt-2" checked={props.facility.outProgram}  value="checkedC" />
                      }
                    />
                    </b>

                  <h3 className="card-label mt-3">{intl.formatMessage({ id: "FACILITY_RESIDENT.DATA_TABLE.RESIDENT_LIST" })}</h3>
                  {props.facility.totalPartailWound > 0 ? <h3 className="card-label">Total number of partial wounds {props.facility.totalPartailWound}</h3> : ""}

                </span>
              </div>

            </div>
            <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5">

              {toolbar}
            </div>
          </div>


        </CardHeader>
        <CardBody>
          {isLoading ? (
            <div className="row d-flex justify-content-center">
              <span className="mt-5 spinner spinner-primary"></span>
            </div>
          ) : (
            residentListUI
          )}
        </CardBody>
      </Card>
      <PopupModal
        title="Insurances"
        showButton={false}
        ref={(ref) => {
          insuranceModal = ref;
        }}
      >
        {" "}
        <InsuranceWrapper residentID={ResidentID} />{" "}
      </PopupModal>
      <PopupModal
        title="Resident Info"
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          residentInfoModal = ref;
        }}
        onClose={(ref) => {
          Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning',
            () => { ref.setState({ show: false }); }, () => { }, null, "Exit Without Saving");
        }}
      >
        {" "}
        {
          isResidentInfoEdit && <Animated
            animationIn="fadeIn"
            animationInDuration={800}
            animationOut="fadeOut"
          // isVisible={isResidentInfoEdit}
          >
            <div className="row d-flex align-items-center justify-content-end">
              <Button className='mb-4 mr-4 ' onClick={() => { setisResidentInfoEdit(!isResidentInfoEdit); }}>Go Back To Detail</Button>
            </div>
            <Newresident
              ref={(ref) => {
                UpsertNewresident = ref;
              }}
              FacilityId={FacilityId}
              Resident={Resident}
              setResidentLoading={setResidentLoading}
              facility={props.facility}
              close={() => {
                getAllResident();
                setResidentLoading(false);
                residentInfoModal.hideModal(true);
              }}
            />
            <div className="row d-flex align-items-center justify-content-end">
            <Button className='mb-4 btn-success ml-2' disabled={residentLoading} onClick={() => { UpsertNewresident.handleSubmit() }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
        {residentLoading && <span className="mr-8 spinner spinner-white"></span>}
        <span>{'Save Changes'}</span>
    </span>
              </Button>
            </div>
          </Animated>

        }

        {
          !isResidentInfoEdit && <Animated
            animationIn="fadeIn"
            animationInDuration={800}
            animationOut="fadeOut"
          // isVisible={isResidentInfoEdit}
          >       <div className="row d-flex align-items-center justify-content-end">
              <Button className='mb-4 mr-4' onClick={() => { setisResidentInfoEdit(!isResidentInfoEdit); }}>Edit</Button>
            </div>
            <ResidentDetails resident={Resident} getAllResident={() => { getAllResident() }} />{" "}
          </Animated>
        }
        {" "}
      </PopupModal>
      {/** Patient Orders approvel Modal */}
      <PopupModal
        title={<div className="card-title">
          <span>
            <h3 className="card-label">Facility Authorize</h3>
            <small className="">
              <b>Facility:</b> {props.facility.facilityName},{" "}
              <b>Ownership/Headquarters:</b> {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
              <b>Purchasing Group-Chain:</b>{" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
            </small>
          </span>
        </div>}
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          authorizeModal = ref;
        }}
      >
        {" "}
        <WoundOrderApprovel
          facility={props.facility}
          close={() => {
            authorizeModal.hideModal();
            //  props.afterAuthorize();
            setOrderApproved(true)
          }}
        />{" "}
      </PopupModal>
      <PopupModal
        title={<div className="card-title">
          <span>
            <h3 className="card-label">Wound Amend Messages</h3>
            <small className="">
              <b>Facility:</b> {props.facility.facilityName},{" "}
              <b>Ownership/Headquarters:</b> {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
              <b>Purchasing Group-Chain:</b>{" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
            </small>
          </span>
        </div>}
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          woundAmendModal = ref;
        }}
      >
        {" "}
        <ViewWoundInformationChangeReason
          residentId={ResidentID}
          woundId={0}
        />{" "}
      </PopupModal>
      
      <PopupModal

        title={<div className="card-title">
          <span>
            <h3 className="card-label">Resident Status</h3>
            <small className="">
              <b>Facility:</b> {props.facility.facilityName},{" "}
              <b>Ownership/Headquarters:</b> {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
              <b>Purchasing Group-Chain:</b>{" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
            </small>
          </span>
        </div>}
        // dialogClassName="modal-90w"

        ref={(ref) => {
          residentStatusModal = ref;
        }}
        showButton={true}
        buttonPress={() => addStatusRef.onSubmit()}
        module={ModulesName.Module_Residents}
        action={UserActions.Update}
      >
        {" "}
        <ResidentStatus
          ref={(ref) => {
            addStatusRef = ref;
          }}
          intl={intl}
          resident={Resident}
          close={() => {
            getAllResident();
            residentStatusModal.hideModal();
            //  props.afterAuthorize();
            //  setOrderApproved(true)
          }}
        />{" "}
      </PopupModal>
      <PopupModal
        title={<div className="card-title">
          <span>
            <h3 className="card-label">Resident Close Wound</h3>
            <small className="">
              <b>Facility:</b> {props.facility.facilityName},{" "}
              <b>Ownership/Headquarters:</b> {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
              <b>Purchasing Group-Chain:</b>{" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
            </small>
          </span>
        </div>}
        dialogClassName="modal-95w" ref={ref => { closeWoundModal = ref; }}>
        <CloseWound resident={Resident} 
          close={() => {
            getAllResident();
          }}
        />
      </PopupModal>
      <PopupModal
        title={<div className="card-title">
          <span>
            <h3 className="card-label">Add Resident</h3>
            <small className="">
              <b>Facility:</b> {props.facility.facilityName},{" "}
              <b>Ownership/Headquarters:</b> {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
              <b>Purchasing Group-Chain:</b>{" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
            </small>
          </span>
        </div>}
        dialogClassName="modal-97w"
        showButton={true}
        ref={(ref) => {
          AddResidentModal = ref;
        }}
        buttonDisabled={residentLoading}
        buttonPress={() => UpsertNewresident.handleSubmit()}
        module={ModulesName.Module_Residents}
        action={UserActions.Create}
        onClose={(ref) => {
          Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning',
            () => { ref.setState({ show: false }); }, () => { }, null, "Exit Without Saving");
        }}
      >
        <Newresident
          ref={(ref) => {
            UpsertNewresident = ref;
          }}
          userID={user.userID}
          user={user}
          FacilityId={props.facility.id}
          facility={props.facility}
          handleNewWound={handleNewWound}
          setResidentLoading={setResidentLoading}
          close={() => {
            getAllResident();
            setResidentLoading(false)
            AddResidentModal.hideModal(true);
          }}
        />
      </PopupModal>

      <PopupModal
                // title="Sales Order Message"
                title={<div className="card-title">
                <span>
                  <h3 className="card-label">Skip Resident Message</h3>
                  <small className="">
                    <b>Facility:</b> {props.facility.facilityName},{" "}
                    <b>Ownership/Headquarters:</b> {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
                    <b>Purchasing Group-Chain:</b>{" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
                  </small>
                </span>
              </div>}
                showButton={false}
                ref={(ref) => {
                    noOrderModal = ref;
                }}

            >
                {" "}
                <SkipResidentMsg msg={null} fkResidentId={ResidentID} isNoOrder={isNoOrder} isProcessorNoOrder={isProcessorNoOrder}   onSave={()=>{noOrderModal.hideModal();getAllResident()}} />
                {" "}
            </PopupModal>
            <PopupModal
                // title="Sales Order Message"
                title={<div className="card-title">
                <span>
                  <h3 className="card-label">Skip Wounds Message</h3>
                  <small className="">
                    <b>Facility:</b> {props.facility.facilityName},{" "}
                    <b>Ownership/Headquarters:</b> {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}
                    <b>Purchasing Group-Chain:</b>{" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
                  </small>
                </span>
              </div>}
                showButton={false}
                ref={(ref) => {
                    showSkipWoundMsgModal = ref;
                }}

            >
                {" "}
                <SkipWoundMsg  fkResidentId={ResidentID} isNoOrder={isNoOrder} isProcessorNoOrder={isProcessorNoOrder}   onSave={()=>{showSkipWoundMsgModal.hideModal()}} />
                {" "}
            </PopupModal>
            <PopupModal
               onClose={(ref) => {
                getAllResident();
                ref.setState({ show: false })
                // Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning',
                //     () => { ref.setState({ show: false }); }, () => { }, null, "Exit Without Saving");
            }}
                title={<div className="card-title">

                    <span>
                        <h3 className="card-label">Wound Evaluation 
                        {/* - First Name:{" "}
                            {Common.replaceNullWithString(this.state.Resident.firstName)},{" "}Last Name: {Common.replaceNullWithString(this.state.Resident.lastName)} */}
                            </h3>

                    </span>
                </div>}
                dialogClassName="modal-95w" ref={ref => { editWoundmodal = ref; }}>
                {addWoundComponent} </PopupModal>
    </>
  );
}

export default injectIntl(FacilityResident);
